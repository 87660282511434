<template>
  <div id="navBar">
    <!-- Mobile Top Menu -->
    <mobile-top v-show="!config.chatModal" />
    <!-- Dekstop Top Menu -->
    <desktop-top />
    <!-- sidebar -->
    <transition name="sidebar">
      <sidebar v-if="config.sidebar" />
    </transition>
    <!--Mobile-Bottom-->
    <mobile-bottom v-show="!config.chatModal" />

    <!--<div class="ptr--ptr"></div>-->
  </div>

  <!-- Modals -->
  <div class="scrollToTop circular ui icon button btn-color-1">
    <i class="icon angle up" />
  </div>
</template>

<script>
import { navbarSemantic } from '@/modules/adminnavbar'
import desktopTop from '@/components/basic/navbar/desktopTop'
import mobileBottom from '@/components/basic/navbar/mobileBottom'
import mobileTop from '@/components/basic/navbar/mobileTop'
import Sidebar from '@/components/basic/navbar/sidebar'

import {configStore} from "@/store/configStore";

export default {
  name: 'Adminnavbar',
  components: {
    Sidebar,
    desktopTop,
    mobileBottom,
    mobileTop
  },
  setup (){
    const config = configStore()

    return {config}
  },
  data () {
    return {
    }
  },
  computed: {
  },
  mounted () {
    navbarSemantic()
    document.addEventListener('click', function (event) {
      try {
        if (document.querySelector('body').classList.contains('sidemenu-open')) {
          var isClickInside = document.querySelector('div.sidebar').contains(event.target)

          if (!isClickInside) {
            document.querySelector('.menu-btn').click()
          }
        }
      }catch (e) {

      }
    })
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
