<template>
  <router-link class="ui fluid card questioncard no-border no-shadow" v-for="(question, index) in this.$parent.questions" :key="index" :to="'/forum/q/' + question.id">
    <div class="content">
      <span class="right floated">
        <p>am {{question.datestamp}}</p>
      </span>
      <router-link :to="'/p/' + question.username" v-if="question.anonym == 0">
        <img class="ui avatar image" :src="projectURL + 'assets/images/user/' + question.profile_img">
        <span><b>{{question.username}}</b> ({{question.age}})</span>
      </router-link>
      <div v-else>
        <img class="ui avatar image" :src="projectURL + 'assets/images/user/' + question.profile_img">
        <span><b>{{question.username}}</b> ({{question.age}})</span>
      </div>
      <div class="description pad-16">
        <h3>
          <a>{{question.title}}</a>
        </h3>
      <a class="ui left labeled right floated tiny button" tabindex="0">
        <span class="ui basic right pointing label">
          Frage ansehen
        </span>
        <div class="ui tiny button btn-color-3">
         <i class="comment icon"></i>
        </div>
      </a>
    </div>
  </div>
  <div class="extra content">
    <span class="left floated like">
      <router-link :to="'/forum/c/'+question.catlink">
        <img class="category-img" :src="question.catimg">
        {{question.cattitle}}
      </router-link>
    </span>
    <span class="right floated star">
      <p>{{question.answers}}</p>
    </span>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'forum',
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped>

</style>
