<template>
  <div class="frame">
    <progress-ring :progress="progress" radius="60" stroke="4" duration="0.7" v-if="status == 'uploading'"></progress-ring>
    <div class="loaderContainer" v-else-if="status == 'failed'">Felix war wohl wieder am Werk.</div>
    <div class="loaderContainer" v-else-if="status == 'completed'">Dein Post wird verarbeitet und zeitnah gepostet.</div>
  </div>
</template>

<script>
import ProgressRing from '@/components/loader/progress-ring'
export default {
  name: 'uploadLoader',
  components: { ProgressRing },
  props: ['progress', 'status']
}
</script>

<style scoped>
  .frame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: #C27FFF;
    border-radius: 16px;
    z-index: 500;
  }
  .loaderContainer{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-family: 'Numbers';
    color: white;
    font-size: 1em;
  }
</style>
