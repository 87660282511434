<template>
  <div id="Mobile-Bottom-Menu">
    <div class="footer-tabs border-top text-center">
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <router-link
            active-class="active"
            class="nav-link "
            to="/wall"
            aria-controls="home"
            aria-selected="true"
            style="position: relative;"
          >
            <i class="material-icons">dashboard</i>
            <small class="sr-only">Feed</small>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            active-class="active"
            class="nav-link "
            to="/subscriptions"
            aria-controls="search"
            aria-selected="false"
            style="position: relative;"
          >
            <i class="material-icons">favorite</i>
          </router-link>
        </li>
        <li class="nav-item centerlarge">
          <router-link
            active-class="active"
            class="nav-link bg-default"
            to="/create"
            data-active="create"
            aria-controls="create"
            aria-selected="false"
            style="position: relative;"
          >
            <i class="material-icons">add_circle_outline</i>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link
            active-class="active"
            class="nav-link "
            to="/messages"
            data-acitve="messages"
            aria-controls="messenger"
            aria-selected="false"
            style="position: relative;"
          >
            <i class="material-icons">chat_bubble</i>
            <div
              v-if="chat.counter > 0"
              class="floating ui blue tiny circular label"
            >
              {{ chat.counter }}
            </div>
          </router-link>
        </li>
        <!--
        <li class="nav-item">
            <a class="nav-link " onclick="openStorys()" aria-controls="cart" aria-selected="false" style="position: relative;" data-active="storys">
                <i class="material-icons">mobile_screen_share</i>
                <small class="sr-only">mobile_screen_share</small>
            </a>
        </li>
        -->
        <li class="nav-item">
          <router-link
            active-class="active"
            class="nav-link "
            :to="'/p/' + user.username"
            aria-controls="profile"
            aria-selected="false"
            style="position: relative;"
          >
            <i class="material-icons">person</i>
            <small class="sr-only">person</small>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";

export default {
  name: 'MobileBottom',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const chat = chatStore()

    return {auth, user, config, chat}
  }
}
</script>

<style scoped>

</style>
