<template>
  <div id="page-legalnotice" class="spaceTop">
    <div class="content-1200 grid-16 pad-16">
      <div class="col-sp-16 row-sp-16 pad-8">
        <h1 class="ui header">Legal Notice</h1>
      </div>
      <div class="col-sp-16 col-tb-6 row-sp-16 pad-8">
        <h2>Information pursuant to Sect. 5 German Telemedia Act (TMG)</h2>
        <p>20North Netherlands BV<br />
          Weesperstraat 61-105<br />
          1018VN Amsterdam</p>

        <p>Commercial Register: requested<br />
          Registration court: District Court Amsterdam</p>

        <p><strong>Represented by:</strong><br />
          Tommy Grimm<br />
          Jannik Noe</p>

        <h2>Contact</h2>
        <p>Phone: +49 (0) 4131 9921 080<br />
          Telefax: +49 (0) 4131 9921 081<br />
          E-mail: hello@fanrocket.net</p>

        <h2>VAT ID</h2>
        <p>Sales tax identification number according to Sect. 27 a of the Sales Tax Law:<br />
          requested</p>

        <h2>Person responsible for editorial</h2>
        <p>Tommy Grimm<br />
          20North Netherlands<br />
          Weesperstraat 61-105<br />
          1018VN Amsterdam</p>

        <h2>EU dispute resolution</h2>
        <p>The European Commission provides a platform for online dispute resolution (ODR): <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Our e-mail address can be found above in the site notice.</p>

        <h2>Dispute resolution proceedings in front of a consumer arbitration board</h2>
        <p>We are not willing or obliged to participate in dispute resolution proceedings in front of a consumer arbitration board.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'legalnotice',
  title: 'FanRocket | Legal Notice',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>
