<template>
  <footer id="publicfooter">
    <div class="content-1200 grid-12 pad-16">
      <div class="col-sp-12 col-tb-6 col-sd-3 row-sp-12 pad-8">
        <img id="FooterLogo" class="responsive-img" src="assets/images/fanrocket-logo-icon.png"/>
      </div>
      <div class="col-sp-12 col-tb-6 col-sd-3 row-sp-12 pad-8">
        <h5>Our Platform</h5>
        <ul>
          <li>
            <router-link to="/howitworks">
              How it works
            </router-link>
          </li>
          <li>
            <router-link to="/aboutus">
              About us
            </router-link>
          </li>
          <li>
            <router-link to="/faq">
              FAQ
            </router-link>
          </li>
          <li>
            <router-link to="/contact">
              Help & Contact
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-12 col-tb-6 col-sd-3 row-sp-12 pad-8">
        <h5>For Creator</h5>
        <ul>
          <li>
            <router-link to="/becomeacreator">
              Become a Creator
            </router-link>
          </li>
          <li>
            <router-link to="/management">
              About Management
            </router-link>
          </li>
          <li>
            <router-link to="/affiliateprogram">
              Affiliate Program
            </router-link>
          </li>
          <li>
            <router-link to="/createstudio">
              Create own Studio
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-12 col-tb-6 col-sd-3 row-sp-12 pad-8">
        <h5>Social Media</h5>
        <ul>
          <li>
            <a href="https://www.instagram.com/fanrocket.app/" target="_blank"><i class="instagram icon"></i> Instagram</a>
          </li>
          <li>
            <a href="https://twitter.com/FanRocketNET" target="_blank"><i class="twitter icon"></i> Twitter</a>
          </li>
        </ul>
      </div>
    </div>
    <div id="FooterBottom">
      <div class="content-1200 t-center">
        <div><p>Copyright &copy; 2022 <router-link to="/home">FanRocket&#174;</router-link> by 20North Netherlands | All rights reserved.</p></div>
        <router-link to="/legalnotice">Legal Notice</router-link> |
        <router-link to="/privacypolicy">Privacy Police</router-link> |
        <router-link to="/termsofuse">Terms of use</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'publicFooter'
}
</script>

<style scoped>
footer#publicfooter{
  background-color: rgba(0,0,0,0.9);
  width: 100%;
  color: rgba(255,255,255,0.8);
}
footer#publicfooter h5{
  text-align: left;
  position: relative;
  font-size: 1.1em;
  padding-bottom: 16px;
  padding-top: 32px;
}
footer#publicfooter ul li{
  list-style: none;
  padding: 2px 0;
}
footer#publicfooter ul li a{
  color: rgba(255,255,255,0.4);
  font-size: 0.8em;
  font-weight: 500;
  transition: 0.3s;
}
footer#publicfooter ul li a:hover{
  color: #AF57FF;
}
footer#publicfooter #FooterBottom{
  margin-top: 32px;
  background-color: rgba(0,0,0,0.2);
  width: 100%;
  padding: 24px;
  border-top: 1px solid rgba(0,0,0,0.1);
  color: rgba(255,255,255,0.6);
  font-size: 0.8em;
}
footer#publicfooter a{
  color: #AF57FF;
}
footer#publicfooter #FooterBottom p{
  font-weight: 400;
  color: rgba(255,255,255,0.6);
}
footer #FooterLogo{
  max-width: 100px;
  width: 100%;
  margin: 0 auto;
  display: block;
  margin-top: 40px;
}
/* Darkmode */
.dark footer#publicfooter{background-color: #1B1C1D;}
.dark footer#publicfooter #FooterBottom p{color: rgba(255,255,255,0.6);}
.dark footer#publicfooter a{color: #4bb7c3;}
.dark footer#publicfooter h5:after{background-color: #4bb7c3;}
.dark footer#publicfooter h5{color: rgba(255,255,255,0.8);}
</style>
