<template>
  <div class="ui tiny modal" id="ReportModal">
    <div class="rainbowloader" id="reportRainbow">
      <div class="loader-inner">
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
        <div class="loader-line-wrap">
          <div class="loader-line">
          </div>
        </div>
      </div>
    </div>
    <i class="close icon report"></i>
    <div class="header">
      <i class="flag icon"></i> Melden
    </div>
    <div class="content">
      <form class="ui form" method="post" id="reportForm" onsubmit="sendReport()">
        <input id="reportedID" type="hidden" value="" name="reportedID">
        <input id="reportedType" type="hidden" value="" name="reportedType">
        <input id="reportedCategory" type="hidden" value="" name="reportedCategory">
        <input id="reportedSpecialID" type="hidden" value="" name="reportedSpecialID">
        <div class="field">
          <div class="ui fluid dropdown" id="reportMain">
            <span class="text">Kategorie</span>
            <i class="dropdown icon"></i>
            <div class="menu" id="reportMainMenu">
            </div>
          </div>
          <div class="pad-8"></div>
          <div class="ui fluid dropdown" id="reportSub">
            <span class="text">Grund des Meldens</span>
            <i class="dropdown icon"></i>
            <div class="menu" id="reportSubMenu">
            </div>
          </div>
        </div>
        <div class="field" id="reportDescription">
          <label>Beschreibung</label>
          <div class="ui input">
            <textarea placeholder="Zusätzliche Bemerkung" id="reportedReason" name="reportedReason"></textarea>
          </div>
        </div>
        <div id="reportBlock">
          Sollte dich der User lediglich nerven, möchten wir dir hierfür unsere Blockier-Funktion empfehlen.
          Sobald du den Nutzer blockiert hast, wirst du keine Inhalte mehr von ihm sehen können.
          Ebenso bleiben auch deine Inhalte vor ihm versteckt.
        </div>
      </form>
    </div>
    <div class="actions">
      <div class="ui button" onclick="$('#ReportModal.ui.modal').modal('hide');">
        Abbrechen
      </div>
      <button class="ui btn-color-3 right labeled icon button" onClick="sendReport();" id="reportSend">
        Melden
        <i class="exclamation icon"></i>
      </button>
    </div>
  </div>
  <div class="ui mini modal" id="reportOk">
    <i class="close icon"></i>
    <div class="header">Danke für dein Report</div>
    <div class="content"><p>Wir werden uns um dein Anliegen schnellstmöglich kümmern.</p></div>
  </div>
</template>

<script>
export default {
  name: 'report'
}
</script>

<style scoped>

</style>
