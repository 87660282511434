/* eslint-disable no-prototype-builtins */
import axios from 'axios'
import { urlify, nl2br, projectURL, findGetParameter, sleepTight } from '@/modules/main'
import router from '@/router'
export function urlBase64ToUint8Array (base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding)
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export function init () {
  return new Promise(function (resolve, reject) {
    const data = new FormData()
    data.append('SessionID', sessionStorage.getItem('SessionID'))
    axios.post(window.projectURL + 'dashboard/getData', data)
      .then(function (response) {
        if (response.status === 250) {
          resolve(response.data)
        } else {
          reject(response.status)
        }
      }).catch(function (err) {
        reject(err)
      })
  })
}

export function initInterest () {
  if (localStorage.getItem('account_type') === 'admin' || localStorage.getItem('account_type') === 'supporter') {
    $('#maxDiB').find('.menu').append('<div class="item" data-value="9999">Global</div>')
    $('#maxDiB').dropdown('refresh')
  }

  $('.setInterests').click(function () {
    let count = 0
    $('.setInterests').each(function () {
      if ($(this).hasClass('active'))count++
    })
    const add = $(this).hasClass('active') ? 0 : 1
    if (count < 4 && add === 0) {
      alert('Mindestens 3 müssen gewählt sein.')
    } else {
      reloadInterest = true
      const interest = $(this).data('interest')
      $.ajax({
        url: window.projectURL + 'profile/setInterest',
        type: 'POST',
        data: { interest: interest, add: add, postkey: sessionStorage.getItem('postkey') },
        success: function (data) {
          localStorage.setItem('subscribed_channels', data)
        }
      })
      if (add === 1) {
        $(this).addClass('active')
      } else {
        $(this).removeClass('active')
      }
    }
  })
}

export function Wall (wallInit = 'wall', profile = null, search = false) {
  const nodeidc = sessionStorage.getItem('NodeID')
  const message = $('#postText')
  const postImage = $('#postimage')
  const postForm = $('#postIt')
  const sendButton = $('#postButton')
  let postContent = $('#posts')
  const hoverModalImg = $('#hoverModalImg')
  const hoverModalFirstName = $('#hoverModalFirstName')
  const hoverModalUserName = $('#hoverModalUserName')
  const hoverModal = $('#hoverModal')
  const scrollToTop = $('.scrollToTop')
  const trendingHashtags = $('#trendingHashtags')

  let posts = {}
  let started = false
  let hoverName = null
  let hoverState = false
  let channel = null
  const thisWall = this
  let oldestPostID = -1
  let oldestPostTime = 9999999999999
  let newestPostID = -1
  let newestPostTime = -1
  let newestCommentID = -1
  let loadingMore = true
  let wallType = wallInit
  let scrollHash = false
  let trendingHashtagArray = []
  // url = new URL(window.location.href),
  let sending = 0
  let currentTextField = message
  let distance = (localStorage.getItem('maxDistance') == null) ? 500 : localStorage.getItem('maxDistance')
  const imageArray = ['thumbs-up.png', 'smiling-face-with-hearts.png', 'sad-but-relieved-face.png', 'rolling-on-the-floor-laughing.png', 'rainbow-flag.png', 'hugging-face.png', 'angry-face.png', 'sweet.png', 'flame.png']

  $('#maxDiB').dropdown('set selected', distance)
  $('#maxDiB').dropdown('set value', distance)

  /* ---------------------------SOCKETS------------------------------- */

  this.activateSocket = function () {
    window.socket.removeAllListeners('trendingHashtags')
    window.socket.removeAllListeners('wallPosts')
    window.socket.removeAllListeners('newWallPosts')
    window.socket.removeAllListeners('wallComments')
    window.socket.removeAllListeners('newPost')
    window.socket.removeAllListeners('newComment')
    window.socket.removeAllListeners('deletedPost')
    window.socket.removeAllListeners('deletedComment')
    window.socket.removeAllListeners('typingPost')
    window.socket.removeAllListeners('newPostLike')
    window.socket.removeAllListeners('newCommentLike')

    // get trending hashtags
    window.socket.on('trendingHashtags', (data) => {
      $('#hashtagLoader').remove()
      trendingHashtagArray = data
      for (const key in data) {
        let hashTag = data[key].hashtag
        if (hashTag === 'croissant') { hashTag = String.fromCodePoint('0x1F950') + hashTag }
        if (hashTag === 'uwusbubumeow') { hashTag = String.fromCodePoint('0x1F63B') + hashTag }
        if (hashTag === 'gn8') { hashTag = hashTag + String.fromCodePoint('0x1F4A4') }

        if (trendingHashtags.find('#trendingHashtag_' + data[key].hashtag).length === 0) {
          trendingHashtags.append('<a id="trendingHashtag_' + data[key].hashtag + '" class="ui violet label trendingHashTag" onclick="window.wall.hashtag(\'' + data[key].hashtag + '\')">#' + hashTag + '</a>')
        }
      }
      if (wallType === 'hashtag') {
        if (search && search.searchString !== '') {
          window.wall.hashtag(search.searchString)
        } else {
          window.wall.hashtag(data[0].hashtag)
        }
      }
    })
    // get wall posts
    window.socket.on('wallPosts', (data) => {
      try {
        $('#Postloader').remove()
        for (const key in data) {
          if (!posts.hasOwnProperty(data[key].id)) {
            posts[data[key].id] = new Post(data[key])
            posts[data[key].id].render(false, true)
            if (wallType === 'single')posts[data[key].id].commentShow = 999
          } else {
            posts[data[key].id].render(false, true)
            if (wallType === 'single')posts[data[key].id].commentShow = 999
            posts[data[key].id].countComments()
            posts[data[key].id].expandComments()
          }
          if (wallType === 'galery') {
            $(document).scrollTop(posts[data[key].id].element.offset().top - 60)
          }
        }
        loadingMore = (data.length < 10)
      } catch (e) {
        console.error(e)
      }
      if (data.length > 0 && wallType !== 'single' && wallType !== 'galery')loadingMore = false

      try {
        if (scrollHash) {
          $('html, body').stop().animate({ scrollTop: ($('#wallChannels').offset().top - 75) }, 500, 'swing')
          scrollHash = false
        }
      } catch (e) { }
    })
    // get new wall posts
    window.socket.on('newWallPosts', (data) => {
      const tempPosts = posts
      posts = {}
      try {
        for (const key in data) {
          $('#Postloader').remove()
          if (!tempPosts.hasOwnProperty(data[key].id)) {
            posts[data[key].id] = new Post(data[key])
            posts[data[key].id].render(true)
            // scrollToTop.text('Neuer Post');
          }
        }
        for (const key in tempPosts) {
          posts[key] = tempPosts[key]
        }
      } catch (e) {
        console.error(e)
      }
    })
    // get wall comments
    window.socket.on('wallComments', (data) => {
      if (posts.hasOwnProperty(data.postID)) {
        for (const key in data.comments) {
          if (!posts[data.postID].comments.hasOwnProperty(data.comments[key].id) && data.comments[key].id != null) {
            posts[data.postID].comments[data.comments[key].id] = new Comment(data.comments[key], posts[data.postID])
          }
        }
        posts[data.postID].countComments()
        posts[data.postID].expandComments()
        const commentActive = window.fanrocket.$route.params.comment
        if (wallType === 'single' && commentActive !== 'null' && commentActive !== undefined && commentActive !== 'undefined') {
          try {
            $('html').scrollTop($('#c_' + commentActive).offset().top - 200)
            $('#c_' + commentActive).addClass('activeComment')
          } catch (e) {}
        }
      }
    })
    // add new posts
    window.socket.on('newPost', (data) => {
      if (!window.smids.includes(data.smid)) {
        window.smids.push(data.smid)
        if (wallType === 'wall') {
          window.socket.emit('loadSinglePost', { type: 'new', lastPostID: data.id, room: nodeidc, distance: distance, channel: channel })
        }
      }
    })
    // add new comments
    window.socket.on('newComment', (data) => {
      if (!window.smids.includes(data.smid)) {
        window.smids.push(data.smid)
        if (posts.hasOwnProperty(data.postID)) {
          window.socket.emit('loadSingleComment', { postID: data.postID, commentID: data.commentID, room: nodeidc })
        }
      }
    })
    // deleted post
    window.socket.on('deletedPost', (data) => {
      try {
        if (posts.hasOwnProperty(data.postID)) {
          posts[data.postID].element.remove()
          delete (posts[data.postID])
          try {
            $('#galery_' + data.postID).parent().remove()
          } catch (e) {}
        }
      } catch (e) {}
    })
    // deleted comment
    window.socket.on('deletedComment', (data) => {
      if (posts.hasOwnProperty(data.postID) && posts[data.postID].comments.hasOwnProperty(data.commentID)) {
        const comment = posts[data.postID].comments[data.commentID]
        comment.content = '<div class="chat-message-text-deleted">Kommentar gelöscht</div>'
        comment.deleted = 1
        comment.element.find('.commentText').html(comment.content)
        comment.element.find('.comment-actions').remove()
        comment.element.find('.commentActionPoints').remove()
      }
    })
    // show typing users
    window.socket.on('typingPost', (data) => {
      // if(posts.hasOwnProperty(data.postID)){
      if (data.userID !== window.user_id && posts.hasOwnProperty(data.postID)) {
        posts[data.postID].typingUser(data.userID, data.userProfileImg)
      }
    })
    // set new likes on post
    window.socket.on('newPostLike', (data) => {
      if (!window.smids.includes(data.smid)) {
        window.smids.push(data.smid)
        if (posts.hasOwnProperty(data.id)) {
          posts[data.id].likes = []

          posts[data.id].likeTypes = data.like_types.split(',')
          posts[data.id].likeCounts = data.like_counts.split(',')

          posts[data.id].setLikes()
        }
      }
    })
    // set new likes on post
    window.socket.on('newCommentLike', (data) => {
      if (!window.smids.includes(data.smid)) {
        window.smids.push(data.smid)
        if (posts.hasOwnProperty(data.post_id) && posts[data.post_id].comments.hasOwnProperty(data.id)) {
          posts[data.post_id].comments[data.id].likes = []

          posts[data.post_id].comments[data.id].likeTypes = data.like_types.split(',')
          posts[data.post_id].comments[data.id].likeCounts = data.like_counts.split(',')

          posts[data.post_id].comments[data.id].setLikes()
        }
      }
    })

    if (newestPostID !== -1 && newestCommentID !== -1)window.socket.emit('updateWall', { room: nodeidc, lastPostID: newestPostID, newestCommentID: newestCommentID, distance: distance, channel: channel })
  }

  /* -------------------------MAIN FUNCTIONS-------------------------- */

  this.bye = function () {
    window.socket.removeAllListeners('trendingHashtags')
    window.socket.removeAllListeners('wallPosts')
    window.socket.removeAllListeners('newWallPosts')
    window.socket.removeAllListeners('wallComments')
    window.socket.removeAllListeners('newPost')
    window.socket.removeAllListeners('newComment')
    window.socket.removeAllListeners('deletedPost')
    window.socket.removeAllListeners('deletedComment')
    window.socket.removeAllListeners('typingPost')
    window.socket.removeAllListeners('newPostLike')
    window.socket.removeAllListeners('newCommentLike')

    $(document).off('scroll', scrollFunction)
    window.wall = undefined
  }

  this.deletePost = function (postID) {
    $('.ui.deletePost.modal')
      .modal({
        closable: true,
        onDeny: function () {
          return true
        },
        onApprove: function () {
          window.socket.emit('deletePost', { room: nodeidc, uname: window.uname, postID: postID })
        }
      })
      .modal('show')
  }

  this.deleteComment = function (commentID, postID) {
    $('.ui.deleteComment.modal')
      .modal({
        closable: true,
        onDeny: function () {
          return true
        },
        onApprove: function () {
          window.socket.emit('deleteComment', { room: nodeidc, uname: window.uname, commentID: commentID, postID: postID })
        }
      })
      .modal('show')
  }

  this.expandComments = function (postID) {
    try {
      posts[postID].commentShow += 10
      posts[postID].expandComments()
    } catch (e) {}
  }

  this.hashtag = function (hashtag) {
    $('#resetWall').show()
    $('#channelSelect').val('hashtag')
    $('#wallChannelsText').text('#' + hashtag)

    scrollHash = true

    wallType = 'hashtag'
    channel = hashtag
    oldestPostID = -1
    oldestPostTime = 9999999999999
    changeChannel()
  }

  this.resetWall = function (wtype) {
    $('#resetWall').hide()
    wallType = wtype
    channel = localStorage.getItem('currentProfile')
    oldestPostID = -1
    oldestPostTime = 9999999999999
    changeChannel()
  }

  this.likePost = function (postID, type) {
    const formdata = new FormData()
    formdata.append('postid', postID)
    formdata.append('type', type)
    formdata.append('postkey', sessionStorage.getItem('postkey'))
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: projectURL + 'wall/newLike',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          try {
            posts[postID].ownLike = type
            posts[postID].setOwnLike()
          } catch (e) {}
        } else if (xhr.status === 253) {
          errorMessageMTi.innerHTML = 'Geblockt <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Der User des Posts blockt dich leider.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 252) {
          errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Leider ging hier etwas schief.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 255) {
          errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Leider ging hier etwas schief.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 299) {
          cookieLogin().then(() => { window.wall.likePost(postID, type) }).catch(openLogin)
        }
      },
      cache: false,
      contentType: false,
      processData: false
    })
  }

  this.likeComment = function (commentID, type, postID) {
    const formdata = new FormData()
    formdata.append('commentid', commentID)
    formdata.append('type', type)
    formdata.append('postkey', sessionStorage.getItem('postkey'))
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: projectURL + 'wall/newCommentLike',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          try {
            posts[postID].comments[commentID].ownLike = type
            posts[postID].comments[commentID].setOwnLike()
          } catch (e) {}
        } else if (xhr.status === 253) {
          errorMessageMTi.innerHTML = 'Geblockt <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Der User des Kommentars blockt dich leider.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 252) {
          errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Leider ging hier etwas schief.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 255) {
          errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
          errorMessageMT.innerHTML = 'Leider ging hier etwas schief.'
          $('#errorModal').modal('show')
        } else if (xhr.status === 299) {
          cookieLogin().then(() => { window.wall.likeComment(commentID, type, postID) }).catch(openLogin)
        }
      },
      cache: false,
      contentType: false,
      processData: false
    })
  }

  this.loadSingle = function (id) {
    $('#resetWall').show()
    wallType = 'galery'
    channel = null
    oldestPostID = id
    changeChannel()
  }

  /* this.loadWall = function (){
        $.post("/wall/getPosts", function (data, status) {
            try {
                let wallData = JSON.parse(data);
                for(let key in wallData){
                    posts[key] = new post(wallData[key]);
                    posts[key].render();
                    posts[key].loadComments();
                }
            }
            catch (e) {
                console.error(e);
            }
        });
    } */

  this.reloadWall = function () {
    if (window.time / 1000 + 60 * 10 < Date.now() / 1000) {
      posts = {}
      started = false
      $('#posts').empty()
      this.startWall()
    }
  }

  /* -------------------------HELPER FUNCTION------------------------- */

  function changeChannel () {
    const loader = '<div class="rainbowloader" id="Postloader">\n' +
      '            <div class="loader-inner">\n' +
      '                <div class="loader-line-wrap">\n' +
      '                    <div class="loader-line">\n' +
      '                    </div>\n' +
      '                </div>\n' +
      '                <div class="loader-line-wrap">\n' +
      '                    <div class="loader-line">\n' +
      '                    </div>\n' +
      '                </div>\n' +
      '                <div class="loader-line-wrap">\n' +
      '                    <div class="loader-line">\n' +
      '                    </div>\n' +
      '                </div>\n' +
      '                <div class="loader-line-wrap">\n' +
      '                    <div class="loader-line">\n' +
      '                    </div>\n' +
      '                </div>\n' +
      '                <div class="loader-line-wrap">\n' +
      '                    <div class="loader-line">\n' +
      '                    </div>\n' +
      '                </div>\n' +
      '            </div>\n' +
      '        </div>'

    for (const key in posts) {
      posts[key].element = null
    }
    postContent = $('#posts')
    postContent.html(loader)
    window.socket.emit('loadPosts', { room: nodeidc, type: wallType, lastPostID: oldestPostID, channel: channel, distance: distance })
  }

  function emojiMenue () {
    const nextInput = $('#emojiCon_' + $(this).data('menu'))
    currentTextField = ($(this).data('menu') === 0) ? message : $('#textFiel_' + $(this).data('menu'))
    if (nextInput.find('#EmoticonsMenu').length > 0) {
      $('#EmoticonsMenu').toggle()
    } else {
      $('#EmoticonsMenu').detach().appendTo(nextInput)
      $('#EmoticonsMenu').show()
    }
  }

  function makeFabolous (element) {
    element.find('.wallHashtag').each(function () {
      const hashtag = $(this).text().substring(1).toLowerCase()
      if (hashtag.startsWith('pride') || hashtag.endsWith('pride') || hashtag.startsWith('gay') || hashtag === 'proud' || hashtag === 'regenbogen' || hashtag === 'rainbow' || hashtag === 'pogo' || hashtag === 'tobias' || hashtag === 'fanrocket' || hashtag === 'dennis') {
        $(this).addClass('fabolous')
      } else if (hashtag === 'tommy' || hashtag === 'tommygr') {
        $(this).addClass('tommy')
      } else if (hashtag === 'croissant') {
        $(this).text('#' + String.fromCodePoint('0x1F950') + $(this).text().substring(1))
      } else if (hashtag === 'uwusbubumeow') {
        $(this).text('#' + String.fromCodePoint('0x1F63B') + $(this).text().substring(1))
      } else if (hashtag === 'bug') {
        $(this).html('#<del>Bug</del> <span class="feature">Feature</span>')
      } else if (hashtag === 'gn8') {
        $(this).text($(this).text() + String.fromCodePoint('0x1F4A4'))
      }
    })
  }

  /* ---------------------------OBJECTS------------------------------- */

  // post object
  function Post (obj) {
    // variables and constans
    this.id = obj.id
    this.type = obj.type
    this.timestamp = obj.timestamp
    this.channel = obj.channel
    this.content = urlify(nl2br(obj.content))
    this.image = obj.image
    this.video = obj.video
    this.videoType = obj.videoType
    this.firstname = obj.firstname
    this.username = obj.username
    this.userID = obj.user_id
    this.profile_img = obj.profile_img
    this.online = obj.last_active_timestamp
    this.verified = obj.verified
    this.city = obj.cname
    this.distance = obj.distance
    this.channelID = obj.channel_id
    this.channelType = obj.channel_type
    this.channelTitle = obj.channel_title
    this.channelHashtag = obj.channel_hashtags
    this.channelImg = obj.channel_img
    this.ownLike = obj.ownlike
    this.likeTypes = (obj.like_types == null) ? null : obj.like_types.split(',')
    this.likeCounts = (obj.like_counts == null) ? null : obj.like_counts.split(',')
    this.likes = []
    this.comments = {}
    this.secondsTimer = true
    this.minuteTimer = true
    this.commentShow = 5
    this.lastTyping = 0
    this.lastTypingUser = 0
    this.lastTypingUserObject = {}
    this.commentLoaded = false
    this.element = null

    const thisPost = this
    let typingStop = null
    const arr = this.timestamp.split(/[- :.T]/)
    const timeFormated = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    const menue = (this.userID !== parseInt(localStorage.getItem('user_id'))) ? '<div class="item" onclick="report(\'p\',\'' + this.id + '\',\'' + this.userID + '\');"><i class="exclamation icon"></i>Post melden</div>' : '<div class="item" onclick="window.wall.deletePost(\'' + this.id + '\');"><i class="delete icon"></i>Post löschen</div>'

    // calculate timestring
    this.calculateTime = function () {
      let remainTime = Math.floor((Date.now() - timeFormated) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sek.'
      if (remainTime <= 0)timeString = 'Jetzt'
      // if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Sekunde'
      if (remainTime > 59) {
        thisPost.secondsTimer = false
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Min.'
        // if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Std.'
          // if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            thisPost.minuteTimer = false
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(thisPost.timestamp)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    }

    this.contentDiv =
      '                       <div class="ui fluid no-shadow no-border card post" id="p_' + this.id + '">\n' +
      '                        <div class="content">\n' +
      '                            <div class="right floated meta">\n' +
      '                                <p class="remainingTime">' + this.calculateTime() + '</p>\n' +
      '                                <div class="ui right pointing dropdown icon">\n' +
      '                                    <i class="ellipsis vertical icon"></i>\n' +
      '                                    <div class="menu" id="actionMenu_' + this.id + '">\n' + menue +
      '                                    </div>\n' +
      '                                </div>\n' +
      '                            </div>\n' +
      '                            <h5 class="ui header active">\n' +
      '                               <a onclick="router.push(\'/p/' + this.username + '\')">' +
      '                                   <img class="ui avatar image" src="' + projectURL + 'assets/images/user/' + this.profile_img + '">\n' +
      '                                   <div class="content">\n' +
      '                                       ' + this.username + '\n' +
      '                                       <div class="sub header active">\n' +
      '                                           <i class="map marker alternate icon"></i> ' + this.city + ' (' + this.distance + ' km)\n' +
      '                                       </div>\n' +
      '                                   </div>\n' +
      '                               </a>' +
      '                            </h5>\n' +
      '                        </div>\n' +
      '                        <div class="image pimg">\n' +
      '                            <img class="post_img">\n' +
      '                        </div>\n' +
      '                        <div class="content postparagraph">\n' +
      '                            <p>' + this.content + '</p>\n' +
      '                            <div>\n' +
      '                                <a class="ui label mini postChannel">\n' +
      '                                    <img class="postChannel" src=""> <span class="postChannel">Filme &amp; Serien</span>\n' +
      '                                </a>\n' +
      '                            </div>\n' +
      '                        </div>\n' +
      '                        <div class="comment-cta content noPadBottom">\n' +
      '                            <span class="right floated">\n' +
      '                                <div class="ui icon bottom no-shadow right pointing dropdown basic button like-select-dropdown"  id="PostReactMenu_' + this.id + '" tabindex="0">\n' +
      '                                    <span class="like-button-select ownLikePost">\n' +
      '                                        <i class="heart icon"></i> Like\n' +
      '                                    </span>\n' +
      '                                    <div class="menu transition hidden" id="PostReactMenu_' + this.id + '" tabindex="-1">\n' +
      '                                        <div class="grid-12" style="width: 120px; height: 120px;">\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-7" onclick="window.wall.likePost(\'' + this.id + '\',\'7\')">\n' +
      '                                                    <img src="assets/images/likes/angry-face.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-3" onclick="window.wall.likePost(\'' + this.id + '\',\'3\')">\n' +
      '                                                    <img src="assets/images/likes/sad-but-relieved-face.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-4" onclick="window.wall.likePost(\'' + this.id + '\',\'4\')">\n' +
      '                                                    <img src="assets/images/likes/rolling-on-the-floor-laughing.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-2" onclick="window.wall.likePost(\'' + this.id + '\',\'2\')">\n' +
      '                                                    <img src="assets/images/likes/smiling-face-with-hearts.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-6" onclick="window.wall.likePost(\'' + this.id + '\',\'6\')">\n' +
      '                                                    <img src="assets/images/likes/hugging-face.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-1" onclick="window.wall.likePost(\'' + this.id + '\',\'1\')">\n' +
      '                                                    <img src="assets/images/likes/thumbs-up.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-9" onclick="window.wall.likePost(\'' + this.id + '\',\'9\')">\n' +
      '                                                    <img src="assets/images/likes/flame.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-8" onclick="window.wall.likePost(\'' + this.id + '\',\'8\')">\n' +
      '                                                    <img src="assets/images/likes/sweet.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                            <div class="item col-sp-4 row-sp-12">\n' +
      '                                                <button class="like-button like-button-type-5" onclick="window.wall.likePost(\'' + this.id + '\',\'5\')">\n' +
      '                                                    <img src="assets/images/likes/rainbow-flag.png" class="">\n' +
      '                                                </button>\n' +
      '                                            </div>\n' +
      '                                        </div>\n' +
      '                                    </div>\n' +
      '                                </div>\n' +
      '                            </span>\n' +
      '                            <div class="posts-likes openLikeDetails">\n' +
      '                            </div>\n' +
      '                        </div>\n' +
      '                        <div class="comment-cta content likeDetails">\n' +
      '                            <div class="posts-likes">\n' +
      '                            </div>\n' +
      '                        </div>\n' +
      '                        <h5 class="ui horizontal divider header active commentCount">\n' +
      '                        </h5>\n' +
      '                        <div class="commentSec">\n' +
      '                            <div class="content comments-content">\n' +
      '                            </div>\n' +
      '                        </div>\n' +
      '                       <div class="typingPost" style="display: none;">...</div>' +
      '                        <div class="extra content">\n' +
      '                            <div class="ui mini left icon fluid input">\n' +
      '                                <i class="comment outline icon"></i>\n' +
      '                                <input type="search" class="commentAnswer" id="textFiel_' + this.id + '" data-post="' + thisPost.id + '" placeholder="Schreibe ein Kommentar..." autocomplete="off">\n' +
      '                                <div class="ui button basic icon smileyMenue display-Dektop-inline" data-menu="' + this.id + '">' +
      '                                    <i class="smile outline icon"></i>' +
      '                                </div>' +
      '                                <button class="ui right floated mini right button post-comment-button" data-post="' + thisPost.id + '">Posten</button>\n' +
      '                            </div>\n' +
      '                        </div>\n' +
      '                        <div class="emojiCon" id="emojiCon_' + this.id + '"></div>' +
      '                    </div>'

    // load comments via socket
    this.loadComments = function () {
      window.socket.emit('loadComments', { room: nodeidc, postID: this.id })
    }

    // set typing user
    this.typingUser = function (userID, userProfileImg) {
      try {
        this.lastTypingUser = Date.now()

        thisPost.element.find('.typingPost').stop().css('opacity', '1').show()

        if (this.lastTypingUserObject.hasOwnProperty(userID)) {
          thisPost.element.find('#typing_' + userID).stop().css('opacity', '1').show()
        } else {
          thisPost.element.find('.typingPost').prepend('<img class="typingPostUser" id="typing_' + userID + '" src="' + projectURL + 'assets/images/user/' + userProfileImg + '">')
        }
        this.lastTypingUserObject[userID] = Date.now()

        typingStop = window.setInterval(function () {
          if ((Date.now() - thisPost.lastTypingUser) > 3500) {
            thisPost.element.find('.typingPost').fadeOut('slow')
            clearInterval(typingStop)
            typingStop = null
          }

          for (const key in thisPost.lastTypingUserObject) {
            if ((Date.now() - thisPost.lastTypingUserObject[key]) > 3500) {
              if ((Date.now() - thisPost.lastTypingUserObject[key]) > 4000) {
                thisPost.element.find('#typing_' + key).remove()
                delete thisPost.lastTypingUserObject[key]
              } else {
                thisPost.element.find('#typing_' + key).fadeOut('slow')
              }
            }
          }
        }, 500)
      } catch (e) {}
    }

    // refresh posttime seconds
    this.refreshSeconds = function () {
      if (this.secondsTimer && thisPost.element != null && thisPost.element !== undefined) {
        thisPost.element.find('.remainingTime').text(thisPost.calculateTime())
      }
    }

    // refresh posttime general
    this.refreshMinutes = function () {
      if (this.minuteTimer && thisPost.element != null && thisPost.element !== undefined) {
        thisPost.element.find('.remainingTime').text(thisPost.calculateTime())
      }
    }

    // bind listener to textfield and button
    this.bindListener = function () {
      this.element.find('.post-comment-button').click(function () {
        if (window.fanrocket.socketOnline) {
          const textField = $(this).siblings('input')
          let sendallowed = 0
          const sendButton = $(this)

          textField.val(textField.val().replace(/[\n\r|\r\n|\r|\n| ]{3,}/g, '\r\n\r\n'))
          if (textField.val().replace(/[\n\r|\r\n|\r|\n| ]{3,}/g, '').length !== 0 || postImage[0].files.length === 1) {
            sendButton.prop('disabled', true)
            sendallowed = 1
          }

          const commentData = new FormData()
          const pid = $(this).data('post')
          commentData.append('postid', pid)
          commentData.append('commentcontent', textField.val())
          commentData.append('postkey', sessionStorage.getItem('postkey'))
          commentData.append('SessionID', sessionStorage.getItem('SessionID'))

          if (sendallowed === 1 && sending === 0) {
            sending = 1
            $.ajax({
              url: projectURL + 'wall/newComment',
              type: 'POST',
              data: commentData,
              success: function (data, textStatus, xhr) {
                // console.log(data);
                // console.log(xhr.status);
                sending = 0
                if (xhr.status === 250) {
                  textField.val('')
                  posts[pid].commentShow = 999999
                  posts[pid].expandComments()
                  sendButton.prop('disabled', false)
                } else if (xhr.status === 252) {
                  errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
                  errorMessageMT.innerHTML = 'Post nicht gefunden'
                  $('#errorModal').modal('show')
                  sendButton.prop('disabled', false)
                } else if (xhr.status === 253) {
                  errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
                  errorMessageMT.innerHTML = 'Der User blockt dich.'
                  $('#errorModal').modal('show')
                  sendButton.prop('disabled', false)
                } else if (xhr.status === 299) {
                  cookieLogin().catch(openLogin)
                }
              },
              error: function (data) {
                sendallowed = 0
                sending = 0
                sendButton.prop('disabled', false)
              },
              cache: false,
              contentType: false,
              processData: false
            })
          }
        }
      })
      this.element.find('.commentAnswer').on('keyup change', function (event) {
        if (event.keyCode === 13 && !event.shiftKey) {
          event.preventDefault()
          $(this).siblings('.post-comment-button').click()
        }

        if ((Date.now() - posts[$(this).data('post')].lastTyping) > 2500) {
          posts[$(this).data('post')].lastTyping = Date.now()
          window.socket.emit('typingPost', { room: nodeidc, postID: $(this).data('post') })
        }
      })

      this.element.find('.smileyMenue').on('click', emojiMenue)
      this.element.find('.openLikeDetails').on('click', function () {
        const likeDetails = $(this).parent().next().toggleClass('likeDetailsActive')
      })
    }

    // bind hover modal
    this.hoverModal = function () {
      this.element.find('*').filter(function () {
        if ($(this).data('hover') !== undefined) {
          const name = $(this).data('hover')
          $(this).on('mouseenter', function (e) {
            hoverState = true
            hoverModal.css({ top: e.pageY, left: e.pageX })
            $.ajax({
              url: projectURL + 'wall/getMention',
              type: 'POST',
              data: { SessionID: sessionStorage.getItem('SessionID'), username: name },
              success: function (data, textStatus, xhr) {
                data = JSON.parse(data)
                if (xhr.status === 250) {
                  if (hoverState) {
                    hoverModalImg.attr('src', projectURL + 'assets/images/user/' + data.profile_img)
                    hoverModalFirstName.text(data.firstname)
                    hoverModalUserName.text(data.username)
                    hoverModal.show()
                  }
                  hoverName = name

                  hoverModal.off()
                  hoverModal.on('click', function () {
                    $(this).hide()
                  })
                }
              }
            })
          })
          $(this).on('mouseleave', function (e) {
            hoverModal.hide()
            hoverState = false
            hoverModalImg.removeAttr('src')
          })
          $(this).on('click touchend ', function (e) {
            e.stopPropagation()
            e.preventDefault()
            if (e.type === 'touchend') {
              if (hoverModal.is(':visible') && $(this).data('hover').substring(1) === hoverName) {
                router.push('/p/' + name)
              } else {
                hoverModal.css({ top: e.pageY, left: e.pageX })
                $.ajax({
                  url: projectURL + 'wall/getMention',
                  type: 'POST',
                  data: { SessionID: sessionStorage.getItem('SessionID'), username: name },
                  success: function (data, textStatus, xhr) {
                    data = JSON.parse(data)
                    if (xhr.status === 250) {
                      hoverModalImg.attr('src', projectURL + 'assets/images/user/' + data.profile_img)
                      hoverModalFirstName.text(data.firstname)
                      hoverModalUserName.text(data.username)
                      hoverModal.show()
                      hoverName = name

                      hoverModal.off()
                      hoverModal.on('click', function () {
                        router.push('/p/' + name)
                      })
                    }
                  }
                })
              }
            } else if (e.type === 'click') {
              router.push('/p/' + name)
            }
          })
        }
      })
    }

    // set channelicon
    this.setChannel = function () {
      if (this.channelID == null) {
        this.element.find('a.postChannel').remove()
      } else {
        this.element.find('img.postChannel').attr('src', 'assets/images/channels/' + this.channelImg)
        this.element.find('span.postChannel').text(this.channelTitle)
      }
    }

    // set likes
    this.setLikes = function () {
      try {
        let counter = 0
        const countArray = []
        for (const key in this.likeTypes) {
          this.likes[this.likeTypes[key]] = this.likeCounts[key]
          countArray.push({ id: this.likeTypes[key], count: this.likeCounts[key] })
          counter += parseInt(this.likeCounts[key])
        }

        countArray.sort((a, b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
        const tempSort = countArray.slice(0, 3)
        const openLikes = this.element.find('.openLikeDetails')
        const postLikes = this.element.find('.likeDetails .posts-likes')
        openLikes.empty()
        postLikes.empty()
        for (const key in tempSort) {
          openLikes.append('<img src="assets/images/likes/' + imageArray[tempSort[key].id - 1] + '" class="like_type">')
        }
        for (const key in countArray) {
          postLikes.append('<div class="ui button basic mini"><img src="assets/images/likes/' + imageArray[countArray[key].id - 1] + '">' + countArray[key].count + '</div>')
        }
        if (tempSort.length > 0)openLikes.append('<span class="like_type">' + counter + '</span>')
      } catch (e) { console.log('post-setlikes' + e) }
    }

    // set own like
    this.setOwnLike = function () {
      try {
        if (this.ownLike > 0) this.element.find('.ownLikePost').html('<img src="assets/images/likes/' + imageArray[this.ownLike - 1] + '">')
      } catch (e) { console.log('post-setownlikes' + e) }
    }

    // set post image
    this.setImg = function () {
      if (this.image != null && this.image !== '') {
        this.element.find('.post_img').attr('src', projectURL + '/assets/images/posts/' + this.image)
      } else if (this.video !== undefined) {
        this.element.find('.pimg').addClass('postVideo')
        if (this.videoType === 'youtube') {
          this.element.find('.pimg').html('<iframe src="https://www.youtube.com/embed/' + this.video + '" frameborder="0" allowfullscreen></iframe>')
        } else if (this.videoType === 'tiktok') {

        }
      } else {
        this.element.find('.pimg').remove()
      }
    }

    // count comment
    this.countComments = function () {
      try {
        let counter = 0
        for (const key in thisPost.comments) {
          counter++
        }
        switch (counter) {
          case 0:
            this.element.find('.commentCount').hide()
            break

          case 1:
            this.element.find('.commentCount').show()
            this.element.find('.commentCount').html('<i class="comments icon"></i>' + counter + ' Kommentar')
            break

          default:
            this.element.find('.commentCount').show()
            this.element.find('.commentCount').html('<i class="comments icon"></i>' + counter + ' Kommentare')
            break
        }
      } catch (e) {}
    }

    // show more comments
    this.expandComments = function () {
      try {
        thisPost.element.find('.loadMoreComments').remove()
        let counter = 0
        for (const key in thisPost.comments) {
          counter++
          if (counter < (thisPost.commentShow + 1)) {
            thisPost.comments[key].render()
          } else if (counter === (thisPost.commentShow + 1)) {
            const moreButton = '<a class="loadMoreComments" onclick="window.wall.expandComments(\'' + thisPost.id + '\')"><button class="ui fluid mini button btn-inverted-color">Mehr</button></a>'
            thisPost.element.find('.comments-content').append($('<div/>').html(moreButton).contents())
          }
        }
      } catch (e) {}
    }

    // get newest and oldest id
    this.timeID = function () {
      if (timeFormated < oldestPostTime) {
        oldestPostTime = timeFormated
        oldestPostID = obj.id
      }
      if (timeFormated > newestPostTime) {
        newestPostTime = timeFormated
        newestPostID = obj.id
      }
    }

    // render post
    this.render = function (top = false, init = false) {
      postContent = $('#posts')
      try {
        if (this.element == null && ((wallType === 'channel' && channel === this.channelID) || wallType === 'wall' || init)) {
          if (top) {
            postContent.prepend(this.contentDiv)
          } else {
            postContent.append(this.contentDiv)
          }
          this.element = postContent.find('#p_' + this.id)
          this.element.find('.dropdown').dropdown({
            action: 'combo'
          })
          if (this.userID === parseInt(localStorage.getItem('user_id'))) this.element.find('#PostReactMenu_' + this.id).hide()
          if (!this.commentLoaded) {
            this.loadComments()
            this.commentLoaded = true
          }
          this.hoverModal()
          this.setChannel()
          this.setLikes()
          this.setOwnLike()
          this.setImg()
          this.bindListener()
          this.timeID()
          makeFabolous(this.element)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  // comment object
  function Comment (obj, parent) {
    // variables and constans
    this.parent = parent
    this.element = null
    this.id = obj.id
    this.content = (obj.deleted === 0) ? urlify(obj.content) : '<div class="chat-message-text-deleted">Kommentar gelöscht</div>'
    this.timestamp = obj.timestamp
    this.firstname = obj.firstname
    this.username = obj.username
    this.userID = obj.user_id
    this.deleted = obj.deleted
    this.profile_img = obj.profile_img
    this.ownLike = obj.ownlike
    this.likeTypes = (obj.like_types == null) ? null : obj.like_types.split(',')
    this.likeCounts = (obj.like_counts == null) ? null : obj.like_counts.split(',')
    this.likes = []

    this.secondsTimer = true
    this.minuteTimer = true

    if (obj.id > newestCommentID)newestCommentID = obj.id

    const thisComment = this
    const arr = this.timestamp.split(/[- :.T]/)
    const timeFormated = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    const menue = (this.userID !== parseInt(localStorage.getItem('user_id'))) ? '<button class="item ui small button fluid"  onclick="report(\'c\',' + this.id + ',\'' + this.userID + '\');"><i class="exclamation icon"></i>Kommentar melden</button>' : '<button class="item ui small button fluid"  onclick="window.wall.deleteComment(' + this.id + ',\'' + this.parent.id + '\');""><i class="delete icon"></i>Kommentar löschen</button>'

    this.calculateTime = function () {
      let remainTime = Math.floor((Date.now() - timeFormated) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sekunden'
      if (remainTime <= 0)timeString = 'Jetzt'
      if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Sekunde'
      if (remainTime > 59) {
        thisComment.secondsTimer = false
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Minuten'
        if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Stunden'
          if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            thisComment.minuteTimer = false
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(thisComment.timestamp)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    }

    this.contentDiv =
      '                                <div class="ui tiny comments" id="c_' + this.id + '">\n' +
      '                                   <div class="comment">\n' +
      '                                        <a onclick="router.push(\'/p/' + this.username + '\')" class="avatar">\n' +
      '                                            <img src="' + projectURL + 'assets/images/user/' + this.profile_img + '">\n' +
      '                                        </a>\n' +
      '                                        <div class="content" style="min-height: 32px;">\n' +
      '                                            <a target="_parent" class="author" onclick="router.push(\'/p/' + this.username + '\')">' + this.username + '</a>\n' +
      '                                            <div class="metadata">\n' +
      '                                                <span class="remainingTimeComment">' + this.calculateTime() + '</span>\n' +
      '                                            </div>\n' +
      '                                            <div class="text commentText" style="max-width: 80%;">\n' +
      this.content +
      '                                            </div>\n' +
      '                                            <div class="content">\n' +
      '                                                <div class="comment-actions t-right" style="transform-origin: bottom left;transform: scale(0.6);">\n' +
      '                                                    <span class="right floated commentReactions">\n' +
      '                                                        <div class="ui icon reply bottom left pointing dropdown mini button like-select-dropdown" >\n' +
      '                                                            <span class="like-button-select ownLikeComment" id="commentReactButton_' + this.id + '">\n' +
      '                                                                <i class="heart icon"></i>\n' +
      '                                                            </span>\n' +
      '                                                           <div class="menu" id="commentReactMenu_' + this.id + '">' +
      '                                                                <div class="item">' +
      '                                                                    <button class="like-button like-button-type-5" onclick="window.wall.likeComment(' + this.id + ',\'5\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/rainbow-flag.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-8" onclick="window.wall.likeComment(' + this.id + ',\'8\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/sweet.png" ">' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-9" onclick="window.wall.likeComment(' + this.id + ',\'9\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/flame.png" ">' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-1" onclick="window.wall.likeComment(' + this.id + ',\'1\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/thumbs-up.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-6" onclick="window.wall.likeComment(' + this.id + ',\'6\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/hugging-face.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-2" onclick="window.wall.likeComment(' + this.id + ',\'2\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/smiling-face-with-hearts.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-4" onclick="window.wall.likeComment(' + this.id + ',\'4\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/rolling-on-the-floor-laughing.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-3" onclick="window.wall.likeComment(' + this.id + ',\'3\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/sad-but-relieved-face.png" >' +
      '                                                                    </button>' +
      '                                                                    <button class="like-button like-button-type-7" onclick="window.wall.likeComment(' + this.id + ',\'7\',\'' + this.parent.id + '\')">' +
      '                                                                        <img src="assets/images/likes/angry-face.png" ">' +
      '                                                                    </button>' +
      '                                                                </div>' +
      '                                                            </div>' +
      '                                                        </div>' +
      '                                                        <div class="left floated likeComments" style="display: contents;">\n' +
      '                                                        </div>\n' +
      '                                                    </span>\n' +
      '                                                </div>\n' +
      '                                            </div>\n' +
      '                                            <div class="ui right pointing dropdown icon commentActionPoints" tabindex="0">\n' +
      '                                                <i class="ellipsis vertical icon"></i>\n' +
      '                                                <div class="menu transition hidden" id="actionMenuComment_' + this.id + '" tabindex="-1">\n' + menue +
      '                                                </div>\n' +
      '                                            </div>\n' +
      '                                        </div>\n' +
      '                                    </div>\n' +
      '                                </div>\n'

    this.refreshSeconds = function () {
      try {
        if (this.secondsTimer && thisComment.element != null && thisComment.element !== undefined) {
          thisComment.element.find('.remainingTimeComment').text(thisComment.calculateTime())
        }
      } catch (e) {}
    }

    this.refreshMinutes = function () {
      try {
        if (this.minuteTimer && thisComment.element != null && thisComment.element !== undefined) {
          thisComment.element.find('.remainingTimeComment').text(thisComment.calculateTime())
        }
      } catch (e) {}
    }

    this.setLikes = function () {
      try {
        const countArray = []
        for (const key in this.likeTypes) {
          this.likes[this.likeTypes[key]] = this.likeCounts[key]
          countArray.push({ id: this.likeTypes[key], count: this.likeCounts[key] })
        }

        countArray.sort((a, b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
        const tempSort = countArray.splice(0, 3)
        const likeComments = this.element.find('.likeComments')

        likeComments.empty()
        for (const key in tempSort) {
          likeComments.append('<span class="ui basic image label"><img src="assets/images/likes/' + imageArray[tempSort[key].id - 1] + '">' + tempSort[key].count + '</span>')
        }
      } catch (e) { console.log('comment-setlikes' + e) }
    }

    // set own like
    this.setOwnLike = function () {
      if (this.ownLike > 0) this.element.find('.ownLikeComment').html('<img src="assets/images/likes/' + imageArray[this.ownLike - 1] + '">')
    }

    // bind hover modal
    this.hoverModal = function () {
      this.element.find('*').filter(function () {
        if ($(this).data('hover') !== undefined) {
          const name = $(this).data('hover')
          $(this).on('mouseenter', function (e) {
            hoverState = true
            hoverModal.css({ top: e.pageY, left: e.pageX })
            $.ajax({
              url: projectURL + 'wall/getMention',
              type: 'POST',
              data: { SessionID: sessionStorage.getItem('SessionID'), username: name },
              success: function (data, textStatus, xhr) {
                data = JSON.parse(data)
                if (xhr.status === 250) {
                  if (hoverState) {
                    hoverModalImg.attr('src', projectURL + 'assets/images/user/' + data.profile_img)
                    hoverModalFirstName.text(data.firstname)
                    hoverModalUserName.text(data.username)
                    hoverModal.show()
                  }
                  hoverName = name

                  hoverModal.off()
                  hoverModal.on('click', function () {
                    $(this).hide()
                  })
                }
              }
            })
          })
          $(this).on('mouseleave', function (e) {
            hoverModal.hide()
            hoverState = false
            hoverModalImg.removeAttr('src')
          })
          $(this).on('click touchend ', function (e) {
            e.stopPropagation()
            e.preventDefault()
            if (e.type === 'touchend') {
              if (hoverModal.is(':visible') && $(this).data('hover').substring(1) === hoverName) {
                router.push('/p/' + name)
              } else {
                hoverModal.css({ top: e.pageY, left: e.pageX })
                $.ajax({
                  url: projectURL + 'wall/getMention',
                  type: 'POST',
                  data: { SessionID: sessionStorage.getItem('SessionID'), username: name },
                  success: function (data, textStatus, xhr) {
                    data = JSON.parse(data)
                    if (xhr.status === 250) {
                      hoverModalImg.attr('src', projectURL + 'assets/images/user/' + data.profile_img)
                      hoverModalFirstName.text(data.firstname)
                      hoverModalUserName.text(data.username)
                      hoverModal.show()
                      hoverName = name

                      hoverModal.off()
                      hoverModal.on('click', function () {
                        router.push('/p/' + name)
                      })
                    }
                  }
                })
              }
            } else if (e.type === 'click') {
              router.push('/p/' + name)
            }
          })
        }
      })
    }

    this.render = function () {
      try {
        if (this.element == null) {
          this.parent.element.find('.comments-content').append(this.contentDiv)
          this.element = this.parent.element.find('#c_' + this.id)
          if (this.userID === parseInt(localStorage.getItem('user_id'))) this.element.find('.like-select-dropdown').hide()
          this.setLikes()
          this.setOwnLike()
          makeFabolous(this.element)
          if (this.deleted === 1) {
            this.element.find('.comment-actions').remove()
            this.element.find('.commentActionPoints').remove()
          }
        } else {
          this.parent.element.find('.comments-content').append(this.element)
        }
        this.hoverModal()
        this.element.find('.dropdown').dropdown()
      } catch (e) {}
    }
  }

  /* ---------------------------Pereodic------------------------------ */

  function refreshSeconds () {
    for (const key in posts) {
      posts[key].refreshSeconds()
      for (const key1 in posts[key].comments) {
        posts[key].comments[key1].refreshSeconds()
      }
    }
  }

  function refreshMinutes () {
    for (const key in posts) {
      posts[key].refreshMinutes()
      for (const key1 in posts[key].comments) {
        posts[key].comments[key1].refreshMinutes()
      }
    }
  }

  this.startWall = function () {
    if (!window.fanrocket.socketOnline) return
    if (started) {
      return
    }
    window.wall.activateSocket()
    if (wallType === 'wall') {
      window.socket.emit('loadPosts', { room: nodeidc, type: wallType, lastPostID: -1, channel: localStorage.getItem('subscribed_channels'), distance: distance })
      window.socket.emit('trendingHashtags', { room: nodeidc })
    } else if (wallType === 'hashtag') {
      window.socket.emit('trendingHashtags', { room: nodeidc })
    } else if (wallType === 'profile') {
      channel = profile
      window.socket.emit('loadPosts', { room: nodeidc, type: wallType, lastPostID: -1, channel: profile, distance: distance })
    } else if (wallType === 'single') {
      const post = window.fanrocket.$route.params.post
      if (post !== undefined && post !== '' && post != null) {
        window.socket.emit('loadPosts', { room: nodeidc, type: wallType, lastPostID: post, channel: null, distance: distance })
      } else {
        window.fanrocket.$router.push('/wall')
      }
    }
    started = true
  }

  setInterval(refreshSeconds, 1000)
  setInterval(refreshMinutes, 60000)

  /* ---------------------------LISTENER------------------------------ */

  // change image preview
  postImage.on('change', function () {
    const files = $('#postimage').prop('files')
    if (files.length === 0) {
      $('#preview-col').hide()
    } else {
      $('#output_image').attr('src', URL.createObjectURL(files[0]))
      $('#preview-col').show()
    }
  })

  // submit post
  postForm.submit(function (e) {
    e.preventDefault()
    if (window.fanrocket.socketOnline) {
      let sendallowed = 0

      message.val(message.val().replace(/[\n\r|\r\n|\r|\n| ]{3,}/g, '\r\n\r\n'))
      if (message.val().replace(/[\n\r|\r\n|\r|\n| ]{3,}/g, '').length !== 0 || postImage[0].files.length === 1) {
        sendButton.prop('disabled', true)
        sendallowed = 1
      }
      const formdata = new FormData(this)
      console.log(formdata)
      formdata.append('SessionID', sessionStorage.getItem('SessionID'))
      formdata.append('postkey', sessionStorage.getItem('postkey'))
      if (sendallowed === 1) {
        $.ajax({
          url: projectURL + 'wall/newPost',
          type: 'POST',
          data: formdata,
          success: function (data, textStatus, xhr) {
            // console.log(data);
            // console.log(xhr.status);
            if (xhr.status === 250) {
              $('#output_image').attr('src', '')
              $('#preview-col').hide()
              message.val('')
              postImage.val('')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 255) {
              errorMessageMTi.innerHTML = 'Kaffeepause <i class="hand paper icon red"></i>'
              errorMessageMT.innerHTML = 'Bitte poste nicht so schnell. <br>Hole dir erstmal einen Kaffee☕️.'
              $('#errorModal').modal('show')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 256) {
              errorMessageMTi.innerHTML = 'Kaffeepause <i class="hand paper icon red"></i>'
              errorMessageMT.innerHTML = 'Bitte poste nicht so viel innerhalb von 30 Minuten. <br>Hole dir erstmal einen Kaffee☕️.'
              $('#errorModal').modal('show')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 257) {
              errorMessageMTi.innerHTML = 'Kaffeepause <i class="hand paper icon red"></i>'
              errorMessageMT.innerHTML = 'Bitte poste nicht so viel innerhalb einer Stunde. <br>Hole dir erstmal einen Kaffee☕️.'
              $('#errorModal').modal('show')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 258) {
              errorMessageMTi.innerHTML = 'Nicht verifiziert <i class="hand paper icon red"></i>'
              errorMessageMT.innerHTML = 'Um direkt in Länderchannels zu posten musst du dich vorher verifizieren.'
              $('#errorModal').modal('show')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 259) {
              errorMessageMTi.innerHTML = 'Fehler <i class="hand paper icon red"></i>'
              errorMessageMT.innerHTML = 'Leider ging hier etwas schief.'
              $('#errorModal').modal('show')
              sendButton.prop('disabled', false)
            } else if (xhr.status === 299) {
              cookieLogin().then(() => { postForm.submit() }).catch(openLogin)
            }
          },
          error: function (data, textStatus, xhr) {
            sendallowed = 0
            sendButton.prop('disabled', false)
          },
          cache: false,
          contentType: false,
          processData: false
        })
      }

      const content = $('#postText').val().toLowerCase()
      const searchArray = ['#müde', '#gutenacht', '#nacht', '#schlafen', '#gn8', '#n8']
      const result = content.match(/#([a-zA-ZäöüÄÖÜß0-9]+)/g)
      let sleep = false
      for (const key in result) {
        if (searchArray.includes(result[key]))sleep = true
      }
      if (sleep) {
        sleepTight()
      }
    }
  })

  // set channel
  $('#wallChannels').on('change', function () {
    channel = ($('#channelSelect').val() == null) ? localStorage.getItem('subscribed_channels') : $('#channelSelect').val()
    if (channel !== 'hashtag') {
      wallType = (channel === '') ? 'wall' : 'channel'
      channel = (channel === '') ? null : channel
      oldestPostID = -1
      oldestPostTime = 9999999999999
      changeChannel()
    }
  })

  // set distance
  $('#maxDiB').on('change', function () {
    distance = $('#maxDiSel').val()
    localStorage.setItem('maxDistance', distance)
    oldestPostID = -1
    oldestPostTime = 9999999999999
    changeChannel()
  })

  // actions
  $('.smileyMenue').on('click', emojiMenue)

  // emojis
  $('.emojione').on('click', function () {
    currentTextField.val(currentTextField.val() + String.fromCodePoint('0x' + $(this).parent().attr('id')))
    $('#EmoticonsMenu').hide()
  })

  $('.scrollToTop').on('click', function () {
    $('html, body').stop().animate({ scrollTop: 0 }, 500, 'swing')
  })

  function scrollFunction () {
    const scrollTemp = $(document).scrollTop()
    if (!loadingMore && (scrollTemp + 200) >= ($(document).innerHeight() - $('body').height()) && wallType !== 'single') {
      loadingMore = true
      window.socket.emit('loadPosts', { room: nodeidc, type: wallType, lastPostID: oldestPostID, channel: channel, distance: distance })
    }

    if (scrollTemp > $('body').height()) {
      scrollToTop.attr('style', 'display: block !important')
    } else {
      scrollToTop.hide()
    }
  }

  // detect scroll
  $(window).off('scroll', scrollFunction)
  $(document).on('scroll', scrollFunction)

  setTimeout(function () {
    window.wall.startWall()
  }, 0)
}
