<template>
  <img src="/assets/images/fanrocket-load.gif">
</template>

<script>
export default {
  name: "WallLoader"
}
</script>

<style scoped>
  img{
    position: absolute;
    max-width: 200px;
    width: 30%;
    transform: translateX(50%);
    right: 50%;
  }
</style>