<template>
  <div
    v-if="size == 'small'"
    onclick="$('#friendships').dropdown('show')"
  >
    <div
      v-if="friends.counter > 0"
      id="friendships"
      class="ui dropdown item"
      tabindex="0"
    >
      <i class="users icon" />
      <div
        v-if="friends.friends > 0"
        class="floating ui blue tiny circular label"
      >
        {{ friendCounter }}
      </div>
      <div
        id="friends-menu"
        class="menu notifications-menu"
        tabindex="-1"
      >
        <div
          v-for="(friend, index) in friends.friends"
          :key="index"
          class="item cur-point"
        >
          <friend-single
            :friendid="friend.id"
            :username="friend.username"
            :timestamp="friend.timestamp"
            :profile_img="friend.profile_img"
          />
        </div>
        <div class="item t-center">
          <router-link
            class="ui fluid button btn-inverted-color"
            to="/n/friends"
          >
            Alle ansehen
          </router-link>
        </div>
      </div>
    </div>
    <router-link
      v-else
      class="item"
      to="/notification/friends"
    >
      <i class="users icon" />
    </router-link>
  </div>
  <div v-else>
    <div
      v-if="friends.counter == 0"
      class="content pad-16"
    >
      <p class="t-center">
        Du hast keine offenen Freundschaftsanfragen
      </p>
      <div class="t-center pad-4">
        <button
          class="ui button btn-color-1"
          onclick="$('#friendrequestModal.ui.modal').modal('hide');"
        >
          Okay
        </button>
      </div>
      <div class="menu" />
    </div>
    <div
      v-else
      class="content pad-16"
    >
      <div
        v-for="(friend, index) in friends.friends"
        :key="index"
        class="item cur-point"
      >
        <friend-single
          :friendid="friend.id"
          :username="friend.username"
          :timestamp="friend.timestamp"
          :profile_img="friend.profile_img"
        />
      </div>
    </div>
  </div>
</template>

<script>
import friendSingle from '@/components/basic/notification/friendSingle'
import router from '@/router'
import {userStore} from "@/store/userStore";
import {friendsStore} from "@/store/friendsStore";

export default {
  name: 'FriendsNav',
  components: {
    friendSingle
  },
  props: ['size'],
  setup() {
    const user = userStore()
    const friends = friendsStore()

    return {user, friends}
  },
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    calculateTime (posted, now) {
      let remainTime = Math.floor((now - posted) / 1000)
      let timeString = 'Vor ' + remainTime + ' Sekunden'
      if (remainTime <= 59)timeString = 'Jetzt'
      if (remainTime > 59) {
        remainTime = Math.floor(remainTime / 60)
        timeString = 'Vor ' + remainTime + ' Minuten'
        if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Minute'
        if (remainTime > 59) {
          remainTime = Math.floor(remainTime / 60)
          timeString = 'Vor ' + remainTime + ' Stunden'
          if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Stunde'
          if (remainTime > 23) {
            remainTime = Math.floor(remainTime / 24)
            timeString = 'Vor ' + remainTime + ' Tagen'
            if (remainTime === 1)timeString = 'Vor ' + remainTime + ' Tag'
            if (remainTime > 6) {
              const date = new Date(posted)
              timeString = 'Am ' + date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear()
            }
          }
        }
      }
      return timeString
    },
    openProfile (uid) {
      router.push('/p/' + uid)
    }
  }
}
</script>

<style scoped>

</style>
