<template>
  <div id="Subscriptions" class="spaceTop">
    <div class="content-1400">
      <div class="grid-12 pad-16">
        Test
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'subscriptions',
  title: 'FanRocket | Subscriptions',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style>

</style>
