<template>
  <div
    class="ui basic label"
    :class="{same: user.tags.includes(tag)}"
  >
    {{ tag }}
    <div
      v-if="edit"
      @click="deleteTag"
    >
      <i class="icon delete" />
    </div>
  </div>
</template>

<script>
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Tags",
  props: ["tag", "edit", "id"],
  setup() {
    const user = userStore()
    const auth = authStore()
    const config = configStore()

    return {auth, config, user}
  },
  methods: {
    deleteTag(){
      const _this = this

      const fD = new FormData()
      fD.append('id', this.id)
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/deleteTag', fD)
      .then((response) => {
        if(response.status === 250){
          _this.$emit('deleteTag', _this.id)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>