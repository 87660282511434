<template>
  <div class="preview">
    <video :src="src" playsinline muted controls="false"></video>
    <div @click="deleteFile">löschen</div>
  </div>
</template>

<script>
export default {
  name: 'videos',
  props: ['file', 'index', 'parent'],
  data () {
    return {
    }
  },
  computed: {
    src () {
      return URL.createObjectURL(this.file)
    }
  },
  methods: {
    deleteFile () {
      this.$emit('deleteFile', this.index)
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
video {
  max-width: 100%;
  height: auto;
}
.preview{
  float: left;
  width: 33%;
}
</style>
