<template>
  <div id="page-termsofuse" class="spaceTop">
    <div class="content-1200 pad-24">
        <h1 class="ui header">
          Terms of use
          <div class="sub header">Coming soon</div>
        </h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'termsofuse',
  title: 'FanRocket | Terms of use',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>

</style>
