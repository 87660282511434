<template>
  <div
    id="SubscribeModal"
    class="vueModal"
  >
    <div class="content t-center pad-32 vueModalInner">
      <i
        class="close icon"
        @click="$emit('close')"
      />
      <h4 class="ui header">
        Subscribe to see posts from
      </h4>
      <h4 class="ui header color-1">
        {{ profile.username }}
      </h4>
      <p class="discountText">
        Today 20% Discount on the 6 month subscribtion
      </p>
      <div>
        <button
          class="ui big fluid button"
          @click="subscribe"
        >
          1 Month for <span class="color-1">6,99$</span>
        </button>
        <button
          class="ui big fluid button"
          @click="subscribe"
        >
          3 Month for <span class="color-1">18,99$</span>
          <span class="instead">instead 20,97$</span>
        </button>
        <button
          class="ui big fluid button btn-color-1"
          @click="subscribe"
        >
          6 Month for 40,97$
          <span class="instead">instead 60,54$</span>
        </button>
      </div>
      <div>
        <h5 class="ui header">
          Payment options
        </h5>
        <img
          src="/assets/images/payment-options.png"
          class="responsive-img"
        >
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "SubscriptionModal",
  props: ['profile'],
  setup(){
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  methods: {
    subscribe(){
      const _this = this
      const fD = new FormData()
      fD.append('creator_id', this.profile.id)
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + '/subscription/add', fD)
          .then((response) => {
            if(response.status === 250){
              _this.profile.subscription = true
            }
          })
    }
  }
}
</script>

<style scoped>

</style>