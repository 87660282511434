<template>
  <div class="spaceTop">
    <div class="pad-16">
      <h1 class="ui header" style="margin-top: 80px;text-align: center;font-size: 3em;">
        Oops! we couldn't find the page
        <div class="sub header" style="font-size: 0.6em;">Please check the link</div>
      </h1>
    </div>
    <div class="t-center">
      <div class="pad-16"></div>
      <router-link class="ui big button btn-color-1 circular" to="/home">Back to home</router-link>
      <div class="pad-40"></div>
    </div>
    <div class="pad-40"></div>
    <div class="pad-40"></div>
  </div>
</template>

<script>
export default {
  name: '404',
  title: 'FanRocket | 404 Error',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>

</style>
