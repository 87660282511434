<template>
  <div id="page-login">
    <div class="content-500 pad-16">
      <div class="ui segment no-border no-shadow br-24">
        <div
          id="LoginLogo"
          class="pad-32 t-center"
        >
          <a href="/">
            <img
              src="assets/images/fanrocket-logo.png"
              class="responsive-img"
            >
          </a>
        </div>
        <div
          v-if="wrongPassword"
          id="loginFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops!
          </div>
          <p>Something went wrong. Please check your email and Password.</p>
        </div>
        <div class="cta-content">
          <form
            id="loginForm"
            class="ui form"
            @submit.prevent="login"
          >
            <div class="field">
              <label>Email or Username</label>
              <input
                v-model="email"
                type="text"
                name="email"
                placeholder="Email or Username"
                required
                autofocus
                autocomplete="username"
              >
            </div>
            <div class="field">
              <label style="float: left;">Password</label>
              <input
                v-model="password"
                type="password"
                name="loginpassword"
                placeholder="Password"
                required
                autocomplete="current-password"
              >
            </div>
            <div class="grid-12">
              <div class="col-sp-6 row-sp-12">
                <div class="field">
                  <input
                    v-model="stayIn"
                    type="checkbox"
                    name="stayIn"
                  >
                  <label>remember me</label>
                </div>
              </div>
              <div class="col-sp-6 row-sp-12 t-right">
                <a
                  class="password-forget color-1"
                  @click="tab = 'resetPassword'"
                >Forgot password?</a>
              </div>
            </div>
            <button
              type="submit"
              class="ui button large fluid btn-color-1"
            >
              Log in
            </button>
          </form>
          <div class="ui divider horizontal">
            or
          </div>
          <button
            class="ui button large fluid basic"
            @click="tab = 'register'"
          >
            Sign up
          </button>
          <div class="pad-16" />
        </div>
      </div>
    </div>
    <div id="page-login-footer">
      <div class="cta-content">
        <p>
          Copyright © 2022 <a class="color-1">FanRocket®</a> by 20North Netherlands | All rights reserved.
        </p>
        <ul>
          <li>
            <router-link to="/legalnotice">
              Legal Notice
            </router-link>
          </li>
          <li>
            <router-link to="/privacypolicy">
              Privacy Policy
            </router-link>
          </li>
          <li>
            <router-link to="/termsofuse">
              Terms of use
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Modals -->
  <transition-group name="modal">
    <div
      v-if="tab === 'resetPassword'"
      id="PasswortForgetModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="pwReset == 'successs'"
          id="passwordForgetSuccess"
          class="ui success message"
        >
          <i class="close icon" />
          <div class="header">
            Password reset
          </div>
          <p>Please check your email inbox to continue.</p>
        </div>
        <div
          v-if="pwReset == 'fail'"
          id="passwordForgetFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>We are not aware of this email.</p>
        </div>
        <form
          id="passwordResetForm"
          class="ui form"
        >
          <input
            type="hidden"
            name="type"
            value="password"
            required
          >
          <h2 class="ui header t-center">
            <div class="content">
              Reset Password
              <div class="sub header">
                Please enter your email address
              </div>
            </div>
          </h2>
          <div class="field">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
              autofocus
            >
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
          >
            Reset Password
          </button>
          <div class="pad-4" />
          <a
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab = 'login'"
          >
            Cancel
          </a>
        </form>
      </div>
    </div>
    <div
      v-if="tab === 'restorePasswort'"
      id="PasswortResetModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="pwRestore == 'password'"
          id="restorePasswordFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>An error has occurred.</p>
        </div>
        <div
          v-if="pwRestore == 'key'"
          id="restoreKeyFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>The Link is invalid.</p>
        </div>
        <form
          id="restorePasswordForm"
          class="ui form"
          method="post"
        >
          <h2 class="ui centered header">
            <div class="content">
              Reset Passwort
              <div class="sub header">
                Please create a new Password.
              </div>
            </div>
          </h2>
          <input
            type="hidden"
            name="type"
            value="login"
            required
          >
          <div class="field">
            <label>Password</label>
            <input
              id="inputPassword"
              type="password"
              name="password"
              placeholder="Password"
              required
              minlength="8"
              autocomplete="new-password"
            >
          </div>
          <div class="field">
            <label>Repeat Password</label>
            <input
              id="inputPassword2"
              type="password"
              name="passwordwdh"
              placeholder="Repeat Password"
              required
              minlength="8"
              autocomplete="new-password"
            >
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
          >
            Reset Password
          </button>
          <div class="pad-4" />
          <a
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab = 'login'"
          >
            Cancel
          </a>
        </form>
      </div>
    </div>
    <div
      v-if="tab === 'register'"
      id="RegisterModal"
      class="vueModal"
    >
      <div class="content vueModalInner">
        <div
          v-if="regError == 'email'"
          id="regEmail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>This email or username are assigned or invalid.</p>
        </div>
        <div
          v-if="regError == 'bot'"
          id="regBot"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>Please try again later.</p>
        </div>
        <div
          v-if="regError == 'fail'"
          id="regFail"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>Check your entries and try again.</p>
        </div>
        <div
          v-if="regError == 'age'"
          id="regAge"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>You have to be at least 18 years old to use FanRocket.</p>
        </div>
        <div
          v-if="password1.length < 6 && password1.length > 0"
          id="toShort"
          class="ui negative message"
          style="display: none;"
        >
          <div class="header">
            Dein Passwort ist leider zu kurz.
            &#x1F346;
          </div>
        </div>
        <div
          v-if="password1.length > 5 && password1 != password2"
          id="noMatch"
          class="ui negative message"
        >
          <i class="close icon" />
          <div class="header">
            Oops! Something went wrong.
          </div>
          <p>Your passwords are not the same or too short.</p>
        </div>
        <form
          id="regform"
          class="ui form"
          action="register/doRegister"
          method="post"
        >
          <h2 class="ui centered header">
            <div class="content">
              Sign Up
              <div class="sub header">
                Join the FanRocket Community
              </div>
            </div>
          </h2>
          <div class="field">
            <label>Email</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              required
            >
          </div>
          <div class="field">
            <label>Username</label>
            <input
              type="text"
              name="username"
              placeholder="Username"
              pattern="[\w]+"
              required
            >
          </div>
          <div class="field">
            <label>Password</label>
            <input
              id="pw1"
              type="password"
              minlength="8"
              placeholder="Password"
              name="registerpassword"
              required
            >
          </div>
          <div class="field">
            <label>Repeat Password</label>
            <input
              id="pw2"
              type="password"
              minlength="8"
              placeholder="Repeat Password"
              name="registerpasswordwdh"
              required
            >
          </div>
          <div class="field">
            <label>Birthday</label>
            <div class="ui left icon input">
              <i class="birthday icon" />
              <input
                type="date"
                placeholder="Geburtstag"
                name="dob"
                required
              >
            </div>
          </div>
          <div class="field">
            <div class="ui checkbox">
              <input
                type="checkbox"
                tabindex="0"
                class="hidden"
                required
              >
              <label>
                I have read and agree to FanRocket's <a
                  href="termsofuse"
                  target="_blank"
                >Terms of use</a>, including the <a
                  href="privacypolicy"
                  target="_blank"
                >Privacy Policy</a>.
              </label>
            </div>
          </div>
          <div class="pad-8" />
          <button
            type="submit"
            class="ui button circular large fluid btn-color-1"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
          >
            Sign Up
          </button>
          <div class="pad-4" />
          <button
            class="ui button circular large fluid btn-inverted-color"
            style="border: 1px solid rgba(34, 36, 38, 0.15);"
            @click="tab = 'login'"
          >
            Cancel
          </button>
        </form>
      </div>
    </div>
  </transition-group>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: 'Login',
  title: 'FanRocket | Login',
  props: ['subpage'],
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return { auth, user, config }
  },
  data () {
    return {
      open: false,
      loader: false,
      tab: this.subpage,
      email: '',
      password: '',
      stayIn: false,
      password1: '',
      password2: '',
      wrongPassword: false,
      pwReset: '',
      pwRestore: '',
      regError: '',
      username: '',
      firstname: '',
      birth: '',
      agb: false,
    }
  },
  mounted () {
    if (this.$route.query.page === 'passwordforget') {
      this.tab = 'resetPassword'
    } else if (this.$route.query.page === 'resetpassword') {
      this.tab = 'restorePassword'
    } else if (this.$route.query.page === 'register') {
      this.tab = 'register'
    }
  },
  methods: {
    async login(){
      const _this = this
      this.loader = true

      this.auth.login(this.email, this.password, this.stayIn)
          .then((resolve) => {
            if (_this.$route.query.redirect === undefined || _this.$route.query.redirect == null || _this.$route.query.redirect === '') {
              _this.$router.push('wall')
            } else {
              _this.$router.push(_this.$route.query.redirect)
            }
          })
          .catch((e) => {
            this.wrongPassword = true
            this.loader = false
          })
    },
    resetPassword(){
      const _this = this
      this.loader = true

      this.auth.resetPassword(this.email)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = 'success'
            } else if (response === 251) {
              _this.pwReset = 'fail'
            }
            _this.loader = false
          })
    },
    restorePassword(){
      const _this = this
      this.loader = true

      this.auth.restorePassword(this.$route.query.resetkey)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = ''
              _this.pwRestore = 'success'
              _this.wrongPassword = false
            } else if (response === 251) {
              _this.pwRestore = 'password'
            } else if (response === 252) {
              _this.pwRestore = 'key'
            }
          })
    },
    register(){
      const _this = this
      this.loader = true

      this.auth.register(email, username, firstname, birth)
          .then((response) => {
            if (response === 250) {
              _this.pwReset = ''
              _this.pwRestore = 'success'
              _this.wrongPassword = false
            } else if (response === 251) {
              _this.pwRestore = 'password'
            } else if (response === 252) {
              _this.pwRestore = 'key'
            }
            loader.hide()
            content.show()
          })

    }
  }
}

</script>

<style >
#page-login{
  padding-top: 80px;
}
#LoginLogo img{
  max-width: 250px;
  width: 100%;
}
#page-login-footer .cta-content{
  margin: 0 auto;
  max-width: 350px;
  width: 100%;
  text-align: center;
}
.cta-content .ui.button{
  max-width: 350px;
  width: 100%;
  margin: 0 auto;
}
#page-login-footer .cta-content ul{
  text-align: center;
  margin: 0;
  padding: 0;
  margin-top: 16px;
}
#page-login-footer .cta-content ul li{
  text-align: center;
  display: inline-block;
  margin: 0 4px;
  list-style: none;
}
#page-login-footer .cta-content ul li a{
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  font-size: 0.8em;
}
#page-login-footer .cta-content p{
  color: rgba(0,0,0,0.6);
  text-decoration: none;
  font-size: 0.9em;
}
#loginForm{
  max-width: 350px;
  margin: 0 auto;
}
#LoginModal .password-forget{cursor: pointer;}
#PasswortForgetModal .rainbowloader,
#PasswortResetModal .rainbowloader,
#LoginModal .rainbowloader{
  display: none;
  min-height: 300px;
}
</style>
