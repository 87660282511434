<template>
  <main
    id="Settings"
    class="spaceTop minHeight"
  >
    <div class="col-sp-4 col-tb-3 col-sd-2 row-sp-12">
      <img
        id="ProfileImage"
        :src="config.projectURL + 'assets/images/user/' + (image ?? 'avatar_default.png')"
      >
      <div v-if="image">
        <i
          class="icon delete"
          @click="deleteImage"
        />
      </div>
      <input
        ref="imageFile"
        type="file"
        accept="image/jpeg,image/png,image/gif"
        @change="uploadFile( $event , 'image')"
      >
    </div>

    <div class="col-sp-4 col-tb-3 col-sd-2 row-sp-12">
      <img
        id="Banner"
        :src="config.projectURL + 'assets/images/user/' + (banner ?? 'default_banner.jpg')"
      >
      <div v-if="banner">
        <i
          class="icon delete"
          @click="deleteBanner"
        />
      </div>
      <input
        ref="bannerFile"
        type="file"
        accept="image/jpeg,image/png,image/gif"
        @change="uploadFile( $event , 'banner')"
      >
    </div>
    <div id="#ProfileTags">
      <tags
        v-for="(tag, index) in tags"
        :id="index"
        :key="index"
        :tag="tag.name"
        :edit="true"
        @deleteTag="deleteTag"
      />
    </div>
    <input
      v-model="newTag"
      type="text"
      @keyup.enter="addTag"
    >
    <textarea v-model="description" />
    <div
      v-for="(item, index) in social"
      :key="index"
    >
      <input
        v-model="item.value"
        type="text"
        :placeholder="item.name"
      >
    </div>
    <button
      type="button"
      @click="save"
    >
      Save
    </button>
  </main>
</template>

<script>
import { decode } from '@/modules/main'
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import axios from "axios";
import Tags from "@/components/profile/tags";
import {reactive, ref} from "vue";

export default {
  name: 'EditProfile',
  components: {Tags},
  title: 'FanRocket | Profile Settings',
  async setup(){
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    let image, banner, description, tags

    let social = reactive({facebook: {name: 'facebook', value: ''}, instagram: {name: 'instagram', value: ''}})

    const fD = new FormData()
    fD.append('username', user.username)
    fD.append('SessionID', auth.sessionID)


    await axios.post(config.projectURL + 'profile/get', fD)
        .then(function (response) {
          if (response.status === 250) {
            const data = response.data
            image = ref(data.image)
            banner = ref(data.banner)
            description = ref(decode(data.description))
            tags = reactive(data.tags) ?? reactive({})
            try {
              const tempSocial = JSON.parse(data.social)
              tempSocial.forEach((item) => {
                social[item.name] = item
              })
            }catch (e) {}
          }
        })

    return {auth, config, user, image, banner, description, tags, social}
  },
  data () {
    return {
      newTag: '',
    }
  },
  methods: {
    save(){
      const _this = this
      const fD = new FormData()
      fD.append('description', this.description)
      fD.append('social', JSON.stringify(this.social))
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/edit', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.tags[response.data.id] = response.data
              _this.newTag = ''
            }
          })
    },
    uploadFile(event, type){
      const _this = this
      const fD = new FormData()
      fD.append('file', event.target.files[0])
      fD.append('type', type)
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/uploadFile', fD,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then((response) => {
            console.log(response.data, response)
            if(response.status === 250){
              if(type === 'image'){
                _this.$refs.imageFile.value = null
                _this.image = response.data
              }else if( type === 'banner'){
                _this.$refs.bannerFile.value = null
                _this.banner = response.data
              }
            }
          })
    },
    deleteImage(){
      const _this = this
      const fD = new FormData()
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/deleteImage', fD)
          .then((response) => {
            if(response.status === 250){
              _this.image = null
            }
          })
    },
    deleteBanner(){
      const _this = this
      const fD = new FormData()
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/deleteBanner', fD)
          .then((response) => {
            if(response.status === 250){
              _this.banner = null
            }
          })
    },
    addTag(){
      const _this = this
      const fD = new FormData()
      fD.append('tag', this.newTag)
      fD.append('SessionID', this.auth.sessionID)
      this.axios.post(this.config.projectURL + 'profile/addTag', fD)
          .then((response) => {
            if(response.status === 250){
              _this.tags[response.data.id] = response.data
              _this.newTag = ''
            }
          })
    },
    deleteTag(id){
      delete this.tags[id]
    }
  }
}
</script>

<style scoped>
</style>
