<template>
  <router-link class="content" :to="'/p/' + username" v-if="size =='small'">
    <a class="author">Neue Reaktion</a>
    <div class="text">
      {{username}} hat auf deine Story reagiert.
      <img :src="like">
    </div>
    <div class="metadata t-right" style="display: block">
      <div class="date">
        {{date}}
      </div>
    </div>
  </router-link>
  <router-link class="col-sp-9 col-sd-11 row-sp-12 pad-16" v-else :to="'/p/' + username">
    <h4 class="ui header">Neue Reaktion
      <div class="sub header">
        {{username}} hat auf deine Story reagiert.
        <img :src="projectURL + 'assets/images/likes/' + this.notidata.reaction">
      </div>
    </h4>
    <div class="t-right">{{date}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'story',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime(),
      projectURL: window.projectURL
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  },
  mounted () {
    $('#notis').dropdown()
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
