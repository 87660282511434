<template>
  <div class="spaceTop">
    <div class="content-600">
      <div class="pad-24"></div>
      <div class="ui segment fluid br-24 bg-color-1 pad-24">
        <img src="assets/images/fanrocket-logo-icon.png" class="responsive-img" style="max-width: 48px; float: right; filter: brightness(100)"/>
        <h3 class="ui header inverted">You need Help?</h3>
        <p class="color-white">
          Do you have any questions or problems on our platform?
          Perhaps you will already find a suitable answer in our <router-link to="faq" style="color: #ffffff; text-decoration: underline;">FAQ</router-link>.
          Otherwise, don't be afraid to contact us using the contact form below.
        </p>
      </div>
      <div class="pad-16"></div>
      <div class="pad-16">
        <h1 class="ui header">
          Contactform
          <div class="sub header">Write us a message</div>
        </h1>
      </div>
      <div class="pad-16" v-if="send">
        <div class="ui success message">
          <i class="close icon"></i>
          <div class="header">
            Your message has been sent successfully
          </div>
          <p>We will take care of your request as soon as possible</p>
        </div>
      </div>
      <form method="post" action="kontakt/sendMessage" class="ui form">
        <div class="grid-12 pad-8">
          <div class="col-sp-12 col-tb-6 row-sp-16 pad-4">
            <div class="field">
              <label>Name</label>
              <input type="text" name="name" placeholder="Name" required>
            </div>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-16 pad-4">
            <div class="field">
              <label>Email</label>
              <input type="email" name="email" placeholder="Email" required>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="field">
              <label>Subject</label>
              <input type="text" name="subject" placeholder="Subject" required>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="field">
              <label>Message</label>
              <textarea name="message" placeholder="Message" required></textarea>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4 t-right">
            <button class="ui button btn-color-1 fluid circular" type="submit"><i class="envelope icon"></i> Send Message</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="pad-40"></div>
</template>

<script>
export default {
  name: 'contact',
  title: 'FanRocket | Contact',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>

</style>
