<template>
  <div id="page-home">
    <section id="HomeBanner">
      <div class="content-1400">
        <div class="banner-content grid-12">
          <div class="col-sp-12 row-sp-12 t-center">
            <h1 class="ui centered header animate__animated animate__fadeIn">
              Explore exclusive adult content on <span class="color-1">FanRocket</span>
              <div class="sub header">
                for everyone from creators around the world
              </div>
            </h1>
            <router-link
              class="ui button massive circular basic animate__animated animate__bounceInUp"
              to="/login"
            >
              Join FanRocket
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="content-1600">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-4 col-sd-6 row-sp-12 pad-32 t-center">
            <img
              src="assets/images/website/fanrocket-home-mockup.png"
              class="responsive-img animate__animated animate__backInLeft"
            >
          </div>
          <div class="col-sp-12 col-tb-8 col-sd-6 row-sp-12 pad-32">
            <div class="pad-24" />
            <h2
              class="ui header"
              style="font-size: 2.1em;"
            >
              This is FanRocket
            </h2>
            <p
              class="color-grey"
              style="max-width: 600px; font-size: 1.2em;"
            >
              Fanrocket is the platform for exclusive adult content from creators from all over the world. Find creators you like, support them with a monthly subscription and enjoy their exclusive content. In addition, our platform offers many other features. From the private chat with the creators to the explore game to find new artists according to your taste.
            </p>
            <div class="pad-8" />
            <div class="ui large buttons">
              <router-link
                to="login"
                class="ui button btn-color-1 large circular"
              >
                Join now for Free!
              </router-link>
              <div class="or" />
              <router-link
                to="becomeacreator"
                class="ui button large circular"
              >
                Become a Creator
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="content-1200">
        <div class="pad-40" />
        <div class="grid-12 pad-8">
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-16">
            <h2
              class="ui header"
              style="font-size: 2.3em"
            >
              Content for everyone
              <div
                class="sub header"
                style="font-size: 0.6em;"
              >
                Unique creator according to your taste
              </div>
            </h2>
            <div id="Hashtags">
              <span class="ui basic label large color-1">#Twinks</span>
              <span class="ui basic label large">#Amateure</span>
              <span class="ui basic label large">#Shemales</span>
              <span class="ui basic label large">#Mens</span>
              <span class="ui basic label large color-1">#18yr</span>
              <span class="ui basic label large">#MILFs</span>
              <span class="ui basic label large">#DILFs</span>
              <span class="ui basic label large">#Bottoms</span>
              <span class="ui basic label large">#Tops</span>
              <span class="ui basic label large">#Asian</span>
              <span class="ui basic label large color-1">#Europe</span>
              <span class="ui basic label large">#Australian</span>
              <span class="ui basic label large color-1">#Blondes</span>
              <span class="ui basic label large">#Ebony</span>
              <span class="ui basic label large">#Czech</span>
              <span class="ui basic label large">#Emo</span>
              <span class="ui basic label large">#Fetish</span>
              <span class="ui basic label large">#Latin</span>
              <span class="ui basic label large">#Straight</span>
              <span class="ui basic label large">#Gays</span>
              <span class="ui basic label large">#Lesbians</span>
              <span class="ui basic label large">#Russians</span>
              <span class="ui basic label large">#Bears</span>
              <span class="ui basic label large">#Hunks</span>
            </div>
            <div class="pad-8" />
            <router-link
              to="login"
              class="ui button big circular basic"
            >
              And much more, Join now!
            </router-link>
          </div>
          <div class="col-sp-5 col-sd-2 row-sp-12 pad-4">
            <img
              src="assets/images/website/become-a-creator-2.jpeg"
              class="responsive-img br-24 animate__animated animate__backInRight"
            >
          </div>
          <div class="col-sp-7 col-sd-4 row-sp-6 pad-4">
            <img
              src="assets/images/website/become-a-creator-1.jpg"
              class="responsive-img br-24 animate__animated animate__backInRight"
            >
          </div>
          <div class="col-sp-6 col-sd-3 row-sp-6 pad-4">
            <img
              src="assets/images/website/become-a-creator-3.png"
              class="responsive-img br-24 animate__animated animate__backInRight"
            >
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="content-1400 pad-16">
        <div class="pad-32" />
        <h3
          style="font-size: 1.8em;"
          class="ui header centered"
        >
          Learn more about FanRocket
        </h3>
        <div class="grid-12">
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-8">
            <div class="ui segment br-16 pad-24 animate__animated animate__backInUp">
              <h4
                class="ui header"
                style="font-size: 1.4em;"
              >
                Become a creator
              </h4>
              <p class="color-grey">
                Do you want to become a creator on our platform? You can find all information and advantages on our creator page.
              </p>
              <router-link
                to="becomeacreator"
                class="ui basic button circular"
              >
                Learn more
              </router-link>
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-8">
            <div class="ui segment br-16 pad-24 bg-color-1 animate__animated animate__backInUp">
              <h4
                class="ui inverted header"
                style="font-size: 1.4em;"
              >
                Create own Studio
              </h4>
              <p class="color-white">
                Do you have a studio or management team with several artists, or would you like to found one and use our platform to publish content?
              </p>
              <router-link
                to="createstudio"
                class="ui basic inverted button circular"
              >
                Learn more
              </router-link>
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-8">
            <div class="ui segment br-16 pad-24 animate__animated animate__backInUp">
              <h4
                class="ui header"
                style="font-size: 1.4em;"
              >
                Affiliate Program
              </h4>
              <p class="color-grey">
                Would you like to recommend our platform to other creators and benefit from it?
              </p>
              <router-link
                to="affiliateprogramm"
                class="ui basic button circular"
              >
                Learn more
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
export default {
  name: 'Home',
  title: 'FanRocket - Explore exclusive adult content ',
  components: {
  },
  setup(){
    const config = configStore()
    const user = userStore()
    const auth = authStore()

    return {config, user, auth}
  },
  data () {
    return {
    }
  },
  mounted () {
    const _this = this
  }
}
</script>

<style scoped>
#page-home{
  padding-bottom: 80px;
}
#page-home #HomeBanner{
  width: 100%;
  min-height: 600px;
}
#page-home #HomeBanner .banner-content {
  padding: 180px 32px!important;
}
#page-home #HomeBanner .banner-content h1{
  max-width: 700px;
  font-size: 3.3em;
  line-height: 48px;
  margin: 0 auto;
  margin-bottom: 32px;
  font-weight: bold;
}
#page-home #HomeBanner .banner-content h1 .sub.header{
  font-size: 0.6em;
  margin-top: 16px;
}
#Hashtags{
  margin-left: -4px;
}
#Hashtags .ui.label{
  border-radius: 90px;
  padding: 12px 16px;
  color: rgba(0,0,0,0.6);
}
</style>
