<template>
  <div
    id="BackendTopMenu"
    class="ui large menu"
  >
    <div class="grid-16">
      <div class="col-sp-16 col-tb-8 row-sp-16">
        <div class="left menu">
          <button
            class="menu-btn btn btn-link btn-44 item pad-16"
            @click="config.sidebar = !config.sidebar"
          >
            <span class="icon material-icons">menu</span>
          </button>
          <div id="Desktop-Top-Logo">
            <router-link
              active-class="active"
              to="/wall"
            >
              <img
                v-if="!darkMode"
                class="logo_main"
                style="max-height: 40px; padding-top: 8px;"
                src="assets/images/fanrocket-logo-cropped.png"
              >
              <img
                v-else
                class="logo_main"
                style="max-height: 40px; padding-top: 8px;"
                src="assets/images/fanrocket-logo-cropped.png"
              >
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-sp-16 col-tb-8 row-sp-16">
        <div class="right menu">
          <router-link
            to="/create"
            class="item"
          >
            <i class="material-icons">add_circle_outline</i>
          </router-link>
          <router-link
            to="/messages"
            class="item"
          >
            <i class="material-icons">chat_bubble_outline</i>
            <div
              v-if="chat.counter > 0"
              class="floating ui blue tiny circular label"
            >
              {{ chat.counter }}
            </div>
          </router-link>
          <router-link
            to="/notifications"
            class="item"
          >
            <i class="material-icons">notifications_none</i>
          </router-link>
          <router-link
            :to="'/p/' + user.username"
            class="item"
          >
            <div v-if="socket.online">
              <img
                class="ui avatar image ar-1-1"
                :src="config.projectURL + 'assets/images/user/' + (user.profile_img ?? 'avatar_default.png')"
              >
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";
import {socketStore} from "@/store/socketStore";

export default {
  name: 'DesktopTop',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const chat = chatStore()
    const socket = socketStore()

    return {auth, user, config, chat, socket}
  },
  data () {
    return {
    }
  },
  computed: {
    countTitle () {
      return this.countNoFri + this.chats
    },
    logo () {
      return this.config.darkMode ? 'assets/images/fanrocket-logo.png' : 'assets/images/fanrocket-logo.png'
    }
  },
  methods: {
  }
}
</script>

<style>
#BackendTopMenu{
  border-radius: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1032;
  background-color: rgba(255,255,255,1);
  margin-top: 0px;
  height: 58px;
  border: none;
  box-shadow: none;
}
#BackendTopMenu .item:before{
  display: none;
  width: 0px!important;
}
#BackendTopMenu .grid-16{
  width: 100%;
  height: 58px;
}
#BackendTopMenu .right.menu{
  float: right;
  height: 58px;
}
#BackendTopMenu .right.menu img:not(.avatar){
  max-height: 20px;
  width: auto;
  cursor: pointer;
}
#BackendTopMenu .menu i{
  color: rgba(0,0,0,0.6);
  font-size: 1.4em;
}
#BackendTopMenu .right.menu .ui.blue.label{
  background-color: #320b49!important;
  border-color: #320b49!important;
}
#BackendTopMenu .menu-btn.btn.btn-link.btn-44.item.pad-16{
  height: 56px;
  width: 56px;
  padding-right: 42px!important;
}
#BackendTopMenu #Desktop-Top-Logo img{
  max-height: 52px;
  margin-top: 3px;
  cursor: pointer;
}
#BackendTopMenu .floating.ui.label{
  top: 0.4em;
  left: 80%;
}
/* Darkmode */
.dark #BackendTopMenu{background-color: #1B1C1D;}
.dark #BackendTopMenu .menu i, .dark #BackendTopMenu .menu span{color: rgba(255,255,255,0.4);}
.dark #Mobile-Top-Menu button .icon{color: rgba(255,255,255,0.6);}
</style>
