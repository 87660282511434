import { defineStore } from 'pinia'

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        profile_img: '',
        username: '',
        user_id: -1,
        tags: [],
    })
})