<template>
  <div class="spinContainer">
    <svg :height="radius * 2" :width="radius * 2" :style="{ 'animation-duration': 1000/duration+'ms'}">
      <circle
          stroke="white"
          fill="transparent"
          :stroke-dasharray="circumference + ' ' + circumference"
          :style="{ strokeDashoffset}"
          :stroke-width="stroke"
          :r="normalizedRadius"
          :cx="radius"
          :cy="radius"
      />
    </svg>
  </div>
  <div class="spinContainer">
    {{progress}}%
  </div>
</template>

<script>
export default {
  name: 'progressRing',
  props: {
    radius: Number,
    progress: Number,
    stroke: Number,
    duration: Number
  },
  data () {
    const normalizedRadius = this.radius - this.stroke * 2
    const circumference = normalizedRadius * 2 * Math.PI

    return {
      normalizedRadius,
      circumference
    }
  },
  computed: {
    strokeDashoffset () {
      return this.circumference - this.progress / 100 * this.circumference
    }
  }
}
</script>

<style scoped>
.spinContainer{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  font-family: 'Numbers';
  color: white;
  font-size: 1em;
}
svg{
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
