<template>
  <div class="spaceTop">
  </div>
</template>

<script>
export default {
  name: 'affiliateprogram',
  title: 'FanRocket | Affiliateprogram',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>

</style>
