<template>
  <textarea v-model="this.$parent.message" :placeholder="$t('wall.form.survey')"></textarea>
  <ul>
    <li v-for="(option, index) in this.$parent.options" :key="index">
        <input type="text" v-model="this.$parent.options[index]" :placeholder="$t('wall.form.option', {index: index+1})"/><button type="button" class="ui basic circular button btn-color-1" @click="$parent.options.splice(index, 1)" v-if="index > 1">-</button>
    </li>
  </ul>
  <button type="button" class="ui basic circular button btn-color-1" @click="$parent.options.push('')" v-if="this.$parent.options.length < 7">+</button>
</template>

<script>
export default {
  name: 'survey'
}
</script>

<style scoped>

</style>
