<template>
  <div id="page-about"  class="spaceTop">
  </div>
</template>

<script>
export default {
  name: 'aboutus',
  title: 'FanRocket | About us',
  mounted () {
    window.fanrocket.loading = false
  },
  methods: {
    scrollMeTo (refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop

      window.scrollTo(0, top)
    }
  }
}
</script>

<style scoped>
#page-about{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
#page-about #AboutBanner{
  width: 100%;
  min-height: 300px;
  background-color: #a9cbd5;
}
#page-about #AboutBanner .banner-content {
  padding-top: 120px!important;
}
#page-about #AboutBanner .banner-content h1{
  font-size: 2.3em;
}
#page-about #AboutBanner .banner-content p{
  color: rgba(255,255,255,0.8);
  max-width: 600px;
  font-size: 1.1em;
}
#page-about #Team h2.ui.header{
  font-size: 2.3em;
}
#page-about #Team h2.ui.header .sub.header{
  font-size: 0.6em;
}
#page-about #Team .teambox img{
  margin: 0 auto;
}
#page-about #Team .teambox h4.ui.header{
  font-size: 1.5em;
}
</style>
