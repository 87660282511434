<template>
  <section
    id="PostContentForm"
    class="pad-16"
  >
    <form @submit.prevent="post">
      <textarea
        v-if="!recShow && !surveyShow"
        v-model="message"
        rows="3"
        :placeholder="$t('wall.form.publish')"
      />
      <recorder
        v-else-if="recShow"
        steps="30"
      />
      <survey
        v-else-if="surveyShow"
        steps="30"
      />
      <transition name="file-slide">
        <div
          v-if="!recShow"
          id="PreviewBox"
        >
          <transition-group name="file-slide">
            <images
              v-for="(imageFile,index) in fileImages"
              :key="index"
              :index="index"
              :file="imageFile"
              @deleteFile="deleteImages"
            />
            <audios
              v-for="(audioFile,index) in fileAudios"
              :key="index"
              :index="index"
              :file="audioFile"
              @deleteFile="deleteAudios"
            />
            <videos
              v-for="(videoFile,index) in fileVideos"
              :key="index"
              :index="index"
              :file="videoFile"
              @deleteFile="deleteVideos"
            />
          </transition-group>
        </div>
      </transition>
      <div id="PostContentFormCTAs">
        <div class="grid-12">
          <div class="col-sp-12 col-hp-8 row-sp-12">
            <ul>
              <Transition name="slide-fade">
                <li>
                  <!--<input ref="fileUpload" type="file" accept="image/jpeg, image/png, image/jpg, video/mp4, video/wmv, video/ogv, video/webm, audio/mp3, audio/wma, audio/oga" id="MediaUpload" class="fileinput" @change="helloFiles">-->
                  <input
                    id="MediaUpload"
                    ref="fileUpload"
                    type="file"
                    accept=".jpeg,.png,.jpg,.mp4,.wmv,.ogv,.webm,.mp3,.wma,.oga,.mov"
                    class="fileinput"
                    multiple
                    @change="helloFiles"
                  >
                  <button
                    title="Upload media"
                    type="button"
                    @click="$refs.fileUpload.click"
                  >
                    <img src="assets/images/icons/add-media.png">
                  </button>
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li>
                  <button
                    title="Record audio"
                    type="button"
                    :class="{active: recShow}"
                    @click="record"
                  >
                    <img src="assets/images/icons/microfon.png">
                  </button>
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li>
                  <button
                    title="Create Survey"
                    type="button"
                    :class="{active: survey}"
                    @click="initSurvey"
                  >
                    <img src="assets/images/icons/question.png">
                  </button>
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li>
                  <button
                    title="Add Goal"
                    type="button"
                    :disabled="surveyShow"
                    :class="{disabled: surveyShow}"
                    @click="priceShow = !priceShow"
                  >
                    <img src="assets/images/icons/money.png">
                  </button>
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li v-if="priceShow">
                  <input
                    v-model="price"
                    type="text"
                    pattern="[0-9\.]"
                  >
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li>
                  <button
                    title="Plan post"
                    type="button"
                    @click="timepicker"
                  >
                    <img src="assets/images/icons/calender.png">
                  </button>
                </li>
              </Transition>
              <Transition name="slide-fade">
                <li v-show="future">
                  <datepicker
                    ref="datepicker"
                    v-model="postDate"
                    auto-apply
                    placeholder="Datum und Zeit auswählen"
                    :format="format"
                    locale="de"
                    cancel-text="abbrechen"
                    select-text="auswählen"
                    show-now-button
                    now-button-label="Jetzt"
:min-date="new Date()" minutes-increment="5" :min-time="minTime"
                  />
                </li>
              </Transition>
            </ul>
          </div>
          <div class="col-sp-12 col-hp-4 row-sp-12 t-right">
            <button
              type="submit"
              class="ui basic circular button btn-color-1"
              :class="{disabled : !readyToPost}"
              :disabled="!readyToPost"
            >
              Post
            </button>
          </div>
        </div>
      </div>
      <transition name="fade">
        <upload-loader
          v-if="status != ''"
          :progress="progress"
          :status="status"
        />
      </transition>
    </form>
  </section>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import Recorder from '@/components/media/recorder'
import Images from '@/components/media/previews/images'
import Audios from '@/components/media/previews/audios'
import Videos from '@/components/media/previews/videos'
import { reactive } from 'vue'
import Survey from '@/components/media/survey'
import UploadLoader from '@/components/loader/upload-loader'

export default {
  name: 'PostForm',
  components: {
    UploadLoader,
    Survey,
    Videos,
    Audios,
    Images,
    Recorder,
    Datepicker
  },
  data () {
    return this.init()
  },
  computed: {
    readyToPost () {
      let option = 0
      this.options.forEach(item => {
        if (item.length > 0) option++
      })
      return this.fileImages.length || this.fileAudios.length || this.fileVideos.length || this.message.length || this.audio || (this.survey && option > 1)
    }
  },
  watch: {
    files () {
      const _this = this
      for (let i = 0; i < this.files.length; i++) {
        const slices = this.files[i].name.split('.')
        switch (slices[slices.length - 1].toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'png':
            _this.fileImages.push(this.files[i])
            break

          case 'oga':
          case 'wma':
          case 'mp3':
            _this.fileAudios.push(this.files[i])
            break

          case 'mp4':
          case 'webm':
          case 'ogv':
          case 'wmv':
          case 'mov':
            _this.fileVideos.push(this.files[i])
            break
        }
      }
      if (this.files.length > 0) this.files = []
    }
  },
  mounted () {
  },
  methods: {
    init () {
      return {
        message: '',
        imageShow: false,
        priceShow: false,
        price: '3.99',
        recShow: false,
        future: false,
        postDate: null,
        audio: null,
        audioBlob: reactive([]),
        files: [],
        fileImages: [],
        fileAudios: [],
        fileVideos: [],
        surveyShow: false,
        options: ['', ''],
        progress: 0,
        status: ''
      }
    },
    timepicker () {
      if (this.future) {
        this.future = false
      } else {
        this.future = true
        this.$refs.datepicker.openMenu()
      }
    },
    format (date) {
      const min = date.getMinutes()
      const hour = date.getHours()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()

      return `${hour}:${min} ${day}.${month}.${year}`
    },
    minTime () {
      return { hours: 11, minutes: 30 }
    },
    record () {
      if (this.recShow) {
        this.recShow = false
        this.audio = null
      } else {
        this.recShow = true
      }
    },
    helloFiles (event) {
      this.surveyShow = false
      this.recShow = false
      this.files = event.target.files
    },
    initSurvey () {
      this.surveyShow = !this.surveyShow
      if (this.surveyShow) this.recShow = false
    },
    deleteImages (index) {
      this.fileImages.splice(index, 1)
    },
    deleteAudios (index) {
      this.fileAudios.splice(index, 1)
    },
    deleteVideos (index) {
      this.fileVideos.splice(index, 1)
    },
    post () {
      if (!this.readyToPost) return
      const _this = this
      const fD = new FormData()
      fD.append('message', this.message)
      // check type of post
      if (this.recShow) {
        fD.append('type', 'record')
        fD.append('record', this.audioBlob)
        fD.delete('message')
      } else if (this.surveyShow) {
        fD.append('type', 'survey')
        this.options.forEach(file => {
          fD.append('options[]', file)
        })
      } else if (this.fileImages.length || this.fileAudios.length || this.fileVideos.length) {
        fD.append('type', 'multimedia')
        this.fileImages.forEach(file => {
          fD.append('images[]', file)
        })
        this.fileAudios.forEach(file => {
          fD.append('audios[]', file)
        })
        this.fileVideos.forEach(file => {
          fD.append('videos[]', file)
        })
      } else {
        fD.append('type', 'text')
      }
      fD.append('price', this.priceShow ? this.price : 0)
      fD.append('date', this.postDate)

      this.status = 'uploading'
      this.axios.post('/wall/newPost', fD, {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length')
          // console.log("onUploadProgress", totalLength, progressEvent.loaded, ( progressEvent.loaded / totalLength ));
          _this.progress = Math.floor(progressEvent.loaded * 100 / totalLength)
          console.log(_this.progress)
        }
      })
        .then(function (response) {
          if (response.status === 250) {
            _this.status = 'completed'
            _this.progress = 0
            setTimeout(function () {
              Object.assign(_this.$data, _this.init())
            }, 2000)
          } else {
            _this.status = 'failed'
            _this.progress = 0
            setTimeout(function () { _this.status = '' }, 2000)
          }
        })
        .catch(function () {
          _this.status = 'failed'
          _this.progress = 0
          setTimeout(function () { _this.status = '' }, 2000)
        })
    }
  }
}
</script>

<style scoped>
form{
  position: relative;
}
.slide-fade-move,
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  /*transform: translateX(-150px);*/
  transform: scaleX(0);
  opacity: 0;
}

.slide-fade-leave-active {
  position: absolute;
}

.file-slide-move,
.file-slide-enter-active,
.file-slide-leave-active {
  transition: all 0.5s ease;
}

.file-slide-enter-from,
.file-slide-leave-to {
  /*transform: translateX(-150px);*/
  transform: translateX(-100px);
  opacity: 0;
}
.file-slide-leave-active{
  position: absolute;
}
#PreviewBox{
  display: flex;
}
</style>
