<template>
  <div
    id="hoverModal"
    class="ui segment no-shadow profile-preview"
  >
    <h5 class="ui header active">
      <img
        id="hoverModalImg"
        class="ui avatar image"
      >
      <div class="content">
        <span id="hoverModalFirstName" />
        <div
          id="hoverModalUserName"
          class="sub header active"
        />
      </div>
    </h5>
  </div>
  <div class="ui mini deletePost modal">
    <i class="close icon" />
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div
        class="ui tiny button"
        onclick="$('.deletePost.modal').modal('hide');"
      >
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui mini deleteComment modal">
    <i class="close icon" />
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div
        class="ui tiny button"
        onclick="$('.deleteComment.modal').modal('hide');"
      >
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>


  <div
    id="page-dashboard"
    class="spaceTop minHeight"
  >
    <div id="ptrest" />
    <div
      id="wallSlider"
      class="content-1200 grid-12"
    >
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-16">
        <div
          class="wallSliderElement bg-color-4"
          style="background-image: url('/assets/images/user/banner/banner-thejanandonly.jpeg');"
        >
          <div class="wallSliderContent">
            <h3 class="ui inverted header">
              Recommend Profile
              <div class="sub header">
                @thejanandonly
              </div>
            </h3>
            <router-link
              class="ui small circular basic inverted button"
              to="/p/thejanandonly"
            >
              Open Profile
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-16 display-Dektop">
        <div
          class="wallSliderElement bg-color-4"
          style="background-image: url('/assets/images/user/banner/banner-fabfabfabulous.jpeg');"
        >
          <div class="wallSliderContent">
            <h3 class="ui inverted header">
              Recommend Profile
              <div class="sub header">
                @fabfabfabulous
              </div>
            </h3>
            <router-link
              class="ui small circular basic inverted button"
              to="/p/fabfabfabulous"
            >
              Open Profile
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-16 display-Dektop">
        <div
          class="wallSliderElement bg-color-4"
          style="background-image: url('/assets/images/user/banner/banner-thejanandonly.jpeg');"
        >
          <div class="wallSliderContent">
            <h3 class="ui inverted header">
              Recommend Profile
              <div class="sub header">
                @thejanandonly
              </div>
            </h3>
            <router-link
              class="ui small circular basic inverted button"
              to="/p/thejanandonly"
            >
              Open Profile
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-1200 grid-12">
      <div class="col-sp-12 col-sd-4 row-sp-12 display-Dektop">
        <aside id="Sidemenu">
          <div class="sidebar">
            <div class="row no-gutters">
              <div class="col-auto align-self-center">
                <router-link :to="'/p/' + user.username">
                  <figure
                    class="avatar avatar-50"
                    style="background: transparent;"
                  >
                    <img
                      class="ar-1-1"
                      :src="config.projectURL + 'assets/images/user/' + (user.profile_img ?? 'avatar_default.png' )"
                    >
                  </figure>
                </router-link>
              </div>
              <div class="col pl-3 align-self-center">
                <router-link :to="'/p/' + user.username">
                  <p class="my-0">
                    {{ user.username }}
                  </p>
                  <p class="text-mute my-0 small">
                    {{ user.username }}
                  </p>
                </router-link>
              </div>
            </div>
            <div class="list-group main-menu my-4">
              <router-link
                active-class="active"
                to="/wall"
                class="list-group-item list-group-item-action"
              >
                <i class="material-icons">dashboard</i>Feed
              </router-link>
              <router-link
                active-class="active"
                to="/search"
                class="list-group-item list-group-item-action"
              >
                <i class="material-icons">search</i>Explore
              </router-link>
              <router-link
                active-class="active"
                to="/subscriptions"
                class="list-group-item list-group-item-action"
              >
                <i class="material-icons">favorite</i>Subscriptions
              </router-link>
              <router-link
                active-class="active"
                to="/settings"
                class="list-group-item list-group-item-action"
              >
                <i class="material-icons">settings</i>Settings
              </router-link>
              <router-link
                active-class="active"
                to="/wallet"
                class="list-group-item list-group-item-action"
              >
                <i class="material-icons">account_balance_wallet</i>Wallet
              </router-link>
            </div>
          </div>
        </aside>
      </div>
      <div class="col-sp-12 col-sd-8 row-sp-12">
        <div id="ptrPull">
          <div class="pad-8" />
          <post-form />
        </div>
        <section id="feed">
          <!--<article class="pad-16">
            <div class="ui fluid card no-shadow no-border br-16">
              <div class="content">
                <h4 class="ui header">
                  <img
                    class="ui avatar image"
                    src="assets/images/user/valentin.jpeg"
                  >
                  <div class="content">
                    Valentin
                    <div class="sub header">
                      14h
                    </div>
                  </div>
                  <div class="right floated meta">
                    <a><i class="bookmark outline icon" /></a>
                    <a><i class="ellipsis vertical icon" /></a>
                  </div>
                </h4>
              </div>
              <div class="image">
                <img src="assets/images/wall/post-1.jpeg">
              </div>
              <div class="content">
                <span class="right floated">
                  <i class="heart outline like icon" />
                  17 likes
                </span>
                <i class="comment icon" />
                3 comments
              </div>
              <div class="extra content">
                <div class="ui large transparent left icon input">
                  <i class="heart outline icon" />
                  <input
                    type="text"
                    placeholder="Add Comment..."
                  >
                </div>
              </div>
            </div>
          </article>-->
          <wallPost />
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PostForm from '@/components/wall/postForm'
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";
import {socketStore} from "@/store/socketStore";
import {userStore} from "@/store/userStore";

import wallPost from "@/components/wall/wall"

export default {
  name: 'Wall',
  title: 'FanRocket | Feed',
  components: {
    PostForm,
    wallPost
  },
  setup (){
    const config = configStore()
    const user = userStore()
    const auth = authStore()
    const socket = socketStore()

    return {config, user, auth, socket}
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    openSearch () {
      router.push({ name: 'Search', query: { t: 'User' } })
    },
    search (e) {
      router.push({ name: 'Search', query: { s: e.target.value } })
    }
  }
}

</script>

<style>
#Sidemenu{
  padding-left: 16px;
}
#Sidemenu .sidebar{
  display: block;
  position: relative;
  width: 100%;
  left: 0;
  box-shadow: none;
  border: none;
  z-index: 1 !important;
  top: 0px !important;
  border-radius: 16px;
}
  #posts{
    position: relative;
    z-index: 1;
  }
  .dark #posts .ui.fluid.card .content p{
    color: rgba(255,255,255,0.9)!important;
  }
  .dark #posts .like_type{color: rgba(255,255,255,0.8);}
  #wallSlider .wallSliderElement{
    position: relative;
    height: 150px;
    margin: 0;
    padding: 0;
    background-size: cover;
    background-position: center center;
    background-color: rgba(0,0,0,0.8);
    color: rgba(255,255,255,1);
    border-radius: 8px;
  }
  #wallSlider .wallSliderElement .wallSliderContent{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    border-radius: 8px;
    width: 60%;
    padding: 16px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }
  #wallSlider .wallSliderElement .wallSliderContent h3{
    margin-top: 8px;
  }
  #PostContentForm textarea{
    width: 100%;
    max-width: 100%;
    border: none;
    height: auto;
    max-height: 200px;
    padding: 16px;
    margin-bottom: -8px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  #PostContentForm #PostContentFormCTAs{
    background: rgba(255,255,255,0.6);
    padding: 8px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  #PostContentForm .fileinput{
    display: none;
  }
  #PostContentForm ul{
    padding: 0;
    margin: 0;
    margin-top: 4px;
  }
  #PostContentForm ul li{
    display: inline;
    padding: 0 8px;
  }
  #PostContentForm ul li button{
    background: transparent;
    border: none;
    max-width: 24px;
  }
  #PostContentForm ul li button img{
    max-width: 100%;
    filter: invert(0.3);
  }
  #feed .right.floated.meta{
    padding-top: 8px;
  }
  #feed .ui.card a{
    padding: 4px;
  }
  #feed .ui.card a i.icon{
    font-size: 1.4em;
    color: rgba(0,0,0,0.8);
  }
</style>
