<template>
  <nav id="MainMenu">
    <div class="content-1400 grid-12">
      <div class="col-sp-12 col-sd-4 row-sp-12 display-Dektop">
        <ul>
          <li>
            <router-link to="howitworks">
              How it Works
            </router-link>
          </li>
          <li>
            <router-link to="becomeacreator">
              Become a Creator
            </router-link>
          </li>
        </ul>
      </div>
      <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 t-center">
        <router-link to="/home">
          <img
            id="MainMenuLogo"
            class="responsive-img"
            src="assets/images/fanrocket-logo-cropped.png"
          >
        </router-link>
      </div>
      <div
        id="MainMenuCTA"
        class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 t-right pad-16"
      >
        <router-link
          to="/login"
          class="ui primary button btn-color-1 large circular"
          style="margin-right: 8px;"
        >
          Log in
        </router-link>
        <router-link
          to="/login/register"
          class="ui purple button basic large circular"
        >
          Sign Up
        </router-link>
      </div>
    </div>
    <!-- Sidemenu -->
    <div
      v-if="config.sidebar"
      class="sidebar"
      style="margin-top: 37px;"
    >
      <div class="list-group main-menu my-4">
        <router-link
          class="list-group-item list-group-item-action"
          to="/home"
        >
          <i class="home icon" /> Home
        </router-link>
        <router-link
          class="list-group-item list-group-item-action"
          to="/magazin"
        >
          <i class="newspaper icon" /> Magazin
        </router-link>
        <router-link
          class="list-group-item list-group-item-action"
          to="/about"
        >
          <i class="users icon" /> Über Fanrocket
        </router-link>
        <router-link
          class="list-group-item list-group-item-action"
          to="/features"
        >
          <i class="fire icon" /> Features
        </router-link>
        <a
          class="list-group-item list-group-item-action"
          href="https://www.fanrocket.shop"
          target="_blank"
        ><i class="cart icon" /> Fanrocket Shop</a>
        <div class="pad-8">
          <div v-if="auth.isAuth">
            <router-link
              to="/wall"
              class="ui primary button fluid btn-color-1"
            >
              <img
                class="ui avatar image"
                :src="config.projectURL + 'assets/images/user/' + user.profile_img"
              >
              Community <i class="arrow right icon" />
            </router-link>
          </div>
          <div v-else>
            <router-link
              to="/login"
              class="ui primary button fluid btn-color-1"
            >
              Login
            </router-link>
            <div class="ui divider horizontal">
              Oder
            </div>
            <router-link
              to="/login/register"
              class="ui primary button fluid btn-color-2"
            >
              Registrieren
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <div id="publicSpacer" />
</template>

<script>
import { navbarSemantic } from '@/modules/adminnavbar'
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
export default {
  name: 'Publicnavbar',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  data () {
    return {
    }
  },
  computed: {
    logo () {
      return this.config.darkMode ? 'assets/images/dark-fanrocket-beta-pride.png' : 'assets/images/fanrocket-beta-pride.png'
    }
  },
  mounted () {
    navbarSemantic()
  }
}
</script>

<style scoped>
#publicSpacer {
  padding-top: 27px;
}
@media (max-width: 992px) {
  #publicSpacer {
    padding-top: 36px;
  }
}
#MainMenu{
  border-radius: 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: #fffafa;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
#MainMenu h1{
  font-size: 28px;
  font-family: 'Poppins';
  color: rgba(0,0,0,0.6);
}
#MainMenuLogo{
  max-width: 180px;
  padding: 16px;
}
#MainMenu ul{
  margin-top: 24px;
  margin-bottom: 0!important;
}
#MainMenu ul li{
  list-style: none;
  display: inline-block;
}
#MainMenu ul li a{
  text-decoration: none;
  padding: 24px 16px;
  color: rgba(0,0,0,0.6);
  font-size: 1.1em;
  font-weight: normal;
  transition: 0.3s;
}
#MainMenu ul li a:hover{
  color: #AF57FF;
}
@media (max-width: 992px){
  #MainMenuCTA{
    text-align: center!important;
  }
  #MainMenu ul{
    text-align: center;
    margin-top: 0;
  }
  #MainMenu ul li{
    display: none;
  }
}
@media (max-width: 767px){
  #MainMenuCTA{
    text-align: center!important;
    padding-top: 0!important;
  }
}
/* Darkmode */
.dark #MainMenu{background-color: #1B1C1D!important;}
.dark #MainMenu ul li a{color: rgba(255,255,255,0.6);}
.dark #MainMenu ul li a:hover{color: rgba(255,255,255,1);}
</style>
