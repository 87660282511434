<template>
  <button type="button" @click="startRecord" v-if="!recording && ($parent.audio == null || !playing)">record</button>
  <button type="button" @click="stop = true" v-if="recording">Stop</button>
  <button type="button" @click="restartMemo" v-if="!recording && $parent.audio !== null && currentTime > 1">Neustart</button>
  <button type="button" @click="playMemo" v-if="!recording && $parent.audio !== null && !playing">Play</button>
  <button type="button" @click="pauseMemo" v-if="!recording && $parent.audio !== null && playing">Pause</button>
  <div class="amplitudeContainer">
    <TransitionGroup name="amplis">
        <div v-for="amplitude in amplitudes" class="amplitudeInnerCon" :style="{ width: 100/steps+'%'}" :key="amplitude">
          <div  class="amplitude" :style="{height: amplitude*100+'%'}"></div>
        </div>
    </TransitionGroup>
  </div>
  {{minutes+':'+seconds}}
</template>

<script>
import { reactive } from 'vue'

export default {
  name: 'recorder',
  props: ['steps'],
  data () {
    return {
      mediaRecorder: null,
      recording: false,
      stop: false,
      playing: false,
      seconds: '00',
      minutes: 0,
      voiceMemo: null,
      amplitudeParts: [],
      amplitudes: reactive([]),
      currentTime: 0
    }
  },
  methods: {
    startRecord () {
      const _this = this
      this.stop = false
      this.recording = true

      const handleSuccess = function (stream) {
        let sinusCorrection = false
        const recordedChunks = []
        try {
          _this.mediaRecorder.stop()
        } catch (e) {}
        const time = Date.now() / 1000
        let partTime = Date.now()
        sinusCorrection = (Date.now() / 3000 - time) % Math.PI
        _this.mediaRecorder = new MediaRecorder(stream)

        _this.mediaRecorder.addEventListener('dataavailable', function (e) {
          if (e.data.size > 0 && _this.steps > 0) {
            recordedChunks.push(e.data)
            const timer = (Date.now() / 1000 | 0) - time
            _this.seconds = Math.round(timer % 60)
            _this.minutes = (timer - _this.seconds) / 60 | 0
            if (_this.seconds.toString().length === 1) {
              _this.seconds = '0' + _this.seconds
            } else {
              _this.seconds = '' + _this.seconds
            }
            _this.amplitudeParts.push(Math.abs(Math.sin(Date.now() / 1500 - time - sinusCorrection * Math.PI)))
            if (partTime + 500 <= Date.now()) {
              partTime = Date.now()
              const ampli =
              _this.amplitudes.unshift(_this.calcAmpli(_this.amplitudeParts.reduce((a, b) => a + b, 0) / _this.amplitudeParts.length, 0.2))
              _this.amplitudeParts = []
              if (_this.amplitudes.length > _this.steps) _this.amplitudes.pop()
            }
          }

          if (_this.stop) {
            _this.mediaRecorder.stop()
            stream.getAudioTracks().forEach(function (track) {
              track.stop()
            })
          }
        })

        _this.mediaRecorder.addEventListener('stop', function () {
          _this.$parent.audioBlob = new Blob(recordedChunks, { type: 'audio/mp4; codecs=mp4a' })
          _this.$parent.audio = new Audio(URL.createObjectURL(_this.$parent.audioBlob))
          _this.recording = false
        })

        try {
          _this.mediaRecorder.start(50)
        } catch (e) { console.error(e) }
      }

      const streams = navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(handleSuccess)
    },
    playMemo () {
      const _this = this
      this.playing = true
      this.$parent.audio.play()
      this.$parent.audio.onended = () => {
        _this.$parent.audio.currentTime = 0
        _this.currentTime = 0
        this.playing = false
      }
      this.$parent.audio.video.ontimeupdate = () => {
        _this.currentTime = _this.$parent.audio.currentTime
      }
    },
    pauseMemo () {
      this.playing = false
      this.$parent.audio.pause()
    },
    restartMemo () {
      this.$parent.audio.currentTime = 0
      this.currentTime = 0
      this.playMemo()
    },
    calcAmpli (ampli, increase) {
      return ampli + (1 - ampli) * increase
    }
  },
  mounted () {
    this.startRecord()
  },
  unmounted () {
    this.stop = true
    console.log(this.stop, 'JOO')
  }
}
</script>

<style scoped>
  .amplitude{
    background-color: blueviolet;
    border-radius: 5px;
    width: 50%;
    margin-left: 50%;
    transform: translateY(-50%);
    transition: none;
  }
  .amplitudeContainer{
    width: 80%;
    height: 50px;
    margin-left: 100px;
    position: relative;
  }
  .amplitudeInnerCon{
    height: 100%;
    position: relative;
    float: right;
  }

  .amplis-move,
  .amplis-enter-active,
  .amplis-leave-active {
    transition: all 0.5s linear;
  }

  .amplis-enter-from{
    transform: translateX(30px);
    opacity: 0;
  }

  .amplis-leave-to {
    transform: translateX(-30px);
    opacity: 0;
  }
  .amplis-leave-active{
    position: absolute;
  }
</style>
