<template>
  <div
    v-if="type == 'single'"
    class="position-relative"
  >
    <transition-group name="loader">
      <wall-loader v-if="Object.keys(wall.single).length === 0 && !wall.loadedSingle" />
      <post
        v-else
        :post="wall.single"
      />
    </transition-group>
  </div>
  <div
    v-if="type == 'profile'"
    class="position-relative"
  >
    <transition-group name="loader">
      <wall-loader v-if="Object.keys(wall.profilePosts).length === 0 && !wall.loadedProfile" />
      <post
        v-for="post in wall.profilePosts"
        v-else
        :key="post.id"
        :post="post"
      />
    </transition-group>
  </div>
  <div
    v-else
    class="position-relative"
  >
    <transition-group name="loader">
      <wall-loader v-if="Object.keys(wall.posts).length === 0 && !wall.loadedWall" />
      <post
        v-for="post in wall.posts"
        v-else
        :key="post.id"
        :post="post"
      />
    </transition-group>
  </div>
</template>

<script>
import post from "@/components/wall/post";
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {wallStore} from "@/store/wallStore";
import {configStore} from "@/store/configStore";
import WallLoader from "@/components/wall/wallLoader";
export default {
  name: "Wall",
  components: {WallLoader, post},
  props: ['type', 'post', 'profile'],
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()
    const wall = wallStore()

    return {auth, config, user, wall}
  },
  data() {
    return {
      scrollToTop: false
    }
  },
  computed: {
  },
  mounted() {
    const _this = this
    if(this.type === 'single') this.wall.loadSingle(this.post)
    if(this.type === 'profile') this.wall.loadProfile(this.profile)
    window.addEventListener('scroll', _this.scroll)
  },
  unmounted() {
    if(this.type === 'profile')this.wall.destroyProfile(this.profile)
  },
  methods: {
    scroll(){
      const scrollTemp = $(document).scrollTop()
      if ((scrollTemp + 400) >= ($(document).innerHeight() - $('body').height()) && this.type !== 'single' && this.type !== 'profile') {
        this.wall.loadMore()
      }else if ((scrollTemp + 400) >= ($(document).innerHeight() - $('body').height()) && this.type !== 'single'){
        this.wall.loadMoreProfile(this.profile)
      }
      this.scrollToTop = scrollTemp > $('body').height()
    }
  }
}
</script>

<style scoped>
.loader-enter-active,
.loader-leave-active {
  transition: all 0.25s ease;
}

.loader-enter-from,
.loader-leave-to {
  opacity: 0;
}
</style>