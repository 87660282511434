<template>
  <div class="content"  v-if="size =='small'">
      <a class="author" v-if="notidata.status == 1">Deine Verifizierung war erfolgreich!</a>
      <a class="author" v-else>Deine Verifizierung ist fehlgeschlagen.</a>
      <div class="text" v-if="notidata.status == 1">
            Du bist nun als überprüft gekennzeichnet.
            <img src="assets/images/fake-check.png">
        </div>
      <div class="text" v-else>
            Leider konnten wir dich nicht verifizieren.
            <img src="assets/images/match-dislike.png">
        </div>
      <div class="metadata t-right" style="display: block">
            <div class="date">
                 {{date}}
              </div>
        </div>
  </div>
  <div class="col-sp-9 col-sd-11 row-sp-12 pad-16" v-else>
    <h4 class="ui header" v-if="notidata.status == 1">
      Deine Verifizierung war erfolgreich!
      <div class="sub header">
        Du bist nun als überprüft gekennzeichnet.
        <img src="assets/images/fake-check.png">
      </div>
    </h4>
    <h4 class="ui header" v-else>
      Deine Verifizierung ist fehlgeschlagen.
      <div class="sub header">
        Leider konnten wir dich nicht verifizieren.
       <img src="assets/images/match-dislike.png">
      </div>
     </h4>
   <div class="t-right">{{date}}</div>
  </div>
</template>

<script>
export default {
  name: 'verified',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime()
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  },
  mounted () {
    $('#notis').dropdown()
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
