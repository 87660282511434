<template>
  <div class="sidebar">
    <div class="row no-gutters">
      <router-link
        :to="'/p/' + user.username"
        class="col-auto align-self-center"
      >
        <figure
          class="avatar avatar-50"
          style="background: transparent;"
        >
          <img
            class="ar-1-1"
            :src="config.projectURL + 'assets/images/user/' + (user.profile_img ?? 'avatar_default.png' )"
          >
        </figure>
      </router-link>
      <router-link
        :to="'/p/' + user.username"
        class="col pl-3 align-self-center"
      >
        <p class="my-0">
          {{ user.username }}
        </p>
        <p class="text-mute my-0 small">
          {{ user.firstname }}
        </p>
      </router-link>
      <div class="col-auto align-self-center">
        <a
          class="btn btn-link p-2 logout"
          @click="auth.logout"
        >
          <i class="material-icons">logout</i>
        </a>
      </div>
    </div>
    <div class="list-group main-menu my-4">
      <router-link
        active-class="active"
        to="/wall"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">dashboard</i>Feed
      </router-link>
      <router-link
        active-class="active"
        to="/search"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">search</i>Explore
      </router-link>
      <router-link
        active-class="active"
        to="/subscriptions"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">favorite</i>Subscriptions
      </router-link>
      <router-link
        active-class="active"
        to="/settings"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">settings</i>Settings
      </router-link>
      <router-link
        active-class="active"
        to="/wallet"
        class="list-group-item list-group-item-action"
      >
        <i class="material-icons">account_balance_wallet</i>Wallet
      </router-link>
    </div>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: 'Sidebar',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    return {auth, user, config}
  },
  data () {
    return {
    }
  },
}
</script>

<style>
.sidebar .avatar img{
  border-radius: 90%;
}
/* Darkmode */
.dark .sidebar{
  background-color: #141414;
}
</style>
