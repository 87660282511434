<template>
  <div id="hoverModal" class="ui segment no-shadow profile-preview">
    <h5 class="ui header active">
      <img id="hoverModalImg" class="ui avatar image">
      <div class="content">
        <span id="hoverModalFirstName">Vorname</span>
        <div id="hoverModalUserName" class="sub header active">
          @username
        </div>
      </div>
    </h5>
  </div>
  <div class="ui mini deletePost modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Post wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deletePost.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="ui mini deleteComment modal">
    <i class="close icon"></i>
    <div class="header">
      Biste du dir sicher?
    </div>
    <div class="content">
      Dieser Kommentar wird unwiderruflich gelöscht.
    </div>
    <div class="actions">
      <div class="ui tiny button" onclick="$('.deleteComment.modal').modal('hide');">
        Abbrechen
      </div>
      <div class="ui tiny button approve btn-color-1">
        Löschen
      </div>
    </div>
  </div>
  <div class="emojiCon" id="emojiCon_0">
    <emojimenu></emojimenu>
  </div>
  <div id="page-post" class="spaceTop">
    <div class="postList">
      <div class="pad-24"></div>
      <div id="posts">
      </div>
    </div>
    <div class="pad-40"></div>
  </div>
</template>

<script>
import Emojimenu from '@/components/plugins/emojimenu'
import { Wall } from '@/modules/wall'
export default {
  name: 'post',
  title: 'Fanrocket | Post',
  props: ['post'],
  components: { Emojimenu },
  mounted () {
    window.ptr = false
    window.wall = new Wall('single')
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>
.ui.post.card .content{
  z-index: 99;
}
.commentSec{
  padding-top: 50px;
  z-index: 1;
}
</style>
