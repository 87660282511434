<template>
  <router-link class="grid-item" v-for="(img, index) in this.$parent.media" :to="'/post/' + img.id" :key="index">
    <img :id="img.id" class="galeryPost" :src="projectURL + 'assets/images/posts/' + img.src">
  </router-link>
</template>

<script>
export default {
  name: 'image',
  data () {
    return {
      projectURL: window.projectURL
    }
  }
}
</script>

<style scoped lang="scss">
#page-search {
  padding-bottom: 80px;
  #SearchMobileMenu {
    display: block;
    margin: -8px;
    .ui.secondary.pointing.menu .item {
      width: calc(25% - 4px);
      font-size: 1.2em;
      i {
        margin: 0 auto;
      }
    }
  }
  SearchResultsMedia {
    display: block;
  }
  #SearchResultsUser {
  }
  #SearchResultsForum {
  }
  #SearchResultsHashtags #posts .ui.fluid.card.post {
    margin-bottom: 8px;
  }
  #posts .ui.post.card {
    width: 100%;
    margin: 0 auto;
  }
}
.contact-element {
  vertical-align: middle;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  .contact-profile-img {
    display: inline-block;
    position: relative;
    .ui.avatar.image {
      height: 3em;
      width: 3em;
      margin-top: -40px;
    }
  }
  .onlinestatus {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  .contact-content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 8px;
    background: #000000;
    background: linear-gradient(0deg, #000000 10%, rgba(255,255,255,0) 100%);
    color: #ffffff;
    width: 100%;
  }
  &.ar-1-1 {
    padding-top: 100%;
  }
  .ui.header {
    margin-bottom: 0!important;
    i.check.icon {
      display: inline-block;
      font-size: 1em;
      margin-top: -5px;
    }
  }
  .location.meta {
    color: #ffffff;
    font-size: 0.8em;
  }
}
#MansoryGaleryGrid {
  .grid-sizer {
    width: 33.333%;
  }
  .grid-item {
    width: 33.333%;
    float: left;
    padding: 2px;
    opacity: 0;
    transition: opacity 0.3s ease;
    img {
      display: block;
      width: 100%;
      cursor: pointer;
    }
  }
  .grid:after {
    content: '';
    display: block;
    clear: both;
  }
}
@media(min-width: 992px) {
  #MansoryGaleryGrid {
    .grid-sizer {
      width: 20%;
    }
    .grid-item {
      width: 20%;
    }
  }
}
#SearchResultsForum .category-img {
  max-width: 24px;
  vertical-align: text-bottom;
}
</style>
