import { cookieLogin, resetApp } from '@/modules/main'
import router from '@/router'
/* eslint-disable eqeqeq */
export function initSettings () {
  $('#passwordDiff').hide()
  $('#passwordFail').hide()
  $('#emailFail').hide()
  $('#emailSuccess').hide()
  $('#passwordSuccess').hide()

  const selectPublic = document.getElementById('SelectionPublic')
  const selectPrivate = document.getElementById('SelectionPrivate')
  const ProfileValue = document.getElementById('ProfileValue')

  $('#vibrateUI').checkbox({
    onChecked: function () { localStorage.setItem('vibrate', 1) },
    onUnchecked: function () { localStorage.setItem('vibrate', 0) }
  })
  if (localStorage.getItem('vibrate') === '1') {
    $('#vibrateUI').checkbox('check')
  }
  $('#emailForm').submit(function (e) {
    e.preventDefault()
    $('#passwordDiff').hide()
    $('#passwordFail').hide()
    $('#passwordSuccess').hide()
    $('#emailFail').hide()
    $('#emailSuccess').hide()
    const formdata = new FormData(this)
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: window.projectURL + 'einstellungen/changeEmail',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          $('#emailSuccess').show()
        } else if (xhr.status === 260) {
          $('#emailFail').show()
        } else if (xhr.status === 261) {
          $('#passwordFail').show()
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { $('#emailForm').submit() }).catch(openLogin('einstellungen'))
        }
      },
      error: function (data, textStatus, xhr) {
        openLogin('einstellungen')
      },
      cache: false,
      contentType: false,
      processData: false
    })
  })

  $('#passwordForm').submit(function (e) {
    e.preventDefault()
    $('#passwordDiff').hide()
    $('#passwordSuccess').hide()
    $('#passwordFail').hide()
    $('#emailFail').hide()
    $('#emailSuccess').hide()
    const formdata = new FormData(this)
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: window.projectURL + 'einstellungen/changePassword',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          $('#passwordSuccess').show()
        } else if (xhr.status === 251) {
          $('#passwordDiff').show()
        } else if (xhr.status === 260) {
          $('#passwordFail').show()
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { $('#emailForm').submit() }).catch(openLogin('einstellungen'))
        }
      },
      error: function (data, textStatus, xhr) {
        openLogin('einstellungen')
      },
      cache: false,
      contentType: false,
      processData: false
    })
  })

  $('#logoutForm').submit(function (e) {
    e.preventDefault()
    $('#passwordDiff').hide()
    $('#passwordFail').hide()
    $('#passwordSuccess').hide()
    $('#emailFail').hide()
    $('#emailSuccess').hide()
    const formdata = new FormData(this)
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: window.projectURL + 'einstellungen/delAllLogin',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          const darkmode = localStorage.getItem('darkmode')
          const vibrate = localStorage.getItem('vibrate')
          localStorage.clear()
          sessionStorage.clear()
          localStorage.setItem('darkmode', darkmode)
          localStorage.setItem('vibrate', vibrate)

          try {
            const cookies = document.cookie.split(';')

            for (var i = 0; i < cookies.length; i++) {
              var cookie = cookies[i]
              var eqPos = cookie.indexOf('=')
              var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
              document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
            }
            location.href = '/'
          } catch (e) {}
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { $('#emailForm').submit() }).catch(openLogin('einstellungen'))
        }
      },
      error: function (data, textStatus, xhr) {
        openLogin('einstellungen')
      },
      cache: false,
      contentType: false,
      processData: false
    })
  })

  $('#deleteForm').submit(function (e) {
    e.preventDefault()
    $('#passwordDiff').hide()
    $('#passwordFail').hide()
    $('#passwordSuccess').hide()
    $('#emailFail').hide()
    $('#emailSuccess').hide()
    const formdata = new FormData(this)
    formdata.append('SessionID', sessionStorage.getItem('SessionID'))

    $.ajax({
      url: projectURL + 'einstellungen/deleteAccount',
      type: 'POST',
      data: formdata,
      success: function (data, textStatus, xhr) {
        if (xhr.status === 250) {
          localStorage.clear()

          try {
            const cookies = document.cookie.split(';')

            for (var i = 0; i < cookies.length; i++) {
              var cookie = cookies[i]
              var eqPos = cookie.indexOf('=')
              var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie
              document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT'
            }
            location.href = '/'
          } catch (e) {}
        } else if (xhr.status === 260) {
          $('#passwordFail').show()
        } else if (xhr.status === 299) {
          cookieLogin().then(function () { $('#emailForm').submit() }).catch(openLogin('einstellungen'))
        }
      },
      error: function (data, textStatus, xhr) {
        openLogin('einstellungen')
      },
      cache: false,
      contentType: false,
      processData: false
    })
  })

  $('#volume').on('change', function () {
    localStorage.setItem('volume', $(this).val())
    $.ajax({
      type: 'POST',
      url: projectURL + 'einstellungen/setVolume',
      data: { volume: $(this).val() }
    })
  })

  loadSettings()
}

export function toShort (value) {
  var message = document.getElementById('toShort')
  if (value.toLowerCase() === 'penis' || value.toLowerCase() === 'schwanz' || value.toLowerCase() === 'cock' || value.toLowerCase() === 'pimmel') {
    message.style.display = 'block'
  } else {
    message.style.display = 'none'
  }
}

export function selectPublicFunc () {
  selectPublic.classList.add('select')
  selectPrivate.classList.remove('select')
  ProfileValue.value = 1
  $.post('/einstellungen/setProfile', { data: 1, postkey: sessionStorage.getItem('postkey') }, function (result) {})
}

export function selectPrivateFunc () {
  selectPrivate.classList.add('select')
  selectPublic.classList.remove('select')
  ProfileValue.value = 0
  $.post('/einstellungen/setProfile', { data: 0, postkey: sessionStorage.getItem('postkey') }, function (result) {})
}

export function openSettings2 (setting) {
  var mediaQuery = window.matchMedia('(max-width: 991px)')
  if (mediaQuery.matches) {
    $('#SettingsMenu').attr('style', 'display: none !important')
    $('#SettingsHeader').attr('style', 'display: none !important')
  }
  $('.SettingsPanel').attr('style', 'display: none !important')
  $('#' + setting + 'Settings').attr('style', 'display: block !important')
}

export function openSettingsMenu () {
  $('.SettingsPanel').attr('style', 'display: none !important')
  $('#SettingsMenu').show()
  $('#SettingsHeader').show()
}

export function loadSettings () {
  $.post(projectURL + 'einstellungen/indexjs', { SessionID: sessionStorage.getItem('SessionID') }, function (msg, status, xhr) {
    try {
      if (xhr.status === 250) {
        const data = JSON.parse(msg)
        $('#Settings').find('*').filter(function () {
          try {
            if ($(this).data('select') !== undefined) {
              const param = $(this).data('select').split('-')
              let value = data[param[0]]
              if (param.length > 1)value = value[param[1]]

              if (value == 1) {
                $(this).checkbox('set checked')
              }
            } else if ($(this).data('check') !== undefined) {
              const param = $(this).data('check').split('-')
              let value = data[param[0]]
              if (param.length > 1)value = value[param[1]]
              if (value == 1) {
                $(this).checkbox('check')
              }
            } else if ($(this).data('active') !== undefined) {
              const param = $(this).data('active').split('-')
              let value = data[param[0]]
              let equal = param[1]
              if (param.length > 2) {
                value = value[param[1]]
                equal = param[2]
              }
              if (value == equal) {
                $(this).addClass('select')
              }
            } else if ($(this).data('val') !== undefined) {
              const param = $(this).data('val').split('-')
              const replace = data[param[0]][param[1]]
              $(this).val(replace)
            }
          } catch (e) {}
        })

        $('.push.checkbox').on('change', function () {
          if ($('#PushMasterUI').checkbox('is checked')) {
            $('#push').show()
          } else {
            $('#push').hide()
          }
          const formData = new FormData(document.querySelector('#pushForm'))
          formData.append('SessionID', sessionStorage.getItem('SessionID'))
          formData.append('postkey', sessionStorage.getItem('postkey'))
          $.ajax({
            type: 'POST',
            url: window.projectURL + 'einstellungen/editpush',
            data: formData,
            processData: false,
            contentType: false
          })
        })

        $('.mail.checkbox').on('change', function () {
          if ($('#MailMasterUI').checkbox('is checked')) {
            $('#mail').show()
          } else {
            $('#mail').hide()
          }
          const formData = new FormData(document.querySelector('#mailForm'))
          formData.append('SessionID', sessionStorage.getItem('SessionID'))
          formData.append('postkey', sessionStorage.getItem('postkey'))
          $.ajax({
            type: 'POST',
            url: window.projectURL + 'einstellungen/editmail',
            data: formData,
            processData: false,
            contentType: false
          })
        })

        $('#DarthVaderUI').on('change', function () {
          const formData = new FormData(document.querySelector('#darkForm'))
          formData.append('SessionID', sessionStorage.getItem('SessionID'))
          formData.append('postkey', sessionStorage.getItem('postkey'))
          $.ajax({
            type: 'POST',
            url: window.projectURL + 'einstellungen/setDarthVader',
            data: formData,
            processData: false,
            contentType: false
          })
          if ($('#DarthVaderUI').checkbox('is checked')) {
            localStorage.setItem('darkmode', 1)
            document.getElementsByTagName('html')[0].setAttribute('class', 'dark')
            window.fanrocket.darkMode = true
          } else {
            localStorage.setItem('darkmode', 0)
            document.getElementsByTagName('html')[0].setAttribute('class', '')
            window.fanrocket.darkMode = false
          }
        })
      } else if (xhr.status === 299) {
        cookieLogin().then(loadSettings).catch(function () {
          resetApp()
          router.push('/login')
        })
      }
    } catch (e) { console.log(e) }
  })
}
