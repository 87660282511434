<template>
  <div id="Wallet" class="spaceTop">
    <div class="content-1400">
      <div class="grid-12 pad-16">
        <div class="col-sp-12 row-sp-12">
          <a @click="this.$router.back();">
            <i class="icon left arrow"></i>
          </a>
        </div>
        <div class="col-sp-12 row-sp-12 pad-16">
          <h1 class="ui header">
            Wallet
            <div class="sub header">Payment Methods</div>
          </h1>
        </div>
        <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
          <div class="ui segment no-shadow no-border bg-color-1 inverted br-8">
            <div class="pad-8"></div>
            <div class="grid-12">
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <h2 class="ui header inverted">
                  Balance
                  <div class="sub header">Current available</div>
                </h2>
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-12">
                <div style="float: right;" class="ui horizontal small inverted statistic">
                  <div class="value">
                    2,204
                  </div>
                  <div class="label">
                    $ USD
                  </div>
                </div>
                <div style="clear: both;"></div>
                <div class="t-right">
                  <div class="ui inverted circular mini basic button">
                    Payout
                  </div>
                </div>
              </div>
            </div>
            <div class="pad-8"></div>
          </div>
          <div class="pad-4"></div>
          <div class="ui vertical fluid big no-border no-shadow menu">
            <a class="item">
              <i class="credit card outline icon"></i> Payment Methods
            </a>
            <a class="item">
              <i class="university icon"></i> Bank account
            </a>
            <a class="item">
              <i class="dollar sign icon"></i> Payments
            </a>
            <a class="item">
              <i class="money bill alternate outline icon"></i> Cashout
            </a>
            <a class="item">
              <i class="percent icon"></i> Discounts
            </a>
          </div>
        </div>
        <div class="col-sp-12 col-sd-8 row-sp-12 pad-8">
          <div class="ui segment no-shadow no-border pad-24">
            <h3 class="ui header">
              Add payment options
              <div class="sub header">
                <br>
                We are fully compliant with Payment Card Industry Data Security Standards.<br>
                The charges on your credit card statement will appear as FanRocket.
              </div>
            </h3>
            <div class="t-right">
              <button class="ui button btn-color-1 labeled icon circular">
                <i class="credit card icon"></i>  Add Card
              </button>
            </div>
          </div>
          <table class="ui fixed table no-shadow no-border">
            <thead>
            <tr>
              <th>Name</th>
              <th>Card</th>
              <th>Card-Number</th>
              <th>Date</th>
              <th class="right aligned"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>Max Mustermann</td>
              <td>Mastercard</td>
              <td>**** **** **** 5372</td>
              <td>04/24</td>
              <td class="right aligned">
                <button class="ui basic button mini">Remove</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'wallet',
  title: 'FanRocket | Wallet',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style>

</style>
