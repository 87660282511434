<template>
  <div id="page-faq"  class="spaceTop">
    <section class="bg-white">
      <div class="content-1400 t-center">
        <div class="pad-40">
          <div class="pad-8"></div>
          <h1 class="ui centered header">
            <div class="content">
              FAQ
              <div class="sub header">Frequently Asked Questions</div>
            </div>
          </h1>
          <p style="max-width: 600px; margin: 0 auto;" class="color-grey">
            Do you have any questions about our platform? Maybe you can already find the answer to your question here. If you cannot find it here, please write to us using our contact form.
          </p>
          <div class="pad-8"></div>
          <div class="content-600">
            <div class="ui accordion t-left fluid">
            <div class="title">
              <i class="dropdown icon"></i>
              Who is FanRocket for?
            </div>
            <div class="content">
              <p class="transition hidden">
                Coming soon...
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              How much fees does FanRocket take from Creators
            </div>
            <div class="content">
              <p class="transition hidden">
                Coming soon...
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              I want to make money with Fanrocket. Where should I start?
            </div>
            <div class="content">
              <p class="transition hidden">
                We take you by the hand and go through every step with you. From business registration, tax issues (we have a tax advisor for that) to account creation and the first content.
              </p>
            </div>
            <div class="title">
              <i class="dropdown icon"></i>
              How exactly do I make money with my content?
            </div>
            <div class="content">
              <p class="transition hidden">
                There are several ways to earn money on Fanrocket or other Plattforms.<br><br>
                The first is, of course, the (self-)set amount for the monthly membership.<br><br>
                If a customer particularly likes a picture or video, they can tip you for it.<br><br>
                And finally, by writing messages. Every message that people want to write to you on FanRocket costs money. Again, the more you write with your customers and build a closer relationship, the more you can earn.
              </p>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="pad-40"></div>
    </section>
    <section>
      <div class="content-1400">
        <div class="pad-40"></div>
        <div class="pad-16 t-center">
          <h2 class="ui header">
            Do you need further help?
          </h2>
          <div class="pad-8">
            <router-link to="contact" class="ui button circular basic purple big">
              Use our contact form
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'faq',
  title: 'FanRocket | FAQ',
  mounted () {
    window.fanrocket.loading = false
    $('.ui.accordion').accordion()
  }
}
</script>

<style scoped>
#page-faq{
  margin-bottom: 80px;
  padding-bottom: 80px;
}
/* Darkmode */
.dark #page-faq p{color: rgba(255,255,255,0.6);}
.dark .ui.accordion .title:not(.ui){color: rgba(255,255,255,0.8);}
</style>
