import { defineStore } from 'pinia'
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import { io } from "socket.io-client";
import {wallStore} from "@/store/wallStore";

export const socketStore = defineStore('socket', {
    state: () => ({
        io: io,
        socket: {},
        online: false,
        change: false,
        connecting: false,
        serverList: ['https://node1.fanrocket.net/fanrocket', 'https://node2.fanrocket.net/fanrocket'],
        currentServer: 0,
        smids: [] //SocketMessageIDs
    }),
    getters: {
    },
    actions: {
        /**
         * connect Socket
         */
        connect() {
            const _this = this
            const auth = authStore()
            const config = configStore()
            const user = userStore()
            const wall = wallStore()
            if (this.connecting || auth.nodeID == null) {
                this.change = false
                this.changeNodeServer()
                return
            }
            this.connecting = true

            this.socket = this.io.connect(this.serverList[this.currentServer], {
                reconnection: false
            })
            this.socket.removeAllListeners()

            this.socket.on('error', (err) => {
                console.log("error due to "+err.message);
                _this.change = false
                _this.connecting = false
                _this.changeNodeServer()
            })

            this.socket.on('disconnect', (err) => {
                console.log("disconnect due to "+err.message);
                _this.change = false
                _this.connecting = false
                _this.online = false
                _this.changeNodeServer()
            })

            this.socket.on('connect_error', (err) => {
                console.log("connect_error due to "+err.message);
                _this.change = false
                _this.connecting = false
                _this.changeNodeServer()
            })

            this.socket.on('connect_failed', (err) => {
                console.log("connect_failed due to " + err.message);
                _this.change = false
                _this.connecting = false
                _this.changeNodeServer()
            })

            this.socket.on('connect', function () {
                console.log('connected')
                _this.connecting = false
                _this.socket.emit('nodeid', { room: auth.nodeID, uname: user.username, id: user.user_id, controller: window.wurstsalat.$route.name })
            })

            this.socket.on('registered', (data) => {
                console.log('registered')
                _this.change = false
                _this.online = true
                wall.registerListener()
            })
        },
        disconnect() {
            this.socket.removeAllListeners()
        },
        changeNodeServer() {
            const _this = this
            const auth = authStore()
            if(!auth.isAuth){
                setTimeout(function () {
                    _this.changeNodeServer()
                }, 500)
                return
            }
            if (this.change || this.online) return
            this.change = true
            this.currentServer = this.currentServer === 1 ? 0 : 1
            setTimeout(function () {
                _this.connect()
            }, 1000)
        }
    },
})