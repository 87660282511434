import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {chatStore} from "@/store/chatStore";

import axios from 'axios'
import Login from '@/views/login.vue'
import adminNavBar from '@/views/basic/adminNavBar'
import publicNavBar from '@/views/basic/publicNavBar'
import wall from '@/views/wall'
import wallet from '@/views/wallet'
import subscriptions from '@/views/subscriptions'

import profile from '@/views/profile'

import publicFooter from '@/views/basic/publicFooter'

import home from '@/views/home'

import settings from '@/views/settings'

import notification from '@/views/notification'

import visitors from '@/views/visitors'

import redirect from '@/views/redirect'

import post from '@/views/post'

import search from '@/views/search'


import editProfile from '@/views/editProfile'
import epMenu from '@/components/editProfile/menu'
import epInfo from '@/components/editProfile/info'
import epLove from '@/components/editProfile/love'
import epLocation from '@/components/editProfile/location'
import epInterest from '@/components/editProfile/interests'
import epSocial from '@/components/editProfile/socialMedia'
import epQuestionnaire from '@/components/editProfile/questionnaire'


import howitworks from '@/views/website/howitworks'
import createstudio from '@/views/website/createstudio'
import legalnotice from '@/views/website/legalnotice'
import aboutus from '@/views/website/aboutus'
import privacypolicy from '@/views/website/privacypolicy'
import faq from '@/views/website/faq'
import becomeacreator from '@/views/website/becomeacreator'
import contact from '@/views/website/contact'
import management from '@/views/website/management'
import affiliateprogram from '@/views/website/affiliateprogram'
import termsofuse from '@/views/website/termsofuse'
import notFound from '@/views/website/404'

const routes = [
  {
    path: '/',
    name: 'Website',
    components: {
      navBar: publicNavBar,
      default: home,
      footer: publicFooter
    },
    meta: { noAuth: true },
  },
  {
    path: '/home',
    name: 'Home',
    components: {
      navBar: publicNavBar,
      default: home,
      footer: publicFooter
    }
  },
  {
    path: '/login/:subpage?',
    name: 'Login',
    components: {
      default: Login
    },
    props: {
      default: true
    },
    meta: { noAuth: true }
  },
  {
    path: '/register/email',
    name: 'register',
    beforeEnter: (to, from, next) => {
      axios.get(window.projectURL + 'register/email?' + to.query.code)
      next('/wall')
    }
  },
  {
    path: '/wall',
    name: 'Wall',
    alias: '/dashboard',
    components: {
      navBar: adminNavBar,
      default: wall
    },
    meta: { auth: true }
  },
  {
    path: '/wallet',
    name: 'Wallet',
    alias: '/wallet',
    components: {
      navBar: adminNavBar,
      default: wallet
    },
    meta: { auth: true }
  },
  {
    path: '/subscriptions',
    name: 'Subscriptions',
    alias: '/subscriptions',
    components: {
      navBar: adminNavBar,
      default: subscriptions
    },
    meta: { auth: true }
  },
  {
    path: '/p/:username',
    name: 'Profile',
    alias: '/profile/:username',
    components: {
      navBar: adminNavBar,
      default: profile
    },
    meta: { auth: true }
  },
  {
    path: '/notifications/:friends?',
    name: 'Notifications',
    alias: ['/notification/:friends?', '/n/:friends?'],
    components: {
      navBar: adminNavBar,
      default: notification
    },
    meta: { auth: true }
  },
  {
    path: '/v',
    name: 'Visitors',
    alias: ['/visitors'],
    components: {
      navBar: adminNavBar,
      default: visitors
    },
    meta: { auth: true }
  },
  {
    path: '/post/:post/:comment?',
    name: 'Post',
    components: {
      navBar: adminNavBar,
      default: post
    },
    props: {
      default: true
    },
    meta: { auth: true }
  },
  {
    path: '/search',
    name: 'Search',
    components: {
      navBar: adminNavBar,
      default: search
    },
    meta: { auth: true }
  },
  {
    path: '/ep/',
    alias: '/editProfile/',
    name: 'EditProfile',
    components: {
      navBar: adminNavBar,
      default: editProfile
    },
    meta: { auth: true }
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: adminNavBar,
      default: settings
    },
    meta: { auth: true }
  },
  {
    path: '/redirect/',
    name: 'Redirect',
    components: {
      default: redirect
    }
  },
  {
    path: '/legalnotice',
    name: 'Legal Notice',
    components: {
      navBar: publicNavBar,
      default: legalnotice,
      footer: publicFooter
    }
  },
  {
    path: '/howitworks',
    name: 'How it works',
    components: {
      navBar: publicNavBar,
      default: howitworks,
      footer: publicFooter
    }
  },
  {
    path: '/aboutus',
    name: 'About us',
    components: {
      navBar: publicNavBar,
      default: aboutus,
      footer: publicFooter
    }
  },
  {
    path: '/becomeacreator',
    name: 'Become a creator',
    components: {
      navBar: publicNavBar,
      default: becomeacreator,
      footer: publicFooter
    }
  },
  {
    path: '/management',
    name: 'Management',
    components: {
      navBar: publicNavBar,
      default: management,
      footer: publicFooter
    }
  },
  {
    path: '/affiliateprogram',
    name: 'Affiliateprogram',
    components: {
      navBar: publicNavBar,
      default: affiliateprogram,
      footer: publicFooter
    }
  },
  {
    path: '/createstudio',
    name: 'Createstudio',
    components: {
      navBar: publicNavBar,
      default: createstudio,
      footer: publicFooter
    }
  },
  {
    path: '/privacypolicy',
    name: 'Privacy policy',
    components: {
      navBar: publicNavBar,
      default: privacypolicy,
      footer: publicFooter
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    components: {
      navBar: publicNavBar,
      default: faq,
      footer: publicFooter
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    components: {
      navBar: publicNavBar,
      default: contact,
      footer: publicFooter
    }
  },
  {
    path: '/termsofuse',
    name: 'Terms of use',
    components: {
      navBar: publicNavBar,
      default: termsofuse,
      footer: publicFooter
    }
  },
  {
    path: '/404',
    name: '404',
    components: {
      navBar: publicNavBar,
      default: () => import('@/views/website/404'),
      footer: publicFooter
    }
  },
  {
    path: '/teamdashboard',
    name: 'Teamdashboard',
    beforeEnter () {
      location.href = '/teamdashboard'
      next(false)
    }
  },
  {
    path: '/redaktion',
    name: 'Redaktion',
    beforeEnter () {
      location.href = '/redaktion'
      next(false)
    }
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    components: {
      navBar: publicNavBar,
      default: notFound,
      footer: publicFooter
    }
  },
  {
    path: '/test',
    name: 'Test',
    components: {
      default: () => import('@/views/test')
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()
  const chat = chatStore()

  config.sidebar = false
  // prevent back from chat.
  if (to.path.startsWith('/messages')) {
    chat.force = true
    next('/wall')
  }

  window.scrollTo(0, 0)
  window.onscroll = function () {}
  $('.dropdown').dropdown()

  if(chat.force){
    chat.visible = true
    chat.force = false
  }else{
    chat.visible = false
  }

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(function (resolved) {
          config.loading = true
            next()
        })
        .catch(function (e) {
          console.log(e)
          next({
            path: '/login',
            query: { redirect: to.fullPath }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .catch((e)=> {
        })
        .finally(function () {
          if (auth.isAuth) {
            config.loading = true
            next({
              path: '/wall'
            })
          } else {
            config.loading = true
            next()
          }
    })
  } else {
    auth.update()
    config.loading = true
    next()
  }
})

/*
router.afterEach((to, from) => {
  const toDepth = to.path.split('/').length
  const fromDepth = from.path.split('/').length
  to.meta.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
})*/

window.router = router

export default router
