<template>
  <transition-group name="modal">
    <subscription-modal
      v-if="subscriptionModal"
      :profile="profile"
      @close="subscriptionModal = false"
    />
  </transition-group>
  <div
    v-if="status == 'ok'"
    id="UserProfile"
    class="spaceTop"
  >
    <div id="ptrest" />
    <div class="content-1000">
      <div class="pad-16" />
      <section
        id="ProfileBanner"
        :style="{backgroundImage:`url(${config.projectURL + 'assets/images/user/' + (profile.banner ?? 'default_banner.jpg')})`}"
      >
        <div id="Favorit">
          <i
            v-if="profile.username == user.username"
            class="icon edit outline"
            @click="$router.push('/ep')"
          />
          <i
            v-else
            class="icon star outline"
          />
        </div>
      </section>
      <section id="ProfileTitle">
        <div class="grid-12">
          <div class="col-sp-4 col-tb-3 col-sd-2 row-sp-12">
            <img
              id="ProfileImage"
              :src="config.projectURL + 'assets/images/user/' + (profile.image ?? 'avatar_default.png')"
            >
          </div>
          <div class="col-sp-8 col-tb-9 col-sd-10 row-sp-12">
            <div id="ProfileContent">
              <h2 class="ui header">
                {{ profile.username }} <i
                  v-if="profile.verified"
                  class="icon check circle"
                  title="Verified"
                />
              </h2>
              <a
                v-if="online"
                class="ui basic mini label OnlineStatus"
              >
                <i class="circle green icon" />
                Online
              </a>
              <a
                v-else
                class="ui basic mini label OfflineStatus"
                data-hide="profile-lastActive"
              >
                <i class="circle grey icon" />
                <span class="OfflineStatus">{{ config.calculateTimeString(dateTime) }}</span>
              </a>
              <div id="MoreOptions">
                <i class="icon ellipsis vertical" />
              </div>
            </div>
          </div>
        </div>
        <div id="ProfileDescription">
          <p :class="{previewDescription: !fullDescription}">
            {{ description() }}
          </p>
          <a
            class="moreinfo"
            @click="fullDescription = !fullDescription"
          >{{ fullDescription ? 'less Information' : 'more Information' }}</a>
        </div>
      </section>
      <section id="ProfileTags">
        <tags
          v-for="tag in profile.tags"
          :key="tag.name"
          :tag="tag.name"
        />
      </section>
      <section id="ProfileCTAs">
        <div class="grid-12">
          <div class="col-sp-6 row-sp-12 pad-8">
            <button
              class="ui button icon left btn-color-1 fluid"
              @click="message"
            >
              <i class="comments icon" /> Messenger
            </button>
          </div>
          <div class="col-sp-6 row-sp-12 pad-8">
            <button
              class="ui button icon left btn-color-2 fluid"
              @click="donate"
            >
              <i class="gift icon" /> Donate
            </button>
          </div>
        </div>
      </section>
      <section id="ProfileFeed">
        <wall
          v-if="profile.subscription"
          :profile="profile.id"
          type="profile"
        />
        <div v-else>
          <div
            id="SubscribeCTA"
          >
            <h3 class="ui header">
              Subscribe to see the Posts from
            </h3>
            <h4 class="ui header color-1">
              {{ profile.username }}
            </h4>
            <p>
              <i class="sticky note outline icon" /> 59 Posts | <i class="icon image" /> 110 Medien | <i class="icon heart" /> Likes
            </p>
            <div class="pad-32">
              <button
                class="ui button icon left btn-color-1 fluid"
                @click="subscriptionModal = true"
              >
                To the Subscription
              </button>
            </div>
          </div>
          <div id="Feed">
            <article style="background-color: rgba(0,0,0,0.2); margin-bottom: 16px; height: 250px; width: 100%;" />
            <article style="background-color: rgba(0,0,0,0.2); margin-bottom: 16px; height: 250px; width: 100%;" />
            <article style="background-color: rgba(0,0,0,0.2); margin-bottom: 16px; height: 250px; width: 100%;" />
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {authStore} from "@/store/authStore";
import Tags from "@/components/profile/tags";
import Wall from "@/components/wall/wall";
import SubscriptionModal from "@/components/subscription/subscriptionModal";
import {useRoute} from "vue-router";
import axios from "axios";

export default {
  name: 'Profile',
  components: {
    SubscriptionModal,
    Wall,
    Tags
  },
  /*
  beforeRouteUpdate (to, from) {
    alert('sollte nicht passieren')
    this.username = to.params.username
    document.title = 'FanRocket | ' + this.username
    this.loadProfile()
  },*/
  async setup(){
    const auth = authStore()
    const user = userStore()
    const config = configStore()

    const route = useRoute()

    let username = route.params.username
    let status
    let profile
    let dateTime
    document.title = 'FanRocket | ' + username

    const fD = new FormData()
    fD.append('username', username)
    fD.append('SessionID', auth.sessionID)

    await axios.post(config.projectURL + 'profile/get', fD)
        .then(function (response) {
          console.log(response)
          if (response.status === 250) {
            status = 'ok'
          } else if (response.status === 204) {
            status = 'notFound'
          } else if (response.status === 205) {
            status = 'otherBlock'
          } else if (response.status === 206) {
            status = 'iBlock'
          }
          profile = response.data

          dateTime = null
          try{
            const date = profile.lastActive.split(' ')[0].split('-')
            const time = profile.lastActive.split(' ')[1].split(':')
            dateTime = new Date(date[0], date[1]-1, date[2], time[0], time[1], time[2])
          }catch (e) {}
        })
        .catch(function (e) {
          status = 'notFound'
        })
    .finally(() => {
    })
    return {auth, user, config, username, status, profile, dateTime}
    //return {auth, user, config}
  },
  data () {
    return {
      tab: 'info',
      tempImg: false,
      fullDescription: false,
      subscriptionModal: false,/*
      username: '',
      status: '',
      profile: {},
      dateTime: ''*/
    }
  },
  computed: {
    online() {
      if(this.dateTime === null) return false
      return (this.config.timer - this.dateTime.getTime()) < this.config.onlineStatus
    }
  },
  mounted () {
    //this.username = this.$route.params.username
    //document.title = 'FanRocket | ' + this.username
    //await this.loadProfile()
  },
  methods: {
    loadProfile () {
      const _this = this
      return new Promise((resolve, reject) => {
        const fD = new FormData()
        fD.append('username', this.username)
        fD.append('SessionID', this.auth.sessionID)
        this.axios.post(this.config.projectURL + 'profile/get', fD)
            .then(function (response) {
              console.log(response)
              if (response.status === 250) {
                _this.status = 'ok'
              } else if (response.status === 204) {
                _this.status = 'notFound'
              } else if (response.status === 205) {
                _this.status = 'otherBlock'
              } else if (response.status === 206) {
                _this.status = 'iBlock'
              }
              _this.profile = response.data

              _this.dateTime = null
              try{
                const date = _this.profile.lastActive.split(' ')[0].split('-')
                const time = _this.profile.lastActive.split(' ')[1].split(':')
                _this.dateTime = new Date(date[0], date[1]-1, date[2], time[0], time[1], time[2])
              }catch (e) {}
            })
            .catch(function (e) {
              console.error(e)
            })
            .finally(() =>{
              resolve()
            })
      })
    },
    notNull (obj) {
      return obj !== null && obj !== undefined && obj !== 'null' && obj !== 'undefined'
    },
    description() {
      try {
        return this.fullDescription ? this.profile.description : this.profile.description.substring(0, 150) + (this.profile.description.length > 150 ? '...' : '');
      }catch (e) {
        return ''
      }

    },
    message(){
      if(this.profile.subscription === 'active'){

      }else{
        this.subscriptionModal = true
      }
    },
    donate(){
      if(this.profile.subscription === 'active'){

      }else{
        this.subscriptionModal = true
      }
    }
  }
}
</script>

<style>
#ProfileBanner{
  width: calc(100% - 32px);
  margin-left: 16px;
  min-height: 190px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 0;
}
#Favorit{
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
  cursor: pointer;
}
#Favorit i{
  color: #ffffff;
  font-size: 1.4em;
}
#ProfileTitle{
  position: relative;
  z-index: 1;
  width: calc(100% - 32px);
  margin-left: 16px;
  background-color: #ffffff;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
#ProfileImage{
  display: block;
  width: calc(100% - 16px);
  margin-left: 8px;
  border-radius: 90%;
  border: 4px solid #ffffff;
  margin-top: -48px;
  max-width: 150px;
}
#ProfileContent{
  padding: 16px;
  position: relative;
}
#ProfileContent h2{
  font-size: 1.3em;
  margin-bottom: 0;
}
#ProfileContent h2 i{
  font-size: 0.8em;
  vertical-align: top;
  color: #AF57FF;
}
#MoreOptions{
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
#ProfileDescription{
  padding: 16px;
  transition: height 2s;
}
#ProfileDescription{
  font-size: 0.9em;
  color: rgba(0,0,0,0.6);
}
#ProfileDescription p previewDescription{
  height: 62px;
  overflow: hidden;
}
.moreinfo{
  text-align: center;
  color: #AF57FF!important;
  font-size: 0.9em;
  display: block;
}
#ProfileTags{
  text-align: center;
  padding: 16px;
}
#ProfileTags .ui.basic.label{
  border-radius: 16px;
}
#ProfileTags .ui.basic.label:hover{
  color: #AF57FF!important;
}
#ProfileTags .ui.basic.label img{
  display: inline;
  max-width: 20px;
  height: 20px!important;
}
#ProfileFeed{
  position: relative;
}
#ProfileCTAs{
  padding: 8px;
}
#ProfileFeed #SubscribeCTA{
  position: absolute;
  width: calc(100% - 32px);
  max-width: 400px;
  left: 50%;
  transform: translateX(-50%);
  top: 32px;
  padding: 32px 16px 0px 16px;
  background-color: #ffffff;
  text-align: center;
  z-index: 3;
  border-radius: 16px;
}
#ProfileFeed #SubscribeCTA h3,
#ProfileFeed #SubscribeCTA h4{
  margin: 0;
}
#ProfileFeed #SubscribeCTA p{
  color: rgba(0,0,0,0.6);
  font-size: 0.8em;
  margin-bottom: -24px;
  margin-top: 8px;
}
#ProfileFeed #Feed{
  position: relative;
  filter: blur(5px);
  min-height: 500px;
  padding: 16px;
}
#SubscribeModal{
  padding-top: 24px;
}
#SubscribeModal h4{
  margin: 0;
  font-size: 1.4em;
}
#SubscribeModal p{
  margin-top: 16px;
  font-size: 1.1em;
  color: rgba(0,0,0,0.6);
  line-height: 1.1em;
}
#SubscribeModal button{
  margin-bottom: 16px;
}
#SubscribeModal .instead{
  display: block;
  font-size: 0.8em;
  font-weight: normal;
}
#SubscribeModal h5{
  margin: 0;
  margin-top: 16px;
  font-size: 1.1em;
  font-weight: normal;
}
</style>
