<template>
  <div class="ui comments">
    <div class="ui comment">
      <router-link class="avatar" :to="'/p/' + username">
        <img :src="projectURL + profile_img">
      </router-link>
      <div class="content">
        <router-link class="author" :to="'/p/' + username">{{username}}</router-link>
      <div class="metadata">
        <div class="date">{{date}}</div>
        </div>
        <div class="text">
          hat dir eine Freundschaftsanfrage gesendet:<br>
          <div class="pad-4 t-right">
            <button @click="this.$parent.$parent.declineFriend(friendid)" style="cursor: pointer;" class="ui tiny grey label">
              Ablehnen
            </button>
            <button @click="this.$parent.$parent.acceptFriend(friendid)" style="cursor: pointer;" class="ui tiny blue label">
              Annehmen
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'friendSingle',
  props: ['friendid', 'timestamp', 'username', 'profile_img'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime(),
      projectURL: window.projectURL
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  }
}
</script>

<style scoped>

</style>
