<template>
  <main
    id="Settings"
    class="spaceTop"
  >
    <div class="content-1200">
      <div
        id="SettingsHeader"
        class="pad-16"
      >
        <div class="ui segment no-shadow no-border pad-32">
          <div class="grid-16">
            <div class="col-sp-8 row-sp-16">
              <div class="t-left">
                <a
                  class="ui icon circular basic button"
                  @click="back()"
                >
                  <i class="icon left arrow" />
                </a>
              </div>
            </div>
            <div class="col-sp-8 row-sp-16">
              <div class="t-right" />
            </div>
            <div class="col-sp-16 row-sp-16 t-center">
              <img
                id="settings_profile_img"
                class="ui image small centered circular ar-1-1"
                :src="config.projectURL + 'assets/images/user/' + user.profile_img"
              >
              <h2 class="ui header centered">
                Einstellungen
                <div class="sub header">
                  Für deinen FanRocket Account
                </div>
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div
        id="emailSuccess"
        class="ui success message"
      >
        <i class="close icon" />
        <div class="header">
          Speichern erfolgreich
        </div>
        <p>Deine neue E-Mail-Adresse wurde deinem Account hinterlegt.</p>
      </div>
      <div
        id="emailFail"
        class="ui error message"
        style="display: none;"
      >
        <i class="close icon" />
        <div class="header">
          Fehler
        </div>
        <p>Die eingegebene E-Mail-Adresse gehört bereits zu einem Account.</p>
      </div>
      <div
        id="passwordSuccess"
        class="ui success message"
      >
        <i class="close icon" />
        <div class="header">
          Passwort geändert
        </div>
        <p>Dein Passwort wurde erfolgreich geändert.</p>
      </div>
      <div
        id="passwordDiff"
        class="ui error message"
      >
        <i class="close icon" />
        <div class="header">
          Bitte überprüfe deine Eingabe
        </div>
        <p>Entweder ist das Passwort zu kurz oder stimmte nicht mit der Wiederholung überein.</p>
      </div>
      <div
        id="passwordFail"
        class="ui error message"
      >
        <i class="close icon" />
        <div class="header">
          Das Passwort ist falsch!
        </div>
        <p>Bitte überprüfe deine Eingabe.</p>
      </div>
      <div class="pad-8 grid-16">
        <div
          id="SettingsMenu"
          class="col-sp-16 col-sd-6 row-sp-16 pad-8"
        >
          <div class="ui segment no-shadow no-radius no-border pad-16">
            <a
              class="settings-menu-item"
              @click="openSettings2('Account');"
            >
              <button class="ui circular icon button">
                <i class="user icon" />
              </button>
              <span class="menu-title">Account</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
            <a
              class="settings-menu-item"
              @click="openSettings2('Notifications');"
            >
              <button class="ui circular icon button">
                <i class="bell icon" />
              </button>
              <span class="menu-title">Benachrichtigungen</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
            <a
              class="settings-menu-item"
              @click="openSettings2('Security');"
            >
              <button class="ui circular icon button">
                <i class="lock icon" />
              </button>
              <span class="menu-title">Passwort & Sicherheit</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
            <a
              class="settings-menu-item"
              @click="openSettings2('Privacy');"
            >
              <button class="ui circular icon button">
                <i class="eye icon" />
              </button>
              <span class="menu-title">Privatsphäre</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
            <a
              class="settings-menu-item"
              @click="openSettings2('Design');"
            >
              <button class="ui circular icon button">
                <i class="paint brush icon" />
              </button>
              <span class="menu-title">Designanpassungen</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
            <a
              v-show="false"
              class="settings-menu-item"
              @click="openSettings2('Membership');"
            >
              <button class="ui circular icon button">
                <i class="fire icon" />
              </button>
              <span class="menu-title">Mitgliedschaft</span>
              <span class="rightspan">
                <i class="icon angle right" />
              </span>
            </a>
          </div>
        </div>
        <div class="col-sp-16 col-sd-10 row-sp-16 pad-8">
          <div
            id="AccountSettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32 display-Dektop"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="user icon" />
                  <div class="content">
                    Account
                    <div class="sub header">
                      Einstellungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />
            <div class="t-center">
              Demnächst verfügbar
            </div>
          </div>
          <div
            id="NotificationsSettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="bell icon" />
                  <div class="content">
                    Benachrichtigung
                    <div class="sub header">
                      Einstellungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />
            <div class="grid-12">
              <form
                id="pushForm"
                class="col-sp-12 col-tb-6 row-sp-12 ui form"
              >
                <hr>
                <div class="pad-4">
                  <div
                    id="PushMasterUI"
                    class="ui toggle checkbox push "
                    data-check="pushNotification-master"
                  >
                    <input
                      id="PushMaster"
                      type="checkbox"
                      name="PushMaster"
                    >
                    <label style="font-weight: bold;">Push-Benachrichtigungen</label>
                  </div>
                  <br>
                  <hr>
                  <div id="push">
                    <div
                      class="ui toggle checkbox push "
                      data-select="pushNotification-message"
                    >
                      <input
                        type="checkbox"
                        name="message"
                      >
                      <label>Nachrichten</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push "
                      data-select="pushNotification-matches"
                    >
                      <input
                        type="checkbox"
                        name="matches"
                      >
                      <label>Matches</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-friendship"
                    >
                      <input
                        type="checkbox"
                        name="friends"
                      >
                      <label>Freundschaftsanfragen</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-comment"
                    >
                      <input
                        type="checkbox"
                        name="comments"
                      >
                      <label>Kommentare</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-chatship"
                    >
                      <input
                        type="checkbox"
                        name="chatship"
                      >
                      <label>Nachrichtenanfragen</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-stamp"
                    >
                      <input
                        type="checkbox"
                        name="stamp"
                      >
                      <label>Stamps</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-reactions"
                    >
                      <input
                        type="checkbox"
                        name="reaction"
                      >
                      <label>Reaktionen</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-follower"
                    >
                      <input
                        type="checkbox"
                        name="follower"
                      >
                      <label>Follower</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-calling"
                    >
                      <input
                        type="checkbox"
                        name="calling"
                      >
                      <label>Anrufe</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-tags"
                    >
                      <input
                        type="checkbox"
                        name="tags"
                      >
                      <label>Makierungen</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox push"
                      data-select="pushNotification-updates"
                    >
                      <input
                        type="checkbox"
                        name="updates"
                      >
                      <label>Feed</label>
                    </div>
                  </div>
                </div>
              </form>
              <form
                id="mailForm"
                class="col-sp-12 col-tb-6 row-sp-12"
              >
                <hr>
                <div class="pad-4">
                  <div
                    id="MailMasterUI"
                    class="ui toggle checkbox mail"
                    data-check="emailNotification-master"
                  >
                    <input
                      id="MailMaster"
                      type="checkbox"
                      name="MailMaster"
                    >
                    <label style="font-weight: bold;">E-Mail-Benachrichtigungen</label>
                  </div>
                  <br>
                  <hr>
                  <div id="mail">
                    <div
                      class="ui toggle checkbox mail"
                      data-select="emailNotification-message"
                    >
                      <input
                        type="checkbox"
                        name="message"
                      >
                      <label>Nachrichten</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox mail"
                      data-select="emailNotification-matches"
                    >
                      <input
                        type="checkbox"
                        name="matches"
                      >
                      <label>Matches</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox mail"
                      data-select="emailNotification-tags"
                    >
                      <input
                        type="checkbox"
                        name="tags"
                      >
                      <label>Tags</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox mail"
                      data-select="emailNotification-friendship"
                    >
                      <input
                        type="checkbox"
                        name="friends"
                      >
                      <label>Freundschaftsanfragen</label>
                    </div>
                    <br>
                    <div
                      class="ui toggle checkbox mail"
                      data-select="emailNotification-stamp"
                    >
                      <input
                        type="checkbox"
                        name="stamp"
                      >
                      <label>Stamps</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <hr>
            <div class="pad-4">
              <div class="sub header">
                Lautstärke
              </div>
              <label>0%</label>
              <label
                class="t-right"
                style="float: right"
              >100%</label>
              <div class="col-sp-16 row-sp-16 field">
                <input
                  id="volume"
                  style="width: 100%;"
                  type="range"
                  name="volume"
                  step="5"
                  min="0"
                  max="100"
                  data-val="settings-volume"
                >
              </div>
            </div>
            <hr v-show="cordova">
            <div
              v-show="cordova"
              class="pad-4"
            >
              <div
                id="vibrateUI"
                class="ui toggle checkbox"
              >
                <input
                  id="vibrate"
                  type="checkbox"
                  name="vibrate"
                >
                <label style="font-weight: bold;">Vibration</label>
              </div>
            </div>
          </div>
          <div
            id="SecuritySettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="lock icon" />
                  <div class="content">
                    Passwort & Sicherheit
                    <div class="sub header">
                      Einstellungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />
            <div
              v-show="fingerprint"
              class="ui horizontal divider"
            >
              Verschlüsselung
            </div>
            <div
              v-show="fingerprint"
              class="grid-12"
            >
              <form
                id="biometricForm"
                class="col-sp-12 row-sp-12 ui form"
              >
                <div class="pad-4">
                  <div
                    id="biometricUI"
                    class="ui toggle checkbox"
                    @click="saveFingerprint"
                  >
                    <input
                      id="biometric"
                      type="checkbox"
                      name="Biometrie"
                      :checked="biometric"
                    >
                    <label style="font-weight: bold;">Biometrische Verschlüsselung / Pin</label>
                  </div>
                </div>
              </form>
            </div>
            <form
              id="emailForm"
              class="ui form"
            >
              <div class="ui horizontal divider">
                E-Mail Adresse ändern
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neue E-Mail Adresse</label>
                    <input
                      type="email"
                      name="email"
                      placeholder="Neue E-Mail Adresse"
                      required
                    >
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Passwort zur Bestätigung</label>
                    <input
                      type="password"
                      name="password"
                      placeholder="Passwort"
                      required
                    >
                  </div>
                </div>
                <div class="col-sp-12 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button
                    class="ui mini button"
                    type="submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </form>
            <div
              id="toShort"
              class="ui negative message"
              style="display: none;"
            >
              <div class="header">
                Dein Passwort ist leider zu kurz.
                &#x1F346;
              </div>
            </div>
            <form
              id="passwordForm"
              class="ui form"
            >
              <div class="ui horizontal divider">
                Passwort ändern
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neues Passwort</label>
                    <input
                      style="margin-bottom: 4px;"
                      type="password"
                      name="password"
                      placeholder="Neues Passwort"
                      required
                      @input="toShort($event.target.value);"
                    >
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Neues Passwort wiederholen</label>
                    <input
                      type="password"
                      name="password2"
                      placeholder="Neues Passwort wiederholen"
                      required
                    >
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Aktuelles Passwort</label>
                    <input
                      type="password"
                      name="current"
                      placeholder="Aktuelles Passwort"
                      required
                    >
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button
                    class="ui mini button"
                    type="submit"
                  >
                    Speichern
                  </button>
                </div>
              </div>
            </form>

            <form
              id="logoutForm"
              class="ui form"
            >
              <div class="ui horizontal divider">
                Login von allen Geräten löschen
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <p>
                    Damit werden alle aktuell mit deinem Account angemeldeten Geräte abgemeldet.
                  </p>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button
                    class="ui mini button"
                    type="submit"
                  >
                    <i class="cancel icon" />Abmelden
                  </button>
                </div>
              </div>
            </form>
            <form
              id="deleteForm"
              class="ui form"
            >
              <div class="ui horizontal divider">
                Fanrocket Account löschen
              </div>
              <div class="grid-12">
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4">
                  <div class="field">
                    <label>Passwort zur Bestätigung der Löschung</label>
                    <input
                      type="password"
                      name="password"
                      required
                    >
                    <p>Mit der Löschung werden alle Daten unwideruflich gelöscht!</p>
                  </div>
                </div>
                <div class="col-sp-12 col-tb-6 row-sp-12 pad-4 pl-0 align-self-center text-right">
                  <button
                    class="ui mini button"
                    type="submit"
                  >
                    <i class="trash icon" />  Account löschen
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div
            id="PrivacySettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="eye icon" />
                  <div class="content">
                    Privatsphäre
                    <div class="sub header">
                      Einstellungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />
            <input
              id="ProfileValue"
              type="hidden"
              name="ProfileValue"
            >
            <div
              id="channel-selection"
              class="grid-12"
            >
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div
                  id="SelectionPublic"
                  class="ui segment t-center pad-16 h-100"
                  onclick="selectPublicFunc();"
                  data-active="accountPublic-1"
                >
                  <div class="checked-icon">
                    <img src="assets/images/channels/check.png">
                  </div>
                  <div class="selectContent">
                    <img src="assets/images/public.png">
                    <h4 class="pad-16">
                      Öffentliches Profil
                    </h4>
                    <p>Dein Profil und Beiträge sind für alle Fanrocket Benutzer sichtbar.</p>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div
                  id="SelectionPrivate"
                  class="ui segment t-center pad-16 h-100"
                  onclick="selectPrivateFunc();"
                  data-active="accountPublic-0"
                >
                  <div class="checked-icon">
                    <img src="assets/images/channels/check.png">
                  </div>
                  <div class="selectContent">
                    <img src="assets/images/private.png">
                    <h4 class="pad-16">
                      Privates Profil
                    </h4>
                    <p>Dein Profil und Beiträge sind nur für deine Freunde sichtbar.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            id="DesignSettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="paint brush icon" />
                  <div class="content">
                    Design
                    <div class="sub header">
                      Anpassungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />
            <div class="grid-12">
              <form
                id="darkForm"
                class="col-sp-12 row-sp-12 ui form"
              >
                <div class="pad-4">
                  <div
                    id="DarthVaderUI"
                    class="ui toggle checkbox"
                    data-select="settings-darkmode"
                  >
                    <input
                      id="DarthVader"
                      type="checkbox"
                      name="DarthVader"
                    >
                    <label style="font-weight: bold;">Darkmode</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            id="MembershipSettings"
            class="SettingsPanel ui segment no-shadow no-radius no-border pad-32"
          >
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16 display-Mobile">
                <div class="t-left">
                  <a
                    class="ui icon circular basic button"
                    @click="openSettingsMenu();"
                  >
                    <i class="icon left arrow" />
                  </a>
                </div>
              </div>
              <div class="col-sp-12 col-sd-16 row-sp-16">
                <h3 class="ui header">
                  <i class="fire icon" />
                  <div class="content">
                    Mitgliedschaft
                    <div class="sub header">
                      Einstellungen
                    </div>
                  </div>
                </h3>
              </div>
            </div>
            <div class="pad-8" />

            <a href="membership">
              <div class="grid-12">
                <div class="col-sp-8 row-sp-12">
                  <h4 class="ui header">
                    Mitgliedschaft ändern
                    <div class="sub header">Ändere oder kündige deine Mitgliedschaft</div>
                  </h4>
                </div>
                <div class="col-sp-4 row-sp-12 t-right">
                  <i class="icon angle right" />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import router from '@/router'
import { initSettings, toShort, selectPublicFunc, selectPrivateFunc, openSettings2, openSettingsMenu } from '@/modules/settings'
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {authStore} from "@/store/authStore";

export default {
  name: 'Settings',
  title: 'FanRocket | Settings',
  setup(){
    const user = userStore()
    const auth = authStore()
    const config = configStore()

    return {auth, config, user}
  },
  data () {
    return {
      vibrate: parseInt(localStorage.getItem('vibrate'))
    }
  },
  computed: {
  },
  mounted () {
    initSettings()
  },
  methods: {
    back () {
      router.back()
    },
    toShort (s) {
      toShort(s)
    },
    selectPublicFunc () {
      selectPublicFunc()
    },
    selectPrivateFunc () {
      selectPrivateFunc()
    },
    openSettings2 (s) {
      openSettings2(s)
    },
    openSettingsMenu () {
      openSettingsMenu()
    },
  }
}
</script>

<style scoped>

</style>
