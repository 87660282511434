<template>
  <div class="spaceTop">
    <section id="pageheader" class="pad-32 t-center bg-color-1">
      <h1 class="ui header inverted centered">
        How it works
      </h1>
    </section>
    <section id="Hashtags" class="pad-16">
      <div class="content-1000">
        <div class="ui segment no-border no-shadow br-16 pad-24 t-center">
          <h2>Content for everyone</h2>
          <div class="hashtag-box">
            <span class="ui basic label large color-1">#Twinks</span>
            <span class="ui basic label large">#Amateure</span>
            <span class="ui basic label large">#Shemales</span>
            <span class="ui basic label large">#Mens</span>
            <span class="ui basic label large color-1">#18yr</span>
            <span class="ui basic label large">#MILFs</span>
            <span class="ui basic label large">#DILFs</span>
            <span class="ui basic label large">#Bottoms</span>
            <span class="ui basic label large">#Tops</span>
            <span class="ui basic label large">#Asian</span>
            <span class="ui basic label large color-1">#Europe</span>
            <span class="ui basic label large">#Australian</span>
            <span class="ui basic label large color-1">#Blondes</span>
            <span class="ui basic label large">#Ebony</span>
            <span class="ui basic label large">#Czech</span>
            <span class="ui basic label large">#Emo</span>
            <span class="ui basic label large">#Fetish</span>
            <span class="ui basic label large">#Latin</span>
            <span class="ui basic label large">#Straight</span>
            <span class="ui basic label large">#Gays</span>
            <span class="ui basic label large">#Lesbians</span>
            <span class="ui basic label large">#Russians</span>
            <span class="ui basic label large">#Bears</span>
            <span class="ui basic label large">#Hunks</span>
          </div>
        </div>
      </div>
    </section>
    <section id="threepoints" class="bg-grey pad-32">
      <div class="content-500">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-1 row-sp-12 pad-8 ">
            <span>1</span>
          </div>
          <div class="col-sp-12 col-tb-11 row-sp-12 pad-8">
            <h4>Register for free</h4>
            <p>Register within a few seconds. The only requirement is that you are of legal age.</p>
          </div>
          <div class="col-sp-12 col-tb-1 row-sp-12 pad-8">
            <span>2</span>
          </div>
          <div class="col-sp-12 col-tb-11  row-sp-12 pad-8">
            <h4>Subscribe and enjoy content</h4>
            <p>Subscribe to artists from all over the world and enjoy their exclusive content.</p>
          </div>
          <div class="col-sp-12 col-tb-1 row-sp-12 pad-8">
            <span>3</span>
          </div>
          <div class="col-sp-12 col-tb-11  row-sp-12 pad-8">
            <h4>Produce your own content and earn money</h4>
            <p>Become an Artist and distribute your own Content. From cooking videos to sports videos to adult content. There are no limits for you.</p>
          </div>
        </div>
        <div class="t-center">
          <a class="ui button btn-color-1 circular large">Join now</a>
        </div>
      </div>
    </section>
    <section id="Advantages" class="bg-white pad-32">
        <div class="t-center pad-32">
          <h3>Advantages Fanrocket Plattform</h3>
        </div>
        <div class="content-1200">
          <div class="grid-12">
            <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-16">
              <div class="bg-grey t-center pad-16 br-16 h-100">
                <h5>Support</h5>
                <p>
                  We offer support in englisch, german and dutch languages
                </p>
              </div>
            </div>
            <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-16">
              <div class="bg-grey t-center pad-16 br-16 h-100">
                <h5>Regular payout from the first month</h5>
                <p>
                  At Fanrocket you will receive your earnings from the first month.
                </p>
              </div>
            </div>
            <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-16">
              <div class="bg-grey t-center pad-16 br-16 h-100">
                <h5>Optional management</h5>
                <p>
                  Fanrocket offers besides the plattform a management that takes care of your planning, accounting and legal. More information here.
                </p>
              </div>
            </div>
            <div class="col-sp-12 col-tb-3 col-sd-2 row-sp-12 pad-16"></div>
            <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-16">
              <div class="bg-grey t-center pad-16 br-16 h-100">
                <h5>Modern Design</h5>
                <p>
                  User-friendly and modern.
                </p>
              </div>
            </div>
            <div class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-16">
              <div class="bg-grey t-center pad-16 br-16 h-100">
                <h5>Unique functions</h5>
                <p>
                  Feed, Explore, Matchgame, Creator Forum & more.
                </p>
              </div>
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'howitworks',
  title: 'FanRocket | How it works',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>
#pageheader h1{
  font-size: 3em;
  padding: 64px 0;
}
#Hashtags{
  background-color: #ffffff;
}
#Hashtags .hashtag-box{
  margin: 0 auto;
  max-width: 700px;
}
#Hashtags h2{
  margin: 16px 0;
}
#Hashtags .ui.label{
  border-radius: 90px;
  padding: 12px 16px;
  color: rgba(0,0,0,0.6);
}
#threepoints .col-sp-12{
  margin-bottom: 24px;
}
#threepoints span{
  width: 40px;
  height: 40px;
  display: block;
  text-align: center;
  background-color: #A333C8;
  color: #ffffff;
  padding: 6px;
  font-size: 1.2em;
  vertical-align: center;
  border-radius: 90%;
}
#threepoints p{
  color: rgba(0,0,0,0.4);
  max-width: 500px;
}
#Advantages p{
  color: rgba(0,0,0,0.6);
  font-size: 0.9em;
}
</style>
