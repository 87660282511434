<template>
  <router-link class="content" :to="'/n/' + notidata.post" v-if="size =='small'">
    <a class="author" v-if="notidata.unlocked == 1">
      Bild freigeschaltet!
      <div class="text">
        Dein Bild wurde von uns überprüft und freigeschaltet.
      </div>
    </a>
    <a class="author" v-else>
      Bild ungeeignet!
      <div class="text">
        Dein Bild wurde von uns überprüft und nicht freigegeben. Bitte lies dir unsere Community-Richtlinien durch und wende dich bei fragen gerne an den Support.
      </div>
    </a>
    <div class="metadata t-right" style="display: block">
      <div class="date">
        {{date}}
      </div>
    </div>
  </router-link>
  <router-link class="col-sp-9 col-sd-11 row-sp-12 pad-16" v-else :to="'/n/' + notidata.post">
    <h4 class="ui header">
      <a class="author" v-if="notidata.unlocked == 1">
        Bild freigeschaltet!
        <div class="sub header">
          Dein Bild wurde von uns überprüft und freigeschaltet.
          <img src="assets/images/fake-check.png">
        </div>
      </a>
      <a class="author" v-else>
        Bild ungeeignet!
        <div class="sub header">
          Dein Bild wurde von uns überprüft und nicht freigegeben. Bitte lies dir unsere Community-Richtlinien durch und wende dich bei fragen gerne an den Support.
          <img src="assets/images/match-dislike.png">
        </div>
      </a>
    </h4>
    <div class="t-right">{{date}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'postUnlocked',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime()
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  },
  mounted () {
    $('#notis').dropdown()
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
