<template>
  <router-link class="content" :to="'/p/' + username" v-if="size =='small'">
      <a class="author">Neuer Match!</a>
      <div class="text">
            Du hast ein Match mit {{username}}! Yeaaaah!
            <img src="assets/images/likes/smiling-face-with-hearts.png">
        </div>
      <div class="metadata t-right" style="display: block">
            <div class="date">
                 {{date}}
              </div>
        </div>
  </router-link>
  <router-link class="col-sp-9 col-sd-11 row-sp-12 pad-16" :to="'/p/' + username" v-else>
     <h4 class="ui header">Neuer Match!
           <div class="sub header">
                 Du hast ein Match mit {{username}}! Yeaaaah!
              <img src="assets/images/likes/smiling-face-with-hearts.png">
             </div>
       </h4>
     <div class="t-right">{{date}}</div>
  </router-link>
</template>

<script>
export default {
  name: 'newMatch',
  props: ['notidata', 'timestamp', 'username', 'size'],
  data () {
    return {
      now: Date.now(),
      time: this.getTime()
    }
  },
  created () {
    setInterval(this.getNow, 5000)
  },
  computed: {
    date () {
      return this.$parent.calculateTime(this.time, this.now)
    }
  },
  methods: {
    getNow: function () {
      this.now = Date.now()
    },
    getTime () {
      const arr = this.timestamp.split(/[- :.T]/)
      return new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]).getTime()
    }
  },
  mounted () {
    $('#notis').dropdown()
  }
}
</script>

<style scoped>
h4.ui.header .sub.header img{
  max-width: 20px;
}
</style>
