<template>
  <div className="spaceTop">
    Test
  </div>
</template>

<script>
export default {
  name: 'createstudio',
  title: 'FanRocket | Create own Studio',
  mounted () {
    window.fanrocket.loading = false
  }
}
</script>
