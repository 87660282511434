<template>
  <div id="notiPage">
    <div class="ui tiny modal" id="friendrequestModal">
      <i class="close icon"></i>
      <div class="header">
        Freundschaftsanfragen
      </div>
      <friends-nav :friendsarray="friends" size="big"></friends-nav>
    </div>
    <div id="page-notifications" class="spaceTop">
      <div class="content-800">
        <div class="pad-16" id="NotificationsHeader">
          <div class="ui segment no-shadow no-border pad-32">
            <div class="grid-16">
              <div class="col-sp-2 row-sp-16">
                <div class="t-left">
                  <a @click="back()" class="ui icon circular basic button">
                    <i class="icon left arrow"></i>
                  </a>
                </div>
              </div>
              <div class="col-sp-12 row-sp-16">
                <div class="t-center pad-8">
                  <h4 class="ui header">Benachrichtigungen</h4>
                </div>
              </div>
              <div class="col-sp-2 row-sp-16">
                <div class="t-right">
                  <router-link class="ui icon circular basic button" to="/settings">
                    <i class="icon cogs"></i>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="pad-4"></div>
          <button class="ui button fluid basic" onclick="$('#friendrequestModal.ui.modal').modal('show');">
            <i class="user add icon"></i> Neue Freundschaftsanfragen
            <span class="ui label">{{friends.length}}</span>
          </button>
        </div>
        <div class="pad-8" id="Notifications">
          <div class="rainbowloader" v-if="loading">
            <div class="loader-inner">
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
              <div class="loader-line-wrap">
                <div class="loader-line">
                </div>
              </div>
            </div>
          </div>
          <notification-nav :notiarray="notis" :notisread="notisread" size="big" ></notification-nav>
        </div>
      </div>
      <div class="pad-40"></div>
    </div>
  </div>

</template>

<script>
import router from '@/router'
import friendsNav from '@/components/basic/navbar/friendsNav'
import notificationNav from '@/components/basic/navbar/notificationNav'
export default {
  name: 'notification',
  title: 'Fanrocket | Benachrichtigungen',
  components: {
    notificationNav,
    friendsNav
  },
  data () {
    return {
      notis: [],
      friends: [],
      allowLoad: false,
      loading: true
    }
  },
  computed: {
    countNoFri () {
      return this.notis.length + this.friends.length - this.notisread
    },
    countTitle () {
      FirebasePlugin.setBadgeNumber(this.countNoFri + this.chats)
      return this.countNoFri + this.chats
    }
  },
  methods: {
    updateReads () {
      this.notisread = this.notis.length
      window.fanrocket.notisread = window.fanrocket.notis.length
    },
    acceptFriend (id) {
      $.post(window.projectURL + 'profile/friendRequestAccept', { id: id, postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
        if (xhr.status === 250) {
          for (var i = 0; i < window.fanrocket.friends.length; i++) {
            if (window.fanrocket.friends[i].id === id) {
              window.fanrocket.friends.splice(i, 1)
            }
          }
        }
      })
    },
    declineFriend (id) {
      $.post(window.projectURL + 'profile/friendRequestDelete', { id: id, postkey: sessionStorage.getItem('postkey'), SessionID: sessionStorage.getItem('SessionID') }, function (data, status, xhr) {
        if (xhr.status === 250) {
          for (var i = 0; i < window.fanrocket.friends.length; i++) {
            if (window.fanrocket.friends[i].id === id) {
              window.fanrocket.friends.splice(i, 1)
            }
          }
        }
      })
    },
    back () {
      router.back()
    },
    initNotis () {
      const _this = this
      setTimeout(function () {
        if (window.fanrocket.socketOnline) {
          window.socket.on('allNotis', (data) => {
            window.socket.emit('allNotisRead', { room: sessionStorage.getItem('NodeID'), uname: localStorage.getItem('username') })
            _this.updateReads()
            _this.loading = false
            $('#notis').dropdown('show')
            for (const key in data) {
              let add = true
              for (const key2 in _this.notis) {
                if (_this.notis[key2].id === data[key].id)add = false
              }
              if (add)_this.notis.push(data[key])
            }
            _this.allowLoad = true
          })
          window.socket.on('friends', (data) => {
            try {
              _this.friends = data
            } catch (e) {}
          })
          window.socket.emit('allNotis', { uid: localStorage.getItem('user_id'), offset: _this.notis.length })
        } else {
          _this.initNotis()
        }
      }, 50)
    }
  },
  mounted () {
    const _this = this
    this.initNotis()
    $(document).scroll(function (event) {
      if (Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight + 250 >= $('#notiPage').height() && _this.allowLoad) {
        _this.allowLoad = false
        socket.emit('allNotis', { uid: localStorage.getItem('user_id'), offset: _this.notis.length })
      }
    })
    window.fanrocket.loading = false
    if (this.$route.params.friends === 'friends') {
      $('#friendrequestModal.ui.modal').modal('show')
    }
  },
  beforeRouteUpdate (to, from) {
    window.fanrocket.loading = false
    if (to.params.friends === 'friends') {
      $('#friendrequestModal.ui.modal').modal('show')
    }
  }
}
</script>

<style>
#Notifications .noti-avatar img{
  margin: 16px auto;
}
.dark #NotificationsHeader span.ui.label{
  background-color: #000000;
  color: #ffffff;
}

</style>
