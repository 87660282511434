<template>
  <div id="Mobile-Top-Menu">
    <header class="header fixed-top header-fill">
      <nav class="navbar">
        <div>
          <button class="menu-btn btn btn-link btn-44">
            <span class="icon material-icons">menu</span>
          </button>
        </div>
        <div>
          <router-link
            active-class="active"
            class="navbar-brand"
            to="/wall"
          >
            <div class="t-center">
              <img
                v-if="!config.darkMode"
                class="logo_main"
                style="max-height: 45px; padding-top: 8px;"
                src="assets/images/fanrocket-logo-icon.png"
              >
              <img
                v-else
                class="logo_main"
                style="max-height: 45px; padding-top: 8px;"
                src="assets/images/icon-logo-cropped.png"
              >
            </div>
          </router-link>
        </div>
        <div id="nav-right">
          <router-link
            v-if="socket.online"
            active-class="active"
            class="nav-link "
            to="/n"
            onclick="allRead();"
            data-active="notifications"
            aria-controls="search"
            aria-selected="false"
            style="position: relative;display: inline;color: #999;font-size: 12px;"
          >
            <i
              class="material-icons"
              style="vertical-align: middle;display: inline-block;text-align: center;"
            >notifications_none</i>
            <small class="sr-only">notifications_none</small>
            <div
              v-if="friends.counter > 0"
              class="floating ui blue tiny circular label"
            >
              {{ friends.counter }}
            </div>
          </router-link>
          <router-link
            active-class="active"
            :to="'/p/' + user.username"
          >
            <span
              v-if="socket.online"
              class="avatar avatar-30"
              style="background: transparent;"
            >
              <img
                class="ar-1-1"
                :src="config.projectURL + 'assets/images/user/' + (user.profile_img ?? 'avatar_default.png')"
              >
            </span>
            <div
              v-else
              class="ui segment"
              style="border: none; box-shadow: none;"
            >
              <div class="ui active loader" />
            </div>
          </router-link>
        </div>
      </nav>
    </header>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {socketStore} from "@/store/socketStore";
import {friendsStore} from "@/store/friendsStore";
import {notificationStore} from "@/store/notificationStore";

export default {
  name: 'MobileTop',
  setup() {
    const auth = authStore()
    const user = userStore()
    const config = configStore()
    const socket = socketStore()
    const notifications = notificationStore()
    const friends = friendsStore()

    return {auth, user, config, socket, notifications, friends}
  },
  data () {
    return {
    }
  },
  computed: {
    logo () {
      return this.config.darkMode ? 'assets/images/dark-fanrocket-beta-pride.png' : 'assets/images/fanrocket-beta-pride.png'
    }
  }
}
</script>

<style>
.header.header-fill .navbar {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  padding-bottom: 0;
}
#nav-right {
  margin-left: -30px;
}
</style>
