<template>
  <div class="content-1200 spaceTop">
    <div class="ui segment no-border no-shadow pad-32 t-center" style="margin: 10% auto; max-width: 700px;">
      <img src="assets/images/redirect-unicorn.png" class="responsive-img" style="max-width: 200px;">
      <h2 class="ui header">
        Du verlässt Fanrocket!
        <div class="sub header">Möchtest du die URL <span class="color-2" id="linkString">{{ link }}</span> wirklich öffnen? <br><br>Wir können für die Sicherheit der URL nicht garantieren.</div>
      </h2>
      <div class="pad-32">
        <a class="ui button" @click="this.$router.back()">Zurück</a>
        <a class="ui button btn-color-1" id="link" @click="openLink()">Link öffnen</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'redirect',
  title: 'Fanrocket | Weiterleitung',
  data () {
    return {
      link: this.$route.query.l
    }
  },
  methods: {
    openLink () {
      let link = this.link.toLowerCase()
      if (!link.startsWith('http')) {
        link = 'http://' + link
      }
      window.open(link, '_system')
      this.router.back()
    }
  },
  mounted () {
    const _this = this
    if (this.link === '' || this.link == null || this.link === undefined) {
      _this.router.back()
    }
    window.fanrocket.loading = false
  }
}
</script>

<style scoped>

</style>
